import { getContainer } from '@vegga/front-store';
import { Subject, takeUntil } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('others45flowController', others45flowController);

  others45flowController.$inject = [
    '$rootScope',
    '$scope',
    '$state',
    'sensorsFactory',
    '$confirm',
    '$filter',
    'unitFactory',
  ];

  function others45flowController($rootScope, $scope, $state, sensorsFactory, $confirm, $filter, unitFactory) {
    var vm = this;
    var backup;
    vm.installer;
    vm.save;
    vm.cancel;
    vm.sector = {};
    vm.save = save;
    vm.cancel = cancel_edition;
    vm.changeSector = changeSector;
    vm.changingSector = changingSector;
    vm.nextOrPreviousItem = nextOrPreviousItem;
    vm.devicesFacade = getContainer().resolve('devicesFacade');

    vm.destroy$ = new Subject();

    activate();

    function activate() {
      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((currentUnit) => {
        if (!currentUnit) {
          return;
        }
        vm.currentUnit = currentUnit;

        getMetersSector();
      });
    }

    function getMetersSector() {
      sensorsFactory.getMetersSector(vm.currentUnit.id).then(function (data) {
        vm.metersSectors = data.plain();
        angular.copy(vm.metersSectors[0], vm.sector);
        backup();
      });
    }

    function changeSector(e) {
      if (e.detail.value) {
        vm.index = +e.detail.value;
        angular.copy(vm.metersSectors[vm.index - 1], vm.sector);
      }
    }

    function changingSector($event) {
      if (vm.form && vm.form.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({ text: $filter('translate')('sensors.cancelq') }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.form.$setPristine(); //Actualitzem estat del formulari a inicial
          this.nextOrPreviousItem($event.detail.action, $event);
        });
      }
    }

    function nextOrPreviousItem(action, $event) {
      const paginator = document.querySelector('vegga-item-paginator');
      switch (action) {
        case 'next-item':
          paginator.nextItem();
          break;
        case 'previous-item':
          paginator.previousItem();
          break;
        default:
          changeSector($event);
          break;
      }
    }

    function backup() {
      vm.sector_backup = {};
      angular.copy(vm.sector, vm.sector_backup);
    }

    function save() {
      vm.sector.unittype = vm.currentUnit.type;
      sensorsFactory.updateMetersSector(vm.sector).then((response) => {
        vm.sector = response.plain();
        angular.copy(vm.sector, vm.metersSectors[vm.index - 1]);
        $rootScope.$broadcast('updateEdit', { message: 'refreshing' });
        vm.form.$setPristine();

        backup();
      });
    }

    function cancel_edition() {
      vm.form.$setPristine();
      angular.copy(vm.sector_backup, vm.sector);
    }
    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      },
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e, args) => {
      save(e, vm.form);
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });
  }
})();
