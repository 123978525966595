import shareModalTemplate from './shareModal.html';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .directive('sharemodal', function () {
      return {
        restrict: 'EA',
        scope: {
          element: '=element',
          map: '=mapid',
          type: '=type',
          callbackbuttonleft: '&ngClickLeftButton',
          callbackbuttonright: '&ngClickRightButton',
          handler: '=main',
        },
        template: shareModalTemplate,
        transclude: true,
        controller: [
          'userFactory',
          '$scope',
          '$rootScope',

          function (userFactory, $scope, $rootScope) {
            if ($scope.element !== null && $scope.element !== null) {
              $scope.handler =
                'popshare' + ($scope.element.pk !== undefined ? $scope.element.pk.id : $scope.element.id);
            }
            var subusers = [];
            var submast = [];
            var backup = {};
            class shareStruct {
              constructor(name, login, userId, elemId, type, ownerId, lvl) {
                this.name = name;
                this.login = login;
                this.userId = userId;
                this.elemId = elemId;
                this.type = type;
                this.ownerId = ownerId;
                this.lvl = lvl;
              }
            }

            $scope.shareList = [];
            $scope.loadModal = loadModal;
            $scope.updateShare = updateShare;
            $scope.cancel = cancel;

            /*Activate*/
            function loadModal() {
              subusers = [];
              if (!$scope.shareList.length > 0) {
                if ($rootScope.user.master === true) {
                  userFactory.shareUsers($rootScope.user.id).then(function (response) {
                    if (response) submast = response.plain();
                    _.forEach(submast, (sm) => {
                      subusers.push(sm);
                    });
                    prepareShareStructList(subusers);
                  });
                } else if ($rootScope.user.usermanager === null) {
                  userFactory.subusers($rootScope.user.id, true).then(function (response) {
                    if (response) subusers = response.plain();
                    if ($rootScope.user.masterUser) subusers.push($rootScope.user.masterUser);
                    prepareShareStructList(subusers);
                  });
                } else if ($rootScope.user.usermanager !== null) {
                  if ($rootScope.user.usermanager) subusers.push($rootScope.user.usermanager);
                  if ($rootScope.user.masterUser) subusers.push($rootScope.user.masterUser);
                  prepareShareStructList(subusers);
                }
              }
            }

            function prepareShareStructList(subusers) {
              _.forEach(subusers, (u) => {
                let share = null;
                switch ($scope.type) {
                  case 0:
                    share = new shareStruct(u.name, u.nif, u.id, null, $scope.type, $scope.element.userId);
                    share.mapId = $scope.element.id;
                    break;
                  case 6: //extracciones
                    share = new shareStruct(u.name, u.nif, u.id, $scope.element.id, $scope.type, $scope.element.userId);
                    break;
                  default:
                    share = new shareStruct(
                      u.name,
                      u.nif,
                      u.id,
                      $scope.element.pk.id,
                      $scope.type,
                      $scope.element.pk.userId,
                    );
                    break;
                }
                $scope.shareList.push(share);
              });
              if ($scope.type === 0) {
                $scope.element.sharedList.forEach((usersWithMap) => {
                  $scope.shareList.forEach((user, i) => {
                    if (usersWithMap.userId === user.userId) {
                      $scope.shareList[i].lvl = usersWithMap.level;
                    }
                  });
                });
              } else {
                _.forEach($scope.element.sharedList, (sl) => {
                  var coincidencia = _.find($scope.shareList, { elemId: sl.elemId, userId: sl.userId });
                  if (coincidencia !== undefined) {
                    coincidencia.lvl = sl.lvl;
                    coincidencia.id = sl.id;
                  }
                });
              }
              angular.copy($scope.shareList, backup);
            }

            function cancel() {
              angular.copy(backup, $scope.shareList);
            }

            function updateShare() {
              if ($scope.shareList.length > 0) {
                userFactory.updateShare($rootScope.user.id, $scope.shareList).then(() => {});
              }
            }
          },
        ],
        link: function (scope, element, attrs) {
          scope.$watch('map', function (newValue, oldValue) {
            scope.shareList = [];
          });
        },
      };
    });
})();
