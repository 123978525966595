import { Subject, take, takeUntil } from 'rxjs';
import { UNITS } from '../utils/units.enum';
import { getContainer } from '@vegga/front-store';
(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('malfunctionsController', malfunctionsController);

  malfunctionsController.$inject = [
    '$rootScope',
    '$scope',
    '$state',
    'manualFactory',
    '$confirm',
    'sensorsFactory',
    '$filter',
    'unitFactory',
    'UserData'
  ];

  function malfunctionsController(
    $rootScope,
    $scope,
    $state,
    manualFactory,
    $confirm,
    sensorsFactory,
    $filter,
    unitFactory,
    UserData
  ) {
    var vm = this;
    vm.devicesArray;
    vm.endMalfunction;
    vm.images;
    vm.endMalfunction = endMalfunction;
    vm.endMalfunction25 = endMalfunction25;
    vm.endMalfunctionBIT = endMalfunctionBIT;
    vm.endMalfunction7 = endMalfunction7;
    vm.endMalfunction55 = endMalfunction55;
    vm.endMalfunction45 = endMalfunction45;
    vm.images = manualFactory.getManualImages();
    vm.selectInput = selectInput;

    vm.destroy$ = new Subject();
    vm.devicesFacade = getContainer().resolve('devicesFacade');

    initSubscriptions();

    function initSubscriptions() {
      vm.devicesFacade.devicesICMResponse.clear();

      vm.devicesFacade.loadICMDevices(UserData.id, true);
      vm.devicesFacade.devicesICMResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((units) => {
        vm.units = units;
        vm.devicesArray = _.filter(vm.units, (unit) => {
          return unit.malfunctions && unit.malfunctions.length > 0;
        });

        vm.devicesArray.forEach((device) => {
          if (device.definitiveStopMalfunction && device.type === UNITS.A_4000) {
            sensorsFactory.digitalsactive(device.id).then((sensors) => {
              // This sensorsIds are the sensors assigned to definitiveStopMalfunction
              device.digitalSensors = sensors?.plain().filter((s) => +s.pk.id >= 6 && +s.pk.id <= 9) ?? [];
              vm.selectedInput = +device.digitalSensors[0]?.pk.id - 5;

              vm.inputOptions = [1, 2, 3, 4];
            });
          }
        });
      });
    }

    function selectInput($event) {
      vm.selectedInput = $event.detail.value;
    }

    function endMalfunction(alert, unit) {
      var action;
      var parameters = null;
      switch (alert[unit.name]) {
        case 'generalMalfunction':
          action = 12;
          break;
        case 'flowMalfunction':
          action = 13;
          break;
        case 'counterMalfunction':
          action = 14;
          break;
        case 'ferlitzerMalfunction':
          action = 15;
          break;
        case 'filterMalfunction':
          action = 19;
          break;
        case 'phMalfunction':
          action = 16;
          break;
        case 'ceMalfunction':
          action = 17;
          break;
        case 'definitiveStopMalfunction':
          action = 18;
          parameters = vm.selectedInput;
          break;
        case 'systemStopMalfunction':
          action = 1;
          break;
        case '2lMalfunction':
          break;
      }
      $confirm({
        text: $filter('translate')('manual.send-action'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function (res) {
        var modalInstance = manualFactory.showLoadingBar();
        manualFactory.stopMalfunction(unit, action, parameters);
        modalInstance.result.then((result) => {
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }
    function endMalfunction45(alert, unit) {
      var action = 25;
      var parameters = 0;

      $confirm({
        text: $filter('translate')('manual.send-action'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function (res) {
        var modalInstance = manualFactory.showLoadingBar();
        manualFactory.stopMalfunction(unit, action, parameters);
        modalInstance.result.then((result) => {
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }

    function endMalfunction25(alert, unit) {
      var action;
      var parameters = null;
      switch (alert[unit.name]) {
        case 'filterMalfunction':
          action = 23;
          break;
        case 'systemStopMalfunction':
          action = 7;
          break;
        default:
          parameters = alert.input;
          action = 16;
          break;
      }
      $confirm({
        text: $filter('translate')('manual.send-action'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        manualFactory.stopMalfunction(unit, action, parameters);
        modalInstance.result.then((result) => {
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }

    function endMalfunctionBIT(alert, unit) {
      var action;
      var parameters = null;
      switch (alert[unit.name]) {
        case 'definitiveStopMalfunction':
          action = 15; //El 22 no sutiltiza
          break;
        default:
          parameters = alert.input; //FINAL PAROS I AVERIES
          action = 15;
          break;
      }
      $confirm({
        text: $filter('translate')('manual.send-action'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function (res) {
        var modalInstance = manualFactory.showLoadingBar();
        manualFactory.stopMalfunction(unit, action, parameters);
        modalInstance.result.then((result) => {
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }

    function endMalfunction7(alert, unit) {
      var action;
      var parameters = null;
      switch (alert[unit.name]) {
        case 'generalMalfunction':
          action = 14;
          break;
        case 'flowMalfunction':
          action = 17;
          break;
        case 'counterMalfunction':
          action = 14;
          break;
        case 'ferlitzerMalfunction':
          action = 15;
          break;
        case 'filterMalfunction':
          action = 11;
          break;
        case 'phMalfunction':
          action = 16;
          break;
        case 'ceMalfunction':
          action = 15;
          break;
        case 'definitiveStopMalfunction':
          action = 18;
          parameters = 1;
          break;
        case 'systemStopMalfunction':
          action = 1;
          break;
        case '2lMalfunction':
          break;
      }
      $confirm({
        text: $filter('translate')('manual.send-action'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function (res) {
        var modalInstance = manualFactory.showLoadingBar();
        manualFactory.stopMalfunction(unit, action, parameters);
        modalInstance.result.then((result) => {
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }

    function endMalfunction55(alert, unit) {
      var action;
      var parameters = null;
      switch (alert[unit.name]) {
        case 'filterMalfunction':
          action = 23;
          break;
        case 'systemStopMalfunction':
          //action = 7;
          break;
        default:
          parameters = alert.input;
          action = 16;
          break;
      }
      $confirm({
        text: $filter('translate')('manual.send-action'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        manualFactory.stopMalfunction(unit, action, parameters);
        modalInstance.result.then((result) => {
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });

    $scope.$on('refresh', function (event, args) {
      if (args.units) {
        vm.devicesArray = [];
        vm.devicesArray = _.filter(args.units, (unit) => {
          return unit.malfunctions && unit.malfunctions.length > 0;
        });
        $scope.$emit('refreshed', { message: 'refreshed' });
      }
    });
  }
})();
