import { getContainer } from '@vegga/front-store';
import { of, Subject, switchMap, takeUntil } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('pivotsConfigController', pivotsConfigController);

  pivotsConfigController.$inject = ['$scope', '$rootScope', '$state', 'pivotsFactory', 'sensorsFactory', '$filter'];

  function pivotsConfigController($scope, $rootScope, $state, pivotsFactory, sensorsFactory, $filter) {
    var vm = this;
    var backup_pivot;
    vm.cancel;
    vm.save;
    vm.cancel = cancel_edition;
    vm.save = save;
    vm.direction = direction;
    vm.selected = 1;
    vm.totalSensors = _.range(1, 41);
    vm.totalAreas = _.range(1, 9);
    vm.digitalSensorsRange = _.range(1, 21);
    vm.conditionersRange = _.range(1, 31);
    vm.toogle = toogle;
    vm.areaGridColumnsDef = [
      { headerName: $filter('translate')(''), field: '' },
      { headerName: $filter('translate')('pivot.finalpos'), field: '' },
      { headerName: $filter('translate')('pivot.sector'), field: '' },
    ];
    vm.extAreaGridColumnsDef = [
      { headerName: $filter('translate')(''), field: '' },
      { headerName: $filter('translate')('pivot.inpos'), field: '' },
      { headerName: $filter('translate')('pivot.finalpos'), field: '' },
      { headerName: $filter('translate')('pivot.usepistol'), field: '' },
      { headerName: $filter('translate')('pivot.usealero'), field: '' },
    ];
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    vm.destroy$ = new Subject();
    activate();

    function activate() {
      initSubscriptions();

      if ($state.$current.parent.name === 'a25programs') {
        const veggaOverlay = document.querySelector('vegga-overlay');
        veggaOverlay.show();
      }
    }

    function initSubscriptions() {
      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).
        pipe(switchMap((currentUnit) => {
          if (!currentUnit) {
            $state.go('units');
            return of(null);
          }
          vm.currentState = $state.includes;
          vm.currentUnit = currentUnit;
          return pivotsFactory.getPivot()
        })).subscribe((pivot) => {
          vm.pivot = pivot
          loadDigitalSensors();
        });
    }

    function toogle(id) {
      vm.selected = id;
    }

    function loadDigitalSensors() {
      if (vm.digitalSensors === undefined || vm.digitalSensors === null) {
        sensorsFactory.digitalsPage(vm.currentUnit.id, { limit: 50, page: 1 }).then(function (data) {
          vm.digitalSensors = data.content;
        });
      }
    }

    function direction(d) {
      vm.pivot.direction = d;
    }

    function backup() {
      backup_pivot = {};
      angular.copy(vm.pivot, backup_pivot);
      // vm.pivot.backup = true;
    }

    function save() {
      vm.form.$setPristine();
      pivotsFactory.update(vm.currentUnit.id, vm.pivot).then((response) => {
        vm.pivot = response.plain();
        vm.pivot.areas = _.orderBy(vm.pivot.areas, 'id');
        vm.pivot.externalAreas = _.orderBy(vm.pivot.externalAreas, 'id');
        backup();
        $scope.$emit('refresh');
      });
    }

    function cancel_edition() {
      angular.copy(backup_pivot, vm.pivot);
      $state.go('pivots.detail');
    }

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      },
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e, args) => {
      save(e);
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });


    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
