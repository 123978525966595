import moment from 'moment/moment';
const colors = require('../../resources/vegga.tokens.colors.json');
import { UNITS } from '../utils/units.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('sectorMapController', sectorMapController);

  sectorMapController.$inject = [
    '$log',
    '$rootScope',
    '$scope',
    'Restangular',
    '$state',
    'mapsFactory',
    'sectorFactory',
    '$filter',
    'progFactory',
    '$q',
    'manualFactory',
    '$confirm',
    'calculatedsFactory',
    '$timeout',
    'unitFactory',
  ];

  function sectorMapController(
    $log,
    $rootScope,
    $scope,
    Restangular,
    $state,
    mapsFactory,
    sectorFactory,
    $filter,
    progFactory,
    $q,
    manualFactory,
    $confirm,
    calculatedsFactory,
    $timeout,
    unitFactory
  ) {
    var vm = $scope.$parent.vm;

    var defaultAreaStyle;
    var props;
    var layerLoaded;
    var params;
    let currentUnit;
    let waterArray;
    let myChart;

    activate();

    /*******************/

    function activate() {
     
      layerLoaded = false;
      vm.actionView = 0; //Default view INFO, 1 = Manual, 2 = Edition area

      vm.editSect = false;
      vm.areaInfo = false;

      //vm.update = update;
      vm.cancelSector = cancel;
      vm.loadSectors = loadSectors;
      vm.saveSector = saveSector;
      vm.cancelSect = cancelSect;
      vm.stop = stop;
      vm.auto = auto;
      vm.start = start;
      vm.prepareCanvasWaterHistory = prepareCanvasWaterHistory;
      vm.getSectorsInMap = getSectorsInMap;
      waterArray = {};
    }

    function closeAreaInfo() {
      $timeout(() => {
        vm.map.invalidateSize();
      }, 200);
      clearSectorSelection();
    }

    function cancel() {
      vm.currentSector = vm.selectedSector;
      vm.currentUnit = _.filter(vm.units, (o) => {
        return o.id === Number(vm.currentSector.deviceId);
      })[0];
      vm.actionView = 0;
    }

    function saveSector() {
      vm.newSector.remove();

      mapsFactory.prepareGEOJsonLayer(vm.newSector, vm.unit, vm.sector, vm.map).then((result) => {
        var geoJsonLayer = result;
        vm.newSector.toggleEdit();
        geoJsonLayer.on({
          mouseover: overAreaSector,
          mouseout: outAreaSector,
          click: areaInformation,
        });
        prepareLayerWithSectorInformation(geoJsonLayer);
        vm.unit = null;
        vm.sector = null;
        reloadSectors();
        $scope.$emit('completedSave');
        $rootScope.$broadcast('reload', { message: 'refreshing' });
      });
    }

    function cancelSect() {
      vm.newSector.toggleEdit();
      vm.newSector.remove();
      vm.editSect = false;
    }

    function loadSectorDetails(obj) {
      if (vm.isCreatingMarker) return;
      props = obj;
      vm.currentUnit = _.filter(vm.units, (o) => {
        return o.id === Number(obj.properties.device);
      })[0];
      vm.waterHistory = false;
      vm.waterValue = '-';
      if (vm.currentUnit) {
        //vm.areaInfo = true;
        vm.equipoInfo = false;
        vm.moduleInfo = false;
        vm.sensorInfo = false;
        vm.actionView = 0;
        vm.currentSector = _.filter(vm.currentUnit.sectors, (sector) => {
          return sector.pk.id === obj.properties.sector;
        });
        if (vm.currentSector.length === 1) {
          vm.currentSector = vm.currentSector[0];

          $scope.$emit('editSectionChange', {
            type: 'sector',
            value: vm.currentSector,
            selectedAreaId: vm.selectedAreaId,
            currentUnit: vm.currentUnit,
            elementsInMap: vm.sectorsInMap,
            elementId: obj.properties.id,
          });
          $state.go(
            'maps.sector',
            {
              unit: vm.currentUnit,
              sector: vm.currentSector,
              map: vm.map,
              layerId: obj.properties.id,
              elementsInMap: vm.sectorsInMap,
            },
            { reload: 'maps.sector' }
          );
          vm.overlay.show();
        }
        checkIrrigation();
      } else {
        //sin acceso al equipo
        vm.areaInfo = true;
        vm.equipoInfo = false;
        vm.moduleInfo = false;
        vm.sensorInfo = false;
        vm.actionView = 0;
      }
    }

    function prepareCanvasWaterHistory() {
      var ctx = document.getElementById('sectorchart');
      myChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: [],
          datasets: [],
        },
        options: {
          scales: {
            yAxes: [
              {
                position: 'left',
                id: 'y-axis-1',
                display: true,
                ticks: {
                  max: 140,
                  min: 0,
                  callback: function (value, index, values) {
                    return Number(value) + ' %';
                  },
                },
                gridLines: {
                  display: false,
                },
              },
            ],
            xAxes: [
              {
                id: 'x-axis-1',
                position: 'bottom',
                type: 'time',
                time: {
                  isoWeekday: true,
                  unit: 'day',
                  tooltipFormat: 'DD/MM HH:mm',
                  displayFormats: {
                    day: 'DD/MM HH:mm',
                    hour: 'DD/MM HH:mm',
                  },
                },
                gridLines: {
                  display: false,
                },
                display: true,
                ticks: {
                  source: 'auto',
                },
              },
            ],
          },
          tooltips: {
            mode: 'nearest',
            intersect: true,
            callbacks: {
              label: function (tooltipItem, data) {
                return tooltipItem.yLabel.toFixed(1) + '%';
              },
            },
          },
          elements: {
            point: {
              pointStyle: 'circle',
              radius: 0,
              hitRadius: 5,
            },
            line: {
              fill: false,
              tension: 0.5,
            },
          },
          annotation: {},
        },
      });
    }

    function loadWaterHistory() {
      let k = vm.currentSector.waterDisp + '_' + vm.currentSector.waterDispUser;
      if (waterArray[k] !== undefined) {
        vm.waterName = waterArray[k].name;
        let cicle = waterArray[k].cicles[0];
        let annotations = [
          {
            type: 'box',
            yScaleID: 'y-axis-1',
            yMin: 100,
            yMax: 140,
            borderColor: 'rgb(0,187,222,0.75)',
            borderWidth: 0.5,
            backgroundColor: 'rgb(0,187,222,0.1)',
          },
          {
            type: 'box',
            yScaleID: 'y-axis-1',
            yMin: cicle.recargaPoint,
            yMax: 100,
            borderColor: 'rgb(0,169,159,0.75)',
            borderWidth: 0.5,
            backgroundColor: 'rgb(0,169,159,0.1)',
          },
          {
            type: 'box',
            yScaleID: 'y-axis-1',
            yMin: cicle.estresPoint,
            yMax: cicle.recargaPoint,
            borderColor: 'rgb(255,146,100,0.75)',
            borderWidth: 0.5,
            backgroundColor: 'rgb(255,146,100,0.1)',
          },
          {
            type: 'box',
            yScaleID: 'y-axis-1',
            yMin: 0,
            yMax: cicle.estresPoint,
            borderColor: 'rgb(255,72,75,0.75)',
            borderWidth: 0.5,
            backgroundColor: 'rgb(255,72,75,0.1)',
          },
        ];
        myChart.options.annotation.annotations = annotations;
        let params = { from: '', to: '' };
        let dates;
        params.to = moment().add(1, 'days').format('DD-MM-YYYY');
        params.from = moment().subtract(6, 'days').format('DD-MM-YYYY');
        calculatedsFactory
          .waterHistory(vm.currentSector.waterDispUser, vm.currentSector.waterDisp, 0, params)
          .then((dates) => {
            let dataset = {
              yAxisID: 'y-axis-1',
              xAxisID: 'x-axis-1',
              label: waterArray[k].name,
              backgroundColor: '#e6e600',
              borderColor: '#e6e600',
              data: [],
              type: 'line',
            };
            let data = [];
            _.forEach(dates, (o) => {
              var values = [];
              values[0] = moment(o[1], 'DD-MM-YYYY HH:mm')._d;
              values[1] = o[0];
              data.push(_.zipObject(['x', 'y'], values));
            });
            dataset.data = data;
            myChart.data.datasets[0] = dataset;
            myChart.update();
          });
      }
    }

    function showOtherSectorFromUnit(id) {
      vm.areasLayer.eachLayer(function (l) {
        let area = l.toGeoJSON().features[0].properties;
        if (Number(area.device) === id) {
          l.setStyle({ color: '#ffc72c', weight: 3 });
        }
      });
    }

    function clearSectorSelection() {
      vm.areasLayer.eachLayer(function (l) {
        l.setStyle({ color: 'white', weight: 1 });
      });
    }

    function checkIrrigation() {
      switch (vm.currentUnit.type) {
        case UNITS.A_4000: //A4000
        case UNITS.A_2500: //A2500
          var irrig = _.filter(vm.currentUnit.sectors, (sector) => {
            return sector.xStatus & (1 === 1);
          });
          progFactory.programs(vm.currentUnit.id, vm.currentUnit.type, true).then(function (data) {
            var activeList = progFactory.activePrograms();
            //var irrig = _.filter(activeList, (prog) => {return prog.irrigation;});
            vm.currentUnit.irrigation = irrig.length > 0;
            vm.currentUnit.nprogr = activeList.length;
            getSectorsInMap();
          });
          break;
      }
    }

    function openIrrigationInfo(event) {
      var geoJSONObject = this.toGeoJSON();
      if (geoJSONObject.features[0].properties.irrigation) {
        vm.irrigInfo = true;
        loadSectorIrrigationDetails(geoJSONObject.features[0].properties);
      } else {
        vm.irrigInfo = false;
      }
    }

    function checkSectorStatus(sector) {
      sector.irrigation = false;
      switch (currentUnit.type) {
        case UNITS.A_4000:
          if (
            currentUnit.systemStopMalfunction ||
            currentUnit.generalMalfunction ||
            currentUnit.flowMalfunction ||
            currentUnit.counterMalfunction ||
            currentUnit.ferlitzerMalfunction
          ) {
            sector.irrigation = false;
            sector.malfunction = true;
          } else if (currentUnit.definitiveStopMalfunction) {
            if (sector.xStatus === 4) {
              sector.irrigation = false;
              sector.malfunction = true;
            }
          } else {
            if (sector.xStatus !== 4 && sector.xStatus !== 0) {
              sector.irrigation = true;
            }
          }
          break;
        case UNITS.A_2500:
        case UNITS.A_BIT:
          if (currentUnit.systemStopMalfunction) {
            sector.irrigation = false;
            sector.malfunction = true;
          } else if (currentUnit.definitiveStopMalfunction) {
            if (sector.xManual) {
              sector.irrigation = true;
            } else {
              sector.irrigation = false;
              sector.malfunction = true;
            }
          } else {
            if (sector.xStatus !== null && sector.xStatus !== 0 && sector.xStatus !== 2 && sector.xStatus !== 5) {
              sector.irrigation = true;
            }
          }
          break;
        case UNITS.A_7000:
          if (currentUnit.systemStopMalfunction) {
            sector.irrigation = false;
            sector.malfunction = true;
          } else if (currentUnit.definitiveStopMalfunction) {
            if (sector.xManual) {
              sector.irrigation = true;
            } else {
              sector.irrigation = false;
              sector.malfunction = true;
            }
          } else {
            if (sector.xStatus !== null && sector.xStatus !== 0) {
              sector.irrigation = true;
            }
          }
          break;
        case UNITS.A_5500:
          if (currentUnit.systemStopMalfunction) {
            sector.irrigation = false;
            sector.malfunction = true;
          } else if (currentUnit.definitiveStopMalfunction) {
            if (sector.xManual) {
              sector.irrigation = true;
            } else {
              sector.irrigation = false;
              sector.malfunction = true;
            }
          } else {
            if (
              sector.xStatus !== null &&
              sector.xStatus !== 0 &&
              sector.xStatus !== 3 &&
              sector.xStatus !== 5 &&
              sector.xStatus !== 6
            ) {
              sector.irrigation = true;
            }
          }
          break;
        case UNITS.A_4500:
          if (sector.xStatus === 1) {
            sector.irrigation = true;
          }

          break;
      }
    }

    function extractProgramData(programs, sector) {
      var currentProgram = null;
      switch (vm.currentUnit.type) {
        case 2: //A4000
          currentProgram = A4000ProgramData(programs, sector);
          break;
        case 3: //A2500
        case 4: //BIT
          currentProgram = A2500ProgramData(programs, sector);
          break;
      }

      var orderedPrograms = _.map(
        _.orderBy(programs, (o) => {
          return Number(o.pk.id);
        }),
        (o) => {
          return Number(o.pk.id);
        }
      );

      if (currentProgram !== null) {
        currentProgram.start = progFactory.getTimeFromMins(currentProgram.start).format('HH:mm');
      } else {
        currentProgram = {};
      }
      currentProgram.area = sector.area;
      currentProgram.programs = orderedPrograms;
      return currentProgram;
    }

    function A2500ProgramData(programs, sector) {
      var current = null;
      if (sector.xProgramN !== 0) {
        current = _.filter(programs, (o) => {
          return Number(o.pk.id) === sector.xProgramN;
        })[0];
      }
      if (current !== null && current !== undefined) {
        switch (current.xStartMotive) {
          case 0:
            current.substate = 'Inicio por horario';
            break;
          case 1:
            current.substate = 'Inicio secuencial';
            break;
          case 2:
            current.substate = 'Inicio por condicionante';
            break;
          case 3:
            current.substate = 'Inicio programa por manual';
            break;
          default:
            current.substate = 0;
            break;
        }
        current.time = current.value;
        switch (current.unit) {
          case 0:
            current.time = $filter('parsetime')(current.time);
            current.pending = $filter('parsetime')(current.xValue);
            current.done = $filter('parsetime')(current.value - current.xValue);
            break;
          case 3:
            var mins = current.time;
            current.time = $filter('parsetimesec')(current.time);
            current.pending = $filter('parsetimesec')(current.xValue);
            current.done = $filter('parsetimesec')(current.value - current.xValue);
            break;
          case 1:
            current.time = current.time / 1000 + 'm3';
            current.pending = current.xValue / 1000 + 'm3';
            current.done = (current.value - current.xValue) / 1000 + 'm3';
            break;
          case 4:
            current.time = $filter('parsetime')(current.time);
            current.pending = $filter('parsetime')(current.xValue);
            current.done = $filter('parsetime')(current.value - current.xValue);
            break;
        }
      } else if (sector.xManual) {
        current = {};
        current.substate = 'Inicio por manual';
      } else {
        current = {};
        current.substate = 'Inicio por pivot';
      }

      return current;
    }

    function A4000ProgramData(programs, sector, current, time) {
      var current = null;
      if (sector.xProgramN != 0 && sector.xSubprogramN != 0) {
        current = _.filter(programs, (o) => {
          return Number(o.pk.id) === sector.xProgramN;
        })[0];
        if (current !== null) {
          current.subprogram = _.filter(current.subprograms, (o) => {
            return Number(o.pk.id) === sector.xSubprogramN;
          })[0];
        }
      }
      if (current !== null && current.subprogram !== null) {
        current.time = current.subprogram.value;
        switch (current.subprogram.unit) {
          case 0:
            current.time = $filter('parsetime')(current.time * 60);
            break;
          case 1:
            var mins = current.time * 60 * 60;
            current.time = $filter('parsetime')(current.time);
            break;
          case 2:
            current.time = current.time + 'm3';
            break;
          case 4:
            current.time = current.time + 'l';
            break;
          case 16:
            current.time = current.time + 'm3/h';
            break;
        }
        if (current.xStartState & 0x0010) current.substate = 'Inicio por manual'; //resultat =  1;// inici manual
        else if (current.xStartState & 0x0020)
          current.substate = 'Inicio por entrada'; //resultat =  2;// inici per entrada
        else if (current.xStartState & 0x0040) current.substate = 'Inicio por SMS'; //resultat =  3;// inici per SMS
        else if (current.xStartState & 0x0080)
          current.substate = 'Inicio secuencial'; //resultat =  4;// inici per sequencial
        else if (current.xStartState & 0x0100)
          current.substate = 'Inicio por condicionante'; //resultat =  5;// inici per condicionant
        else if (current.xStartState & 0x0200)
          current.substate = 'Inicio por horario'; //resultat =  6;// inici per horari
        else current.substate = 0; //resultat =  17;// inici indefinit
      }
      return current;
    }

    function update() {
      props.properties.sector = vm.selectedSector.pk.id;
      props.properties.sectorName = vm.selectedSector.name;
      props.properties.device = vm.currentUnit.id;
      props.properties.deviceName = vm.currentUnit.name;
      mapsFactory.updateAreaSectorLayer(props.properties.map, props).then((result) => {
        loadSectorDetails(result);
      });
    }

    function prepareLayerWithSectorInformation(area) {
      var properties;
      if (vm.filter.sectors.irrig) {
        properties = area.toGeoJSON().features[0];
        properties = properties.properties;
        setSectorStyle(area, properties.sector, properties.device);
      } else if (vm.filter.sectors.water) {
        var properties = area.toGeoJSON().features[0];
        properties = properties.properties;
        setWaterSectorStyle(area, properties);
      } else if (vm.filter.sectors.waterstate) {
        var properties = area.toGeoJSON().features[0];
        properties = properties.properties;
        setWaterstateSectorStyle(area, properties);
      } else {
        area.setStyle({
          fillColor: colors['vegga-color-primary-default'],
          opacity: 0.8,
          color: colors['vegga-color-primary-dark'],
          fillOpacity: 0.7,
          weight: 1,
        });
      }
      vm.areasLayer.addLayer(area);
    }

    function loadSectors(unit) {
      vm.areSectorsLoading = true;
      mapsFactory.loadSectors(unit.id).then((sectors) => {
        unit.sectors = sectors;
        vm.sector = sectors[0];
        vm.areSectorsLoading = false;
      });
    }

    function loadSectorsToUnit(areas) {
      var calls = [];
      _.forEach(areas, (area) => {
        var index = _.findIndex(vm.units, (o) => {
          return o.id === Number(area.properties.device);
        });
        if (index !== -1) {
          if (
            !vm.units[index].loadedSector &&
            (vm.units[index].sectors === undefined || vm.units[index].sectors === null)
          ) {
            vm.units[index].loadedSector = true;
            var func = sectorFactory.allSectors(area.properties.device).then(function (data) {
              vm.units[index].sectors = data.plain();
              return '';
            });
            calls.push(func);
          }
        }
      });
      $q.all(calls).then((result) => {
        vm.areasLayer.clearLayers();
        _.forEach(areas, (obj) => {
          var index = _.findIndex(vm.units, (o) => {
            return o.id === Number(obj.properties.device);
          });
          if (index !== -1) {
            var geoJson = L.geoJson(obj, {
              style: {
                fillColor: colors['vegga-color-primary-default'],
                opacity: 0.8,
                color: colors['vegga-color-primary-dark'],
                fillOpacity: 0.7,
                weight: 1,
              },
            });
            geoJson.on({
              mouseover: overAreaSector,
              mouseout: outAreaSector,
              click: areaInformation,
            });
            var stringPopup =
              '<div class="title-popup"><div class="img-logo"></div>' +
              obj.properties.deviceName +
              '</div>' +
              '<div class="title-sector"><span>S' +
              obj.properties.sector +
              '</span>' +
              '<span> | </span>' +
              '<span>' +
              obj.properties.sectorName +
              '</span></div>';
            var mypopup = L.popup({ closeButton: false, className: 'popup-map-sector' }).setContent(stringPopup);
            geoJson.bindPopup(mypopup);
            prepareLayerWithSectorInformation(geoJson);
          }
        });
        if (vm.equipoInfo) $rootScope.$broadcast('remarkSectors');
      });
    }

    function overAreaSector(e) {
      if (!vm.areaInfo && !vm.irrigInfo) {
        this.openPopup();
      }
    }

    function outAreaSector(e) {
      if (!vm.areaInfo && !vm.irrigInfo) {
        //this.closePopup();
      }
    }

    function areaInformation(event) {
      vm.selectedAreaId = _.get(event, 'layer.feature.properties.sector') || event;

      $timeout(() => {
        vm.map.invalidateSize();
        vm.map.flyTo(this.getBounds().getCenter());
      }, 300);
      // vm.map.fitBounds(this.getBounds(),{maxZoom:17});
      var geoJSONObject = this.toGeoJSON();
      vm.currentArea = this._leaflet_id;
      loadSectorDetails(geoJSONObject.features[0]);
    }

    function setSectorStyle(area, id, unit) {
      let styleProperties = { fillColor: 'white', opacity: 0.8, color: 'white', fillOpacity: 0.7, weight: 1 };

      var index = _.findIndex(vm.units, (o) => {
        return o.id === Number(unit);
      });
      if (index !== -1) {
        /* vm.currentUnit = vm.units[index];*/
        currentUnit = vm.units[index];

        var resultFilter = _.filter(vm.units[index].sectors, (sector) => {
          return sector.pk.id === id;
        });
        if (resultFilter.length === 1) {
          resultFilter = resultFilter[0];

          checkSectorStatus(resultFilter);
          if (resultFilter.xManual) {
            styleProperties.color = '#993399';
            styleProperties.weight = 4;
          }
          if (resultFilter.malfunction) {
            styleProperties.fillColor = '#FF3631';
          } else if (resultFilter.irrigation) {
            styleProperties.fillColor = '#00BADF';
          }
          area.setStyle(styleProperties);
        }
      }
    }

    function setWaterSectorStyle(area, props) {
      var styleProperties = { fillColor: 'white', opacity: 0.8, color: 'white', fillOpacity: 0.2, weight: 1 };
      let index = _.findIndex(vm.units, (o) => {
        return o.id === Number(props.device);
      });
      if (index !== -1) {
        currentUnit = vm.units[index];
        let sector =
          _.filter(vm.units[index].sectors, (sector) => {
            return sector.pk.id === props.sector;
          })[0] || {};
        if (sector.waterDisp !== null && sector.waterDisp !== undefined && sector.waterDispUser !== null) {
          let key = sector.waterDisp + '_' + sector.waterDispUser;
          if (waterArray[key] !== undefined && waterArray[key] !== null) {
            printWaterColor(area, waterArray[key]);
          } else {
            calculatedsFactory.waterDisp(sector.waterDispUser, { page: sector.waterDisp, limit: 1 }).then((sensors) => {
              var content = sensors.plain();
              var sensor = _.find(content, (s) => {
                return s.pk.id === sector.waterDisp;
              });
              if (sensor !== undefined) {
                waterArray[key] = sensor;
                printWaterColor(area, waterArray[key]);
              } else {
                area.setStyle(styleProperties);
              }
            });
          }
        } else {
          area.setStyle(styleProperties);
        }
      }
    }

    function setWaterstateSectorStyle(area, props) {
      var styleProperties = { fillColor: 'blue', opacity: 0.8, color: 'blue', fillOpacity: 0.2, weight: 1 };

      //area.setStyle({color:'black',weight:1});

      area.setStyle(styleProperties);
    }

    function printWaterColor(area, sensor) {
      let colors = {
        1: '#005E86',
        2: '#006056',
        3: '#00B8A7',
        4: '#92E9E1',
        5: '#F17373',
      };
      let key;
      let value = sensor.xValue / 10;
      if (value > sensor.lConfortA) {
        key = 1;
      } else if (value > sensor.lConfortM) {
        key = 2;
      } else if (value > sensor.lConfortB) {
        key = 3;
      } else if (value > sensor.lDeficit) {
        key = 4;
      } else {
        key = 5;
      }
      if (Number(key)) {
        area.setStyle({ fillColor: colors[key], opacity: 0.8, color: 'white', fillOpacity: 0.7, weight: 1 });
      }
    }

    function loadSectorsLayer() {
      if (!layerLoaded && vm.map.id) {
        mapsFactory.getAreas(vm.map.id).then((areas) => {
          layerLoaded = true;
          vm.sectorsInMap = areas;
          getSectorsInMap();
          loadSectorsToUnit(areas);
          vm.map.addLayer(vm.areasLayer);
        });
      } else {
        _.forEach(vm.areasLayer.getLayers(), (area) => {
          if (vm.filter.sectors.irrig) {
            var properties = area.toGeoJSON().features[0];
            properties = properties.properties;
            setSectorStyle(area, properties.sector, properties.device);
          } else if (vm.filter.sectors.water) {
            var properties = area.toGeoJSON().features[0];
            properties = properties.properties;
            setWaterSectorStyle(area, properties);
          } else if (vm.filter.sectors.waterstate) {
            var properties = area.toGeoJSON().features[0];
            properties = properties.properties;
            setWaterstateSectorStyle(area, properties);
          } else {
            area.setStyle({ fillColor: '#ffc72c', opacity: 0.8, color: 'white', fillOpacity: 0.7, weight: 1 });
          }
        });
        vm.map.addLayer(vm.areasLayer);
      }
    }

    /* Manual actions */

    function stop() {
      params = {};
      params.type = vm.currentUnit.type;
      params.deviceId = vm.currentUnit.id;
      params.parameter1 = Number(vm.currentSector.pk.id) - 1;
      $confirm({ text: 'Enviar orden?', title: 'Manual AGRONIC', ok: 'Yes', cancel: 'No' }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        params.action = 8;
        manualFactory.genericManualAction(params.deviceId, params);
        modalInstance.result.then((result) => {
          vm.areaInfo = false;
          vm.actionView = 0;

          $rootScope.$broadcast('reload', { message: 'refreshing' });
        });
      });
    }
    function start() {
      params = {};
      params.type = vm.currentUnit.type;
      params.deviceId = vm.currentUnit.id;
      params.parameter1 = Number(vm.currentSector.pk.id) - 1;
      $confirm({ text: 'Enviar orden?', title: 'Manual AGRONIC', ok: 'Yes', cancel: 'No' }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        params.action = 9;
        manualFactory.genericManualAction(params.deviceId, params);
        modalInstance.result.then((result) => {
          vm.areaInfo = false;
          vm.actionView = 0;

          $rootScope.$broadcast('reload', { message: 'refreshing' });
        });
      });
    }
    function auto() {
      params = {};
      params.type = vm.currentUnit.type;
      params.deviceId = vm.currentUnit.id;
      params.parameter1 = Number(vm.currentSector.pk.id) - 1;
      $confirm({ text: 'Enviar orden?', title: 'Manual AGRONIC', ok: 'Yes', cancel: 'No' }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        params.action = 10;
        manualFactory.genericManualAction(params.deviceId, params);
        modalInstance.result.then((result) => {
          vm.areaInfo = false;
          vm.actionView = 0;

          $rootScope.$broadcast('reload', { message: 'refreshing' });
        });
      });
    }

    function getSectorsInMap() {
      $scope.$emit('groupElementsInMap', vm.sectorsInMap);
    }

    function reloadSectors() {
      layerLoaded = false;
      loadSectorsLayer();
    }

    $scope.$on('editAreaFromSectorLayer', (event, args) => {
      /*         var layer = vm.areasLayer.getLayer(vm.currentArea);
        vm.map.editTools; */
      vm.editSect = true;
    });

    $scope.$on('deleteSectorArea', () => {
      mapsFactory.deleteArea(props.properties.map, props.properties.id).then((resp) => {
        vm.areasLayer.removeLayer(vm.currentArea);
        vm.overlay.dismiss();
        vm.unit = null;
        vm.sector = null;
        $scope.$emit('completedSave');
        reloadSectors();
        closeAreaInfo();
      });
    });

    $scope.$on('closeSector', () => {
      closeAreaInfo();
    });

    $scope.$on('reloadMap', () => {
      layerLoaded = false;
    });

    $scope.$on('loadSectorsLayer', () => {
      if (vm.filter.sectors.active) loadSectorsLayer();
    });

    $scope.$on('createMarker', (event) => {
      vm.isCreatingMarker = true;
    });

    $scope.$on('createdMarker', (event) => {
      vm.isCreatingMarker = false;
    });

    $scope.$on('loadSectorData', () => {
      // console.log(this._leaflet_id);
    });
  }
})();
