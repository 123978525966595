import moment from 'moment';
import { UNITS } from './units.enum';

export const DAYS = {
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday',
  7: 'sunday',
};

export function unitTimeFormatter($translate, hour) {
  if (hour) {
    const dayWeek = moment(hour, 'DD-MM-YYYY HH:mm').day();
    let newDay = '';
    switch (dayWeek) {
      case 1:
        newDay = '_mon';
        break;
      case 2:
        newDay = '_tue';
        break;
      case 3:
        newDay = '_wed';
        break;
      case 4:
        newDay = '_thu';
        break;
      case 5:
        newDay = '_fri';
        break;
      case 6:
        newDay = '_sat';
        break;
      case 0:
        newDay = '_sun';
        break;
    }
    return `${$translate.instant(`programs.edit.${newDay}`)} ${moment(hour, 'DD-MM-YYYY HH:mm').format(
      'DD/MM/YYYY | HH:mm',
    )}`;
  } else {
    return '--:--';
  }
}

export function getType(type) {
  switch (type) {
    case UNITS.A_4000:
      return 'A-4000';
    case UNITS.A_2500:
      return 'A-2500';
    case UNITS.A_BIT:
      return 'A-BIT';
    case UNITS.A_7000:
      return 'A-7000';
    case UNITS.A_5500:
      return 'A-5500';
    case UNITS.A_4500:
      return 'A-4500';
  }
}

export function parseToNumberDays(daysContainer) {
  return Object.keys(DAYS)
    .filter((dayKey) => daysContainer[DAYS[dayKey]])
    .map((dayKey) => +dayKey);
}

export function unitIncludedIn(unitType, allowedUnitTypes) {
  return allowedUnitTypes.includes(unitType);
}

export function toDate(date) {
  return moment(date, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY');
}

export function toHour(date) {
  return moment(date, 'DD-MM-YYYY HH:mm').format('HH:mm');
}

export function formatDate(date) {
  return moment(date).format('MMMM-YYYY');
}
