import { getContainer } from '@vegga/front-store';
import formatcoords from 'formatcoords/index';
import ParseDMS from 'parse-dms/index';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('agrobeeController', agrobeeController);

  agrobeeController.$inject = ['$scope', '$state', 'commFactory', '$anchorScroll', 'unitFactory'];

  function agrobeeController($scope, $state, commFactory, $anchorScroll, unitFactory) {
    var vm = this;
    var link_backup;
    var module_array;
    vm.activeList;
    vm.save;
    vm.cancel;
    vm.serialNumber;
    vm.link = {};
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    vm.destroy$ = new Subject();

    activate();

    function activate() {
      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((currentUnit) => {
        if (!currentUnit) {
          return;
        }
        loadData(currentUnit);
      });
    }

    function loadData(currentUnit) {
      vm.currentState = $state.includes;
      vm.currentUnit = currentUnit;
      vm.save = save;
      vm.cancel = cancel_edition;

      vm.openModuleWindow = openModuleWindow;
      vm.cancelModule = cancelModule;
      vm.saveModule = saveModule;
      vm.prepareModule = prepareModule;
      vm.convertDMStoDS = convertDMStoDS;
      vm.changeSensor = changeSensor;
      loadExternalModules();

      vm.opened = false;
      vm.portList = _.range(1, 17);
      vm.totals = _.range(0, 16);
      vm.models = _.range(0, 14);
      module_array = [];
      vm.widthOptions = [
        '1 "',
        '2 "',
        '3 "',
        '6 "',
        '9 "',
        '12 "',
        '18 "',
        '24 "',
        '30 "',
        '36 "',
        '48 "',
        '60 "',
        '72 "',
        '84 "',
        '96 "',
        '120 "',
        '144 "',
        '180 "',
        '240 "',
        '360 "',
        '480 "',
        '600 "',
      ];

      vm.formatOptions = [
        '000.00  m3/h',
        '0000.0  m3/h',
        '00000   m3/h',
        '000.00  m3/s',
        '0000.0  m3/s',
        '00000   m3/s',
        '000.00  l/s',
        '0000.0  l/s',
        '00000   l/s',
        '000.00 GPM',
        '0000.0 GPM',
        '00000  GPM',
      ];
    }

    //currently not used
    /* function convertDDToDMS(D, lng) {
      return {
        dir: D < 0 ? (lng ? 'W' : 'S') : lng ? 'E' : 'N',
        deg: 0 | (D < 0 ? (D = -D) : D),
        min: 0 | ((D % 1) * 60),
        sec: (0 | (((D * 60) % 1) * 6000)) / 100,
      };
    }*/

    function prepareModule() {
      if (vm.module.readTime === 0) {
        if (vm.module.model !== 12) {
          vm.module.readTime = 5;
        } else {
          vm.module.readTime = 1;
        }
      }
      if (vm.module.model === 13) {
        prepareLatitudeLongitude();
      }
    }

    function prepareLatitudeLongitude() {
      vm.pc = {};
      vm.pi = {};
      var pc_lat = vm.module.latCP / 1000000;
      var pc_lng = vm.module.lngCP / 1000000;
      var coords = formatcoords(pc_lat, pc_lng);
      vm.pc.lat = {};
      vm.pc.lng = {};
      vm.pc.lat.degrees = coords.latValues.degreesInt;
      vm.pc.lat.minutes = coords.latValues.minutesInt;
      vm.pc.lat.seconds = Number(coords.latValues.seconds.toFixed(2)) * 100;
      vm.pc.lng.degrees = coords.lonValues.degreesInt;
      vm.pc.lng.minutes = coords.lonValues.minutesInt;
      vm.pc.lng.seconds = Number(coords.lonValues.seconds.toFixed(2)) * 100;
      vm.pc.lat.north = coords.north ? 'N' : 'S';
      vm.pc.lng.east = coords.east ? 'E' : 'W';
      pc_lat = vm.module.latIP / 1000000;
      pc_lng = vm.module.lngIP / 1000000;
      coords = formatcoords(pc_lat, pc_lng);
      vm.pi.lat = {};
      vm.pi.lng = {};
      vm.pi.lat.degrees = coords.latValues.degreesInt;
      vm.pi.lat.minutes = coords.latValues.minutesInt;
      vm.pi.lat.seconds = Number(coords.latValues.seconds.toFixed(2)) * 100;
      vm.pi.lng.degrees = coords.lonValues.degreesInt;
      vm.pi.lng.minutes = coords.lonValues.minutesInt;
      vm.pi.lng.seconds = Number(coords.lonValues.seconds.toFixed(2)) * 100;
      vm.pi.lat.north = coords.north ? 'N' : 'S';
      vm.pi.lng.east = coords.east ? 'E' : 'W';
    }

    function convertDMStoDS() {
      var formated_lat, formated_lng;
      formated_lat =
        vm.pc.lat.degrees + '° ' + vm.pc.lat.minutes + "' " + vm.pc.lat.seconds / 100 + '" ' + vm.pc.lat.north;
      formated_lng =
        vm.pc.lng.degrees + '° ' + vm.pc.lng.minutes + "' " + vm.pc.lng.seconds / 100 + '" ' + vm.pc.lng.east;
      var tmp = ParseDMS(formated_lat + ' ' + formated_lng);
      if (tmp !== undefined && tmp.lat && tmp.lon) {
        vm.module.latCP = tmp.lat * 1000000;
        vm.module.lngCP = tmp.lon * 1000000;
      }
      formated_lat =
        vm.pi.lat.degrees + '° ' + vm.pi.lat.minutes + "' " + vm.pi.lat.seconds / 100 + '" ' + vm.pi.lat.north;
      formated_lng =
        vm.pi.lng.degrees + '° ' + vm.pi.lng.minutes + "' " + vm.pi.lng.seconds / 100 + '" ' + vm.pi.lng.east;
      tmp = ParseDMS(formated_lat + ' ' + formated_lng);
      if (tmp !== undefined && tmp.lat && tmp.lon) {
        vm.module.latIP = tmp.lat * 1000000;
        vm.module.lngIP = tmp.lon * 1000000;
      }
    }

    function loadExternalModules() {
      commFactory.linkBoxEM(vm.currentUnit.id, 8).then((modules) => {
        if (modules[0]) {
          vm.link = modules[0];

          backup();
        }
      });
    }

    function save() {
      vm.form.$setPristine();
      vm.link.unittype = vm.currentUnit.type;
      commFactory.updateEM(vm.currentUnit.id, module_array).then(
        (result) => {
          module_array = [];
          vm.link.externalModules = null;
          commFactory.updateLinkBox(vm.currentUnit.id, vm.link).then(
            (result) => {
              backup();
              loadExternalModules();
            },
            (error) => {}
          );
        },
        (error) => {}
      );
    }

    function openModuleWindow(index) {
      vm.page = index;
      vm.opened = true;
      vm.module = angular.copy(vm.link.externalModules[vm.page]);
      prepareModule();
      document.getElementById('vegga-modal-agrobee').show();

      $anchorScroll();
    }

    function cancelModule() {
      vm.opened = false;
      document.getElementById('vegga-modal-agrobee').hide();
    }

    function saveModule() {
      const index = vm.link.externalModules.findIndex((externalModule) => externalModule.pk.id === vm.module.pk.id);

      vm.link.externalModules[index] = angular.copy(vm.module);
      const key = module_array.findIndex((m) => m.pk.id === vm.module.pk.id);

      if (key !== -1) {
        module_array[key] = angular.copy(vm.module);
      } else {
        module_array.push(vm.module);
      }
      vm.opened = false;
      document.getElementById('vegga-modal-agrobee').hide();
      vm.form.$dirty = true;
    }

    function changeSensor(e) {
      if (e.detail) {
        vm.module = vm.link.externalModules.find((externalModule) => externalModule.pk.id === e.detail.value);
        prepareModule();
      }
    }

    function cancel_edition() {
      vm.form.$setPristine();
      angular.copy(link_backup, vm.link);
    }

    function backup() {
      link_backup = {};
      angular.copy(vm.link, link_backup);
      vm.link.backup = true;
    }

    $scope.$on('refresh', function (event, args) {});

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e, args) => {
      save();
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
