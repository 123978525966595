import moment from 'moment/moment';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UNITS } from './units.enum';

angular.module('agronicwebApp').constant('moment', moment).controller('legacyRouter', legacyRouter);

legacyRouter.$inject = ['$stateParams', '$location', '$state', '$scope', 'unitFactory', 'UserData'];

function legacyRouter($stateParams, $location, $state, $scope, unitFactory, UserData) {
  var vm = this;
  vm.destroy$ = new Subject();
  activate();

  function activate() {
    const unitId = $stateParams.unitId;
    const state = $stateParams.state;
    const { path } = $location.search();

    if (!unitId) {
      $state.go('units');
    }

    unitFactory
      .getUnits(UserData.id)
      .pipe(
        // get units data
        switchMap((units) => {
          const unitType = (units.find((unit) => unit.id === unitId) || {}).type;
          const params = unitType === UNITS.A_7000 ? {} : { add: ['format'] };
          return unitFactory.getMergedUnit(UserData.id, +unitId, params).pipe(take(1));
        }),
        takeUntil(vm.destroy$),
      )
      .subscribe((unit) => {
        $state.go(state, { unit, unitId: unit.id, overlayPath: path });
      });
  }

  $scope.$on('$destroy', function () {
    vm.destroy$.next();
    vm.destroy$.complete();
  });
}
