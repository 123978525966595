import { getContainer } from '@vegga/front-store';
import moment from 'moment/moment';
import { UNITS } from '../utils/units.enum';
import { Subject, takeUntil } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .constant('moment', moment)

    .controller('registerListController', registerListController);

  registerListController.$inject = ['$scope', '$state', 'UserData', 'registerFactory', '$filter', 'unitFactory'];

  function registerListController($scope, $state, UserData, registerFactory, $filter, unitFactory) {
    var vm = this;
    vm.registerData;
    vm.fromToFilter = [];
    vm.currentPage = 1;
    vm.itemsPerPage = 15;
    vm.totalRegisters;
    vm.from = moment().subtract(7, 'day').format('DD/MM/YYYY');
    vm.to = moment().format('DD/MM/YYYY');
    vm.UNITS = UNITS;

    if (!UserData.profesional) {
      vm.minDate = moment().subtract(7, 'day').format('DD/MM/YYYY');
    } else {
      vm.minDate = null;
    }
    vm.maxDate = moment().format('DD/MM/YYYY');

    vm.anomaly = true;
    vm.prog = true;
    vm.comm = true;
    vm.clean = true;
    vm.fert = true;
    vm.other = true;
    vm.applyFilters = applyFilters;
    vm.updateFilterDate = updateFilterDate;
    vm.pageChangeHandler = pageChangeHandler;
    vm.pageSizeChangeHandler = pageSizeChangeHandler;

    vm.gridColumnsDef = [
      {
        headerName: '',
        field: 'warningNewType',
        width: 70,
        cellTemplate: (registerItem) =>
          (registerItem.type === 0 && vm.currentUnit.type !== UNITS.A_4500) ||
          (registerItem.type === null && vm.currentUnit.type !== UNITS.A_4500) ||
          (registerItem.type === 1 && vm.currentUnit.type === UNITS.A_4500)
            ? '<vegga-icon icon="bell"> </vegga-icon>'
            : '',
      },
      {
        headerName: $filter('translate')('register.date'),
        field: 'date',
        width: 150,
        cellTemplate: (registerItem) => moment(registerItem.date, 'DD-MM-YYYY HH:mm').format('DD/MM/YYYY'),
      },
      {
        headerName: $filter('translate')('register.hour'),
        field: 'hour',
        width: 150,
        cellTemplate: (registerItem) => moment(registerItem.date, 'DD-MM-YYYY HH:mm:ss').format('HH:mm:ss'),
      },
      { headerName: $filter('translate')('register.type'), field: 'registerType', width: 400 },
      { headerName: $filter('translate')('register.desc'), field: 'desc' },
    ];
    vm.destroy$ = new Subject();
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    activate();

    function activate() {
      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((currentUnit) => {
        if (!currentUnit) {
          return;
        }
        loadData(currentUnit);
      });
    }
    function loadData(currentUnit) {
      vm.currentUnit = currentUnit;
      initPrograms();
      initRegister();
    }
    //Setejem vm.programsDTO pel select multiple de programes
    function initPrograms() {
      if (!vm.programs) {
        vm.programs = [];

        var end = 0;
        if (vm.currentUnit.type === UNITS.A_4000) {
          end = 36;
        } else if (vm.currentUnit.type === UNITS.A_2500) {
          end = 51;
        } else if (vm.currentUnit.type === UNITS.A_BIT) {
          end = 31;
        } else if (vm.currentUnit.type === UNITS.A_7000) {
          end = 25;
        } else if (vm.currentUnit.type === UNITS.A_5500) {
          end = 17;
        } else if (vm.currentUnit.type === UNITS.A_4500) {
          if (vm.currentUnit.inoptions && vm.currentUnit.inoptions.optionPlus) {
            end = 100;
          } else {
            end = 41;
          }
        }
        _.range(1, end).forEach((x) => {
          vm.programs.push({ text: 'P' + x, value: '' + x });
        });
      }
    }

    //grid data set for the first time with the "default" filters
    function initRegister() {
      if (!vm.filter) {
        vm.filter = {
          anomaly: true,
          prog: true,
          comm: true,
          clean: true,
          fert: true,
          other: true,
          sensors: true,
          pivots: true,
          manual: true,
          cond: true,
        };

        if (vm.currentUnit.type === UNITS.A_4500) {
          vm.filter.programs = [];
        }
      }

      getRegisterData();
    }

    //grid data set with the filters applied by the user
    function applyFilters() {
      if (!vm.filter.prog) {
        vm.filter.programs = [];
      }
      vm.filter = {
        anomaly: vm.filter.anomaly,
        prog: vm.filter.prog,
        comm: vm.filter.comm,
        clean: vm.filter.clean,
        fert: vm.filter.fert,
        other: vm.filter.other,
        sensors: vm.filter.sensors,
        pivots: vm.filter.pivots,
        manual: vm.filter.manual,
        cond: vm.filter.cond,
        programs: vm.filter.programs,
      };

      getRegisterData();
    }

    //get the data from the backend //register-factory.js//
    function getRegisterData() {
      vm.showSkeleton = true;
      return registerFactory
        .get(vm.currentUnit.id, vm.filter, vm.from, vm.to, vm.currentPage, vm.itemsPerPage)
        .then((data) => {
          var register = data.plain();
          vm.totalRegisters = data.total;
          if (register.content !== undefined && register.content.length > 0) {
            registerFactory.parseRegister(vm.currentUnit.type, register.content);

            vm.registerData = register.content.map((i) => ({
              warningNewType: '',
              type: i.type,
              date: i.date,
              hour: i.date,
              registerType:
                vm.currentUnit.type !== UNITS.A_4500
                  ? $filter('translate')('register.' + i.registerType)
                  : `${$filter('translate')(`${i.group}.${i.register}`)} ${$filter('translate')(
                      `register.IDS45_${i.group}_${i.register}`
                    )}`,
              desc: i.textLine,
            }));
          } else{
            vm.registerData = []
          }
          vm.showSkeleton = false;
        });
    }

    function pageChangeHandler(event) {
      vm.currentPage = event.detail;
      getRegisterData();
    }

    function pageSizeChangeHandler(event) {
      vm.itemsPerPage = event.detail;
      vm.currentPage = 1;
      getRegisterData();
    }

    function updateFilterDate(ev) {
      const date = ev.detail;
      const [start, end] = date;
      vm.from = start;
      vm.to = end;
    }

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });

    $scope.$on('lang-update', function () {
      initRegister();
    });
  }
})();
