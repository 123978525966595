import '../components/communications/communications-controller';
import commTemplate from '../components/communications/communications.html';

import '../components/communications/pccomm/pccomm-controller';
import commPcTemplate from '../components/communications/pccomm/pc.html';

import '../components/communications/modules/modules-controller';
import modulesTemplage from '../components/communications/modules/modules.html';

import '../components/communications/modules/m120/m120-controller';
import m120Templage from '../components/communications/modules/m120/m120.html';

import '../components/communications/modules/radio16/r16-controller';
import r16Template from '../components/communications/modules/radio16/r16.html';

import '../components/communications/modules/radio24/r24-controller';
import r24Template from '../components/communications/modules/radio24/r24.html';

import '../components/communications/modules/radio433/r433-controller';
import r433Template from '../components/communications/modules/radio433/r433.html';

import '../components/communications/modules/agrobee/agrobee-controller';
import agrobeeTemplate from '../components/communications/modules/agrobee/agrobee.html';

import '../components/communications/modules/agrobeel/agrobeel-controller';
import agrobeelTemplate from '../components/communications/modules/agrobeel/agrobeel.html';

import '../components/communications/modules/sdi12/sdi12-controller';
import sdi12Template from '../components/communications/modules/sdi12/sdi12.html';

import '../components/communications/modules/davis/davis-controller';
import davisTemplate from '../components/communications/modules/davis/davis.html';

import '../components/communications/sms/sms-controller';
import smsTemplate from '../components/communications/sms/sms.html';

import phoneTemplate from '../components/communications/sms/phone.html';

import otherTemplate from '../components/communications/sms/other.html';

import '../components/communications/psep/psep-controller';
import psepTemplate from '../components/communications/psep/psep.html';

import '../components/communications/modbus/modbus-controller';
import modbusTemplate from '../components/communications/modbus/modbus.html';

import '../components/communications/modules/modbus-port/modbusPort-controller';
import modbusPortTemplate from '../components/communications/modules/modbus-port/modbusPort.html';

import '../components/communications/modules/modbus-externo/modbusExterno-controller';
import modbusExternoTemplate from '../components/communications/modules/modbus-externo/modbusExterno.html';

import '../components/communications/gprs/gprs-controller';
import gprsTemplate from '../components/communications/gprs/gprs.html';

import '../components/communications/wifi/wifi-controller';
import wifiTemplate from '../components/communications/wifi/wifi.html';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .config([
      '$stateProvider',
      '$urlRouterProvider',
      function ($stateProvider, $urlRouterProvider) {
        $stateProvider
          .state('communications', {
            parent: 'unit',
            url: '/communications',
            template: commTemplate,
            controller: 'commController',
            authenticate: true,
            controllerAs: 'vm',
          })
          .state('communications.pc', {
            url: '/pc',
            template: commPcTemplate,
            controller: 'pcCommController',
            authenticate: true,
            controllerAs: 'vm',
          })
          .state('communications.modules', {
            resolve: {
              Module: [
                'commFactory',
                '$stateParams',
                function (commFactory, $stateParams) {
                  if ($stateParams.unitId !== null) {
                    return commFactory.module($stateParams.unitId);
                  } else {
                    return null;
                  }
                },
              ],
            },
            url: '/modules',
            template: modulesTemplage,
            controller: 'modulesController',
            authenticate: true,
            controllerAs: 'vm',
          })
          .state('communications.modules.m120', {
            url: '/monocable120',
            template: m120Templage,
            controller: 'm120Controller',
            authenticate: true,
            controllerAs: 'vm',
          })
          .state('communications.modules.r16', {
            url: '/radio868-16',
            template: r16Template,
            controller: 'r16Controller',
            authenticate: true,
            controllerAs: 'vm',
          })
          .state('communications.modules.r24', {
            url: '/radio24',
            template: r24Template,
            controller: 'r24Controller',
            authenticate: true,
            controllerAs: 'vm',
          })
          .state('communications.modules.r433', {
            url: '/radio433',
            template: r433Template,
            controller: 'r433Controller',
            authenticate: true,
            controllerAs: 'vm',
          })
          .state('communications.modules.agrobee', {
            url: '/agrobee',
            template: agrobeeTemplate,
            controller: 'agrobeeController',
            authenticate: true,
            controllerAs: 'vm',
          })
          .state('communications.modules.agrobeel', {
            url: '/agrobeel',
            template: agrobeelTemplate,
            controller: 'agrobeelController',
            authenticate: true,
            controllerAs: 'vm',
          })
          .state('communications.modules.agrobeel2', {
            url: '/agrobeel',
            template: agrobeelTemplate,
            controller: 'agrobeelController',
            authenticate: true,
            controllerAs: 'vm',
          })
          .state('communications.modules.sdi12', {
            url: '/sdi12',
            template: sdi12Template,
            controller: 'sdi12Controller',
            authenticate: true,
            controllerAs: 'vm',
          })
          .state('communications.modules.davis', {
            resolve: {
              Davis: [
                'commFactory',
                '$stateParams',
                function (commFactory, $stateParams) {
                  if ($stateParams.unitId !== null) {
                    return commFactory.getDavis($stateParams.unitId);
                  } else {
                    return null;
                  }
                },
              ],
            },
            url: '/davis',
            template: davisTemplate,
            controller: 'davisController',
            authenticate: true,
            controllerAs: 'vm',
          })
          .state('communications.modules.external-modbus', {
            resolve: {
              ModBusExterno: [
                'commFactory',
                '$stateParams',
                function (commFactory, $stateParams) {
                  if ($stateParams.unitId !== null) {
                    return commFactory.modBusExterno($stateParams.unitId);
                  } else {
                    return null;
                  }
                },
              ],
            },
            url: '/external-modbus',
            template: modbusExternoTemplate,
            controller: 'modbusExternoController',
            authenticate: true,
            controllerAs: 'vm',
          })
          /******/
          // WARNING: there already exists a state with the same content, however, the state name is not correct, neither the url
          .state('communications.modules.modbus-port', {
            resolve: {
              ModBusPort: [
                'commFactory',
                '$stateParams',
                function (commFactory, $stateParams) {
                  if ($stateParams.unitId !== null) {
                    return commFactory.modBusClient($stateParams.unitId);
                  } else {
                    return null;
                  }
                },
              ],
            },
            url: '/modbus-port',
            template: modbusPortTemplate,
            controller: 'modbusPortController',
            authenticate: true,
            controllerAs: 'vm',
          })
          .state('communications.sms', {
            resolve: {
              SMS: [
                'commFactory',
                '$stateParams',
                function (commFactory, $stateParams) {
                  if ($stateParams.unitId !== null) {
                    return commFactory.SMS($stateParams.unitId);
                  } else {
                    return null;
                  }
                },
              ],
            },
            url: '/sms',
            template: smsTemplate,
            controller: 'smsController',
            authenticate: true,
            controllerAs: 'vm',
          })
          .state('communications.sms.phone', {
            url: '/phone',
            template: phoneTemplate,
            authenticate: true,
            controllerAs: 'vm',
          })
          .state('communications.sms.other', {
            url: '/other',
            template: otherTemplate,
            authenticate: true,
            controllerAs: 'vm',
          })
          .state('communications.psep', {
            resolve: {
              PSEP: [
                'commFactory',
                '$stateParams',
                function (commFactory, $stateParams) {
                  if ($stateParams.unitId !== null) {
                    return commFactory.PSEP($stateParams.unitId);
                  } else {
                    return null;
                  }
                },
              ],
            },
            url: '/psep',
            template: psepTemplate,
            controller: 'psepController',
            authenticate: true,
            controllerAs: 'vm',
          })
          .state('communications.modbus', {
            resolve: {
              ModBus: [
                'commFactory',
                '$stateParams',
                function (commFactory, $stateParams) {
                  if ($stateParams.unitId !== null) {
                    return commFactory.modBusClient($stateParams.unitId);
                  } else {
                    return null;
                  }
                },
              ],
            },
            url: '/modbus',
            template: modbusTemplate,
            controller: 'modbusController',
            authenticate: true,
            controllerAs: 'vm',
          })
          .state('communications.gprs', {
            resolve: {
              GPRS: [
                'commFactory',
                '$stateParams',
                function (commFactory, $stateParams) {
                  if ($stateParams.unitId !== null) {
                    return commFactory.GPRS($stateParams.unitId);
                  } else {
                    return null;
                  }
                },
              ],
            },
            url: '/gprs',
            template: gprsTemplate,
            controller: 'gprsController',
            authenticate: true,
            controllerAs: 'vm',
          })
          .state('communications.wifi', {
            resolve: {
              Wifi: [
                'commFactory',
                '$stateParams',
                function (commFactory, $stateParams) {
                  if ($stateParams.unitId !== null) {
                    return commFactory.Wifi($stateParams.unitId);
                  } else {
                    return null;
                  }
                },
              ],
            },
            url: '/wifi',
            template: wifiTemplate,
            controller: 'wifiController',
            authenticate: true,
            controllerAs: 'vm',
          });
      },
    ]);
})();
