import { Subject, takeUntil } from 'rxjs';
import { getContainer } from '@vegga/front-store';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('others45Controller', others45Controller);

  others45Controller.$inject = ['$state', '$scope', '$confirm', '$filter', 'unitFactory'];

  function others45Controller($state, $scope, $confirm, $filter, unitFactory) {
    var vm = this;
    vm.activeList;
    vm.getType;
    vm.save;
    vm.cancel;
    vm.changeState = changeState;
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    vm.destroy$ = new Subject();
    activate();

    function activate() {
      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((currentUnit) => {
        if (!currentUnit) {
          return;
        }
        vm.currentUnit = currentUnit;
        loadData();
      });
    }

    function loadData() {
      vm.currentState = $state;
      if ($state.current.name === 'a45others') $state.go('a45others.others', { unit: vm.currentUnit });
    }

    function changeState(state) {
      if (vm.form && vm.form.$dirty) {
        $confirm({
          text: $filter('translate')('programs.edit.cancelq'),
          title: $filter('translate')('programs.prog2'),
        }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.form.$setPristine(); //Actualitzem estat del formulari a inicial
          $state.go('a45others.' + state, { unit: vm.currentUnit });
        });
      } else {
        $state.go('a45others.' + state, { unit: vm.currentUnit });
      }
    }

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });

    /**
     * Event listener per gestionar l'estat del formulari
     */
    $scope.$on('formUpdated', (e, args) => {
      vm.form = args;
    });
  }
})();
