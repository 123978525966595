import { UNITS, UNITS_V2 } from '../../utils/units.enum';
import {
  IOSelectorItemResponseElementTypeEnum,
  IOSelectorItemResponseOutputTypeEnum,
} from '../../utils/enums/io-selector.enum';
import { from, of, Subject } from 'rxjs';
import { getContainer } from '@vegga/front-store';
import { switchMap, take } from 'rxjs/operators';

(function () {
  'use strict';

  angular.module('agronicwebApp').controller('outDieselController', outDieselController);
  outDieselController.$inject = ['$scope', '$state', 'configFactory', 'unitFactory'];

  function outDieselController($scope, $state, configFactory, unitFactory) {
    var vm = this;
    var backup;
    vm.activeList;
    vm.save;
    vm.cancel;
    vm.open;
    vm.currentHeader;
    vm.UNITS = UNITS;
    vm.IOSelectorItemResponseOutputTypeEnum = IOSelectorItemResponseOutputTypeEnum;
    vm.IOSelectorItemResponseElementTypeEnum = IOSelectorItemResponseElementTypeEnum;

    vm.destroy$ = new Subject();
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    vm.ioFacade = getContainer().resolve('ioFacade');
    vm.save = save;
    vm.cancel = cancel_edition;
    vm.open = open;
    activate();

    function activate() {
      vm.devicesFacade.legacyUnitResponse.value$.pipe(take(1)).subscribe((currentUnit) => {
        if (!currentUnit) {
          $state.go('units');
        }
        vm.currentHeader = $state.params.headerId || 1;
        vm.currentUnit = currentUnit;
        if (vm.currentUnit.type === vm.UNITS.A_4500) {
          loadDiesel45();
        }
        vm.selected = 0;
        vm.loaded = false;
        vm.currentState = $state.includes;
      });
    }

    function open(index) {
      if (vm.selected === index) {
        vm.selected = null;
      } else {
        vm.selected = index;
      }
    }

    function loadDiesel45() {
      vm.ioFacade.loadConfiguredOutputs({
        deviceId: vm.currentUnit.id,
        deviceType: UNITS_V2[vm.currentUnit.type],
      });

      // Init StagingList in order to check the current io selector.
      vm.ioFacade.initializationStagingOutputs(vm.currentUnit.id, UNITS_V2[vm.currentUnit.type]);

      configFactory.getDiesel(vm.currentUnit.id, vm.currentHeader).then((result) => {
        vm.config = result.plain();
        backup = angular.copy(vm.config);
        vm.loaded = true;
      });
    }

    function save() {
      vm.form.$setPristine();
      vm.config.unittype = vm.currentUnit.type;
      if (vm.currentUnit.type === vm.UNITS.A_4500) {
        vm.ioFacade
          .validateOutputsToTheCurrentView()
          .pipe(
            switchMap((result) => {
              if (result) {
                return from(configFactory.saveHeaderDiesel(vm.currentUnit.id, vm.currentHeader, vm.config));
              } else {
                return of(null);
              }
            }),
          )
          .subscribe((response) => {
            if (response) {
              vm.config = response;
              backup = angular.copy(vm.config);
            }
          });
      }
    }

    function cancel_edition() {
      vm.form.$setPristine();
      vm.config = angular.copy(backup);
    }

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      },
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e, args) => {
      save();
    });

    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
