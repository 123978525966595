const colors = require('../../../resources/vegga.tokens.colors.json');
import { UNITS } from '../../utils/units.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('moduleLayerController', moduleLayerController);

  moduleLayerController.$inject = ['$rootScope', '$scope', '$state', 'mapsFactory', 'commFactory', '$q'];

  function moduleLayerController($rootScope, $scope, $state, mapsFactory, commFactory, $q) {
    var vm = this;
    var layerLoaded;
    var conectionLines;
    vm.module;

    activate();

    /*******************/

    function activate() {
      if (!$state.params.module) {
        $state.go('^');
        return;
      }

      vm.actionView = 0; //Default view INFO, 1 = Manual, 2 = Edition area
      vm.selectEquipo = false;
      vm.equipoInfo = false;
      vm.deleteModuleMarker = deleteModuleMarker;
      vm.closeModuleInfo = closeModuleInfo;
      vm.returnType = returnType;
      vm.loadLinkBoxes = loadLinkBoxes;
      vm.loadModules = loadModules;
      vm.parseLastComm = parseLastComm;
      vm.checkStatus = checkStatus;
      vm.UNITS = UNITS;
      layerLoaded = false;
      if ($state.params.module !== null) {
        vm.currentUnit = $state.params.unit;
        vm.module = $state.params.module;
      }
    }

    vm.types = {
      1: { type: 'Monocable', name: 'AM120', cod: 'EAM', mod: 'MAM' },
      2: { type: 'Radio', name: 'AR868-16', cod: 'EAR', mod: 'MAR' },
      3: { type: 'Radio', name: 'AR24', cod: 'EAR', mod: 'MAR' },
      4: { type: 'Radio', name: 'AR433', cod: 'EAR', mod: 'MAR' },
      5: { type: 'Radio', name: 'MI', cod: 'MI' },
      6: { type: 'Radio', name: 'ME', cod: 'ME' },
      7: { type: 'Radio', name: 'R868', cod: 'EAR', mod: 'MAR' },
      8: { type: 'Radio', name: 'AgroBee', cod: 'Coordinador', mod: 'Módulo' },
      9: { type: 'Radio', name: 'AgroBeeL', cod: 'Coordinador', mod: 'Módulo' },
      10: { type: 'Radio', name: 'SDI12', cod: '', mod: 'Dispositivo' },
    };

    vm.agroBeeTypes = {};

    function checkStatus(device) {
      if (device && device.status !== undefined && device.status === 'ok') {
        return device.irrigation ? 'irrig' : 'nirrig';
      } else if (device && device.status !== undefined) {
        return device.status;
      }
    }
    function parseLastComm(value) {
      var s = [];
      for (var j = 0; j < 16; j++) s.push((value & (1 << j)) >> j);
      return s;
    }

    function closeModuleInfo() {
      $rootScope.$broadcast('closeModule');
      $state.go('^');
    }

    function returnType(type) {
      switch (type) {
        case 2:
          return 'Agrónic 4000';
        case 3:
          return 'Agrónic 2500';
        case 4:
          return 'Agrónic BIT';
        case 5:
          return 'Agrónic 7000';
        case 6:
          return 'Agrónic 5500';
      }
    }

    function deleteModuleMarker() {
      $rootScope.$broadcast('deleteModule');
      $state.go('^');
    }

    function loadLinkBoxes() {
      commFactory.linkBox(vm.unit.id).then((list) => {
        vm.linkBoxes = list.plain();
      });
    }

    function loadModules() {
      if (vm.moduleType === 1 || vm.moduleType === 0) {
        commFactory.linkBoxEM(vm.unit.id, vm.linkbox.pk.type).then((modules) => {
          if (modules[0]) {
            vm.modules = modules[0].externalModules;
          }
        });
      }
    }

    $scope.$on('actionViewChange', (ev, args) => {
      vm.actionView = args;
    });

    $scope.$on('editPaginatorChange', (ev, { moduleLayer, currentUnit }) => {
      vm.module = moduleLayer.properties.module;
      vm.currentUnit = currentUnit;
    });
  }
})();
