import { A4500_DEFAULT_CONFIG } from '../utils/device-config';
import { UNITS } from '../utils/units.enum';
import moment from 'moment/moment';
import { showLoader, hideLoader } from '@vegga/front-utils';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')
    .constant('moment', moment)
    .controller('sensorsConfigMetersController', sensorsConfigMetersController);

  sensorsConfigMetersController.$inject = ['$scope', '$state', 'sensorsFactory', '$confirm', '$filter'];

  function sensorsConfigMetersController($scope, $state, sensorsFactory, $confirm, $filter) {
    var vm = this;
    vm.activeList;
    vm.lastSensor;
    vm.nextSensor;
    vm.selectSensor;
    var PAGE_LIMIT;
    activate();

    function activate() {
      vm.overlayEl = document.querySelector('#sensor-config-overlay');
      vm.overlayEl.show();
      vm.currentState = $state.includes;
      if ($state.params.unit !== null) {
        vm.minDate = null;
        vm.UNITS = UNITS;
        vm.currentUnit = $state.params.unit;
        vm.formats = vm.currentUnit.formats;
        vm.selectSensor = loadMeters;
        vm.nextSensor = nextSensor;
        vm.lastSensor = lastSensor;
        vm.cancel = cancel_edition;
        vm.getFormatExample = getFormatExample;
        vm.save = save;
        vm.getFormatByKey = getFormatByKey;
        vm.setFormat = setFormat;
        vm.checkMeterType = checkMeterType;
        vm.updateDate = updateDate;
        if (vm.currentUnit.type === vm.UNITS.A_BIT) {
          vm.totals = _.range(1, 21);
          PAGE_LIMIT = 20;
        } else if (vm.currentUnit.type === UNITS.A_4500) {
          vm.totals = _.range(1, 81);
          PAGE_LIMIT = 80;
          loadAllMeters();
        } else {
          vm.totals = _.range(1, 11);
          PAGE_LIMIT = 10;
        }
        loadMeters(1);
        if (vm.currentUnit.type === UNITS.A_4500) {
          vm.measureTypes = [
            $filter('translate')('magnitudes.volume'),
            $filter('translate')('magnitudes.energy'),
            $filter('translate')('magnitudes.units'),
          ];

          vm.meterTypes = [
            $filter('translate')('sensors.meters.types.digital'),
            $filter('translate')('sensors.meters.types.analog'),
            $filter('translate')('sensors.meters.types.frequency'),
            $filter('translate')('sensors.meters.types.summation'),
            $filter('translate')('sensors.meters.types.pluviometer'),
          ];

          vm.formatAcc = [
            A4500_DEFAULT_CONFIG.formats.metersAccumulated.volume,
            A4500_DEFAULT_CONFIG.formats.metersAccumulated.energy,
            A4500_DEFAULT_CONFIG.formats.metersAccumulated.unit,
          ];
          vm.formatUnits = {
            0: A4500_DEFAULT_CONFIG.formats.metersFlow.volume,
            1: A4500_DEFAULT_CONFIG.formats.metersFlow.energy,
            2: A4500_DEFAULT_CONFIG.formats.metersFlow.unit,
          };
        }
        loadMeters(1);
        vm.flowFormats = ['m3/h', 'L/h', 'L/s'];
        vm.acumulatedFormats = [
          '0000 m3',
          '000.0 m3',
          '000.00 m3',
          '0000 L',
          '000.0 L',
          '000.00 L',
          '0000 L/m2',
          '000.0 L/m2',
          '000.00 L/m2',
        ];
        vm.setColor = setColor;
        vm.captionRange = _.range(0, 5);
        vm.changeSensor = changeSensor;
        vm.changingSensor = changingSensor;
        vm.nextOrPreviousItem = nextOrPreviousItem;
        vm.selectFormat = selectFormat;
        vm.resetValues = resetValues;
        vm.setFlowFormat = setFlowFormat;
        vm.changeMeasureType = changeMeasureType;
        vm.changeMeterType = changeMeterType;
        vm.changeFlowFormat = changeFlowFormat;
        vm.changeAccumulatedFormat = changeAccumulatedFormat;
        vm.changeSummation = changeSummation;
      } else {
        $state.go('units');
      }
    }

    function changeMeasureType($event) {
      vm.sensor.measureType = $event.detail.value;
      vm.form_m.$dirty = true;
      vm.setFlowFormat();
      vm.setFormat();
    }

    function changeMeterType($event) {
      vm.sensor.meterType = $event.detail.value;
      vm.form_m.$dirty = true;
      vm.checkMeterType();
    }

    function changeFlowFormat($event) {
      vm.sensor.flowFormat = $event.detail.value;
      vm.form_m.$dirty = true;
      vm.setFormat();
      vm.resetValues();
    }

    function changeAccumulatedFormat($event) {
      vm.sensor.acumulatedFormat = $event.detail.value;
      vm.form_m.$dirty = true;
    }

    function changeSummation($event, prop) {
      if (prop === 'summation1') {
        vm.sensor.summation1 = $event.detail.value;
        if (vm.currentUnit.type === UNITS.A_4500) {
          const firstOfSensorsRange = vm.allSensors.find((meter) => meter.pk.id === vm.sensor.summation1);
          vm.sensor.flowFormat = firstOfSensorsRange.flowFormat;
        }
      }
      if (prop === 'summation2') {
        vm.sensor.summation2 = $event.detail.value;
      }

      vm.form_m.$dirty = true;
    }

    function getFormatByKey(key, type) {
      return type === 'units' ? vm.formatUnits[key] : vm.formatAcc[key];
    }

    function loadMeters(n) {
      showLoader();
      vm.sensor = {};
      vm.loading = true;
      vm.page = n;
      sensorsFactory.metersOne(vm.currentUnit.id, n).then(function (data) {
        angular.copy(data.plain(), vm.sensor);
        vm.sensorDateReadInDateDTO = convertDate(vm.sensor.dateReadInDate);
        if (vm.currentUnit.type === UNITS.A_4500) {
          setFormat();
        }
        hideLoader();
        vm.loading = false;
      });
    }

    function loadAllMeters() {
      vm.allSensors = {};
      vm.loading = true;
      sensorsFactory.meters(vm.currentUnit.id).then(function (data) {
        vm.allSensors = data.plain();
        vm.loading = false;
      });
    }

    function filterAllMeters(metersList, meterId) {
      return metersList.filter((elem) => elem !== meterId);
    }

    function lastSensor() {
      --vm.page;
      if (vm.page === 0) {
        vm.page = PAGE_LIMIT;
      }
      if (vm.currentUnit.type === UNITS.A_4500) {
        vm.sensor = vm.allSensors[page - 1];
        vm.sensorDateReadInDateDTO = convertDate(vm.sensor.dateReadInDate);
      }
      loadMeters(vm.page);
    }

    function nextSensor() {
      ++vm.page;
      if (vm.page > PAGE_LIMIT) {
        vm.page = 1;
      }
      if (vm.currentUnit.type === UNITS.A_4500) {
        vm.sensor = vm.allSensors[vm.page - 1];
        vm.sensorDateReadInDateDTO = convertDate(vm.sensor.dateReadInDate);
      }
      loadMeters(vm.page);
    }

    function changeSensor(e) {
      if (e.detail.value) {
        vm.page = e.detail.value;
        if (vm.currentUnit.type === UNITS.A_4500) {
          vm.sensor = vm.allSensors[vm.page - 1];
          vm.sensorDateReadInDateDTO = convertDate(vm.sensor.dateReadInDate);
        }
        loadMeters(vm.page);
      }
    }

    function changingSensor($event) {
      if (vm.form_m && vm.form_m.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.form_m.$setPristine(); //Actualitzem estat del formulari a inicial
          this.nextOrPreviousItem($event.detail.action, $event);
        });
      }
    }

    function nextOrPreviousItem(action, $event) {
      const paginator = document.querySelector('vegga-item-paginator');
      switch (action) {
        case 'next-item':
          paginator.nextItem();
          break;
        case 'previous-item':
          paginator.previousItem();
          break;
        default:
          changeSensor($event);
          break;
      }
    }

    function getFormatExample(format) {
      var example = '';
      if (format.sign) {
        example = example + '\u00B1 ';
      }

      _.times(format.integers, (o) => {
        example = example + '0';
      });
      if (format.decimals > 0) {
        example = example + '.';
        _.times(format.decimals, (o) => {
          example = example + '0';
        });
      }
      example = example + ' ' + format.units;
      return example;
    }

    function setColor() {
      vm.pickerActive = false;
      switch (vm.sensor.caption.numLvl) {
        case 3:
          vm.sensor.caption.color1 = sensorsFactory.lightenDarkenColor(vm.sensor.color.hex, 60);
          vm.sensor.caption.color2 = vm.sensor.color.hex;
          vm.sensor.caption.color3 = sensorsFactory.lightenDarkenColor(vm.sensor.color.hex, -60);
          break;
        case 5:
          vm.sensor.caption.color1 = sensorsFactory.lightenDarkenColor(vm.sensor.color.hex, 60);
          vm.sensor.caption.color2 = sensorsFactory.lightenDarkenColor(vm.sensor.color.hex, 30);
          vm.sensor.caption.color3 = vm.sensor.color.hex;
          vm.sensor.caption.color4 = sensorsFactory.lightenDarkenColor(vm.sensor.color.hex, -30);
          vm.sensor.caption.color5 = sensorsFactory.lightenDarkenColor(vm.sensor.color.hex, -60);
          break;
      }
    }

    function setFlowFormat() {
      if (vm.currentUnit.type === UNITS.A_4500) {
        vm.sensor.flowFormat =
          vm.sensor.measureType === 0
            ? 0
            : vm.sensor.measureType === 1
              ? vm.formatUnits[0].length
              : vm.formatUnits[0].length + vm.formatUnits[1].length;

        vm.sensor.acumulatedFormat =
          vm.sensor.measureType === 0
            ? 0
            : vm.sensor.measureType === 1
              ? vm.formatAcc[0].length
              : vm.formatAcc[0].length + vm.formatAcc[1].length;
      }
      if (vm.sensor.meterType === 4) {
        vm.sensor.flowFormat = 16;
      }
    }

    function resetValues() {
      vm.sensor.logicValue1 = 0;
      vm.sensor.logicValue2 = 0;
    }

    function selectFormat() {
      vm.format = getFormatByKey(vm.sensor.flowFormat - 1, 'units');
      getFormatByKey(vm.sensor.measureType, 'units')[vm.sensor.flowFormat - 1];
    }

    function setFormat() {
      let minimumAndMaximum = getMinimumMaximumIntegersDecimalsLabel();
      let minimum = minimumAndMaximum[0];
      let maximum = minimumAndMaximum[1];
      let integers = minimumAndMaximum[2];
      let decimals = minimumAndMaximum[3];
      let label = minimumAndMaximum[4];
      vm.inputFormat = {
        min: minimum,
        max: maximum,
        integers: integers,
        decimals: decimals,
        label: label,
      };
      // set everything to 0 since mask-sensor-unit doesn't watch minimum and maximum scope
      //vm.sensor.tara = 0;
      vm.sensor.minGraphic = 0;
      vm.sensor.maxGraphic = 0;
    }

    function checkMeterType() {
      if (vm.sensor.meterType === 4) {
        vm.sensor.flowFormat = 16;
        setFormat();
      } else {
        setFlowFormat();
        setFormat();
      }
    }

    function getMinimumMaximumIntegersDecimalsLabel() {
      let format;
      if (vm.sensor.flowFormat === 16) {
        format = '00.00 L/m2';
      } else if (vm.sensor.flowFormat === 17) {
        format = '00.00 mm';
      } else {
        format = getFormatByKey(vm.sensor.measureType, 'units').find(
          (format) => format.deviceFormatKey === vm.sensor.flowFormat,
        ).format;
      }

      let integersDecimalsLabel = getIntegersDecimals(format);
      let integers = integersDecimalsLabel[0];
      let decimals = integersDecimalsLabel[1];
      let label = integersDecimalsLabel[2];

      let totalDigits = integers + decimals;
      let minimum = 0;
      let maximum = '';
      for (let i = 0; i < totalDigits; i++) {
        maximum += '9';
      }

      maximum = Number((maximum / 10 ** decimals).toFixed(decimals)); // using toFixed() to avoid Float problems

      return [minimum, maximum, integers, decimals, label];
    }

    function getIntegersDecimals(format) {
      let labelAndFormat = format.split(' ');
      let integersAndDecimals = labelAndFormat[0].split('.');
      if (integersAndDecimals.length > 1) {
        return [integersAndDecimals[0].length, integersAndDecimals[1].length, labelAndFormat[1]];
      } else {
        return [integersAndDecimals[0].length, 0, labelAndFormat[1]];
      }
    }

    function save() {
      vm.loading = true;
      vm.form_m.$setPristine();
      vm.sensor.unittype = vm.currentUnit.type;
      vm.sensor.dateReadInDate = moment(vm.sensorDateReadInDateDTO, 'DD-MM-YYYY').format('DD-MM-YYYY hh:mm:ss');

      if (!moment(vm.sensor.dateReadInDate).isValid()) {
        vm.sensor.dateReadInDate = null;
      }

      sensorsFactory.updateMeter(vm.currentUnit.id, vm.sensor).then((response) => {
        vm.sensor = response.plain();

        if (vm.currentUnit.type === UNITS.A_4500) {
          const sensorIndex = vm.allSensors.findIndex((sensor) => sensor.pk.id === vm.sensor.pk.id);
          vm.allSensors[sensorIndex] = vm.sensor;

          updateSummatoryTypesSensor(vm.sensor);
        }

        vm.sensorDateReadInDateDTO = convertDate(vm.sensor.dateReadInDate);
        vm.loading = false;
        $scope.$emit('refresh-sensors');
      });
    }

    function updateSummatoryTypesSensor(sensor) {
      vm.allSensors.forEach((s, index) => {
        s.unittype = 7;
        if (s.summation1 === sensor.pk.id && s.flowFormat !== sensor.flowFormat) {
          s.flowFormat = sensor.flowFormat;
          sensorsFactory.updateMeter(vm.currentUnit.id, s).then((response) => {
            s = response.plain();
            vm.allSensors[index] = s;
            updateSummatoryTypesSensor(s);
          });
        }
      });
    }

    function convertDate(date) {
      if (!date) return;
      moment.defaultFormat = 'DD-MM-YYYY HH:mm:ss';
      let m = moment(date, moment.defaultFormat).format('DD-MM-YYYY');
      return m;
    }

    function updateDate($event) {
      vm.form_m.$dirty = true;
      vm.sensorDateReadInDateDTO = $event.detail;
    }

    function cancel_edition() {
      $state.go('sensors.detail', { unit: vm.currentUnit });
    }

    $scope.$on('refresh', function (event, args) {});

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form_m,
      () => {
        $scope.$emit('formUpdated', vm.form_m);
      },
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e, args) => {
      save(e, vm.form_m);
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });
  }
})();
