import Highcharts from 'highcharts/highcharts';
const colors = require('../../resources/vegga.tokens.colors.json');

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('cropsController', cropsController);

  cropsController.$inject = [
    '$log',
    '$rootScope',
    '$scope',
    '$state',
    'Restangular',
    '$mdDialog',
    'UserData',
    'NgTableParams',
    'sectorFactory',
    '$timeout',
    '$translate',
    '$filter',
    '$confirm',
    '$q',
  ];

  function cropsController(
    $log,
    $rootScope,
    $scope,
    $state,
    Restangular,
    $mdDialog,
    UserData,
    NgTableParams,
    sectorFactory,
    $timeout,
    $translate,
    $filter,
    $confirm,
    $q,
  ) {
    var vm = this;
    var backups, chart, _index;
    vm.devicesArray;
    vm.checkStatus;
    vm.returnType;
    vm.selectUnit;
    vm.openPrograms;
    vm.openSectors;
    vm.kc;
    vm.edit;
    vm.modalKc;
    vm.curveKc = [];
    vm.registerData;
    vm.dataGridRowClick;
    vm.showContentModal;
    vm.editOverlayView = 'info';
    vm.setOverlayState = setOverlayState;
    vm.cropSelected;
    vm.cropConfigForm;
    //Keep track of the kcType when opening config tab
    vm.isCropGroupSet = false;
    vm.prevKcType;
    vm.prevFixedKc;
    vm.filteredCrops;
    vm.repeatedErrorMessage = [];
    vm.groupName;
    vm.gridFilter = { className: -1 };
    vm.classListFilter;
    vm.currentLang;

    class kcStruct {
      constructor(idCrop, idUser, type, fixedKc, curveKc) {
        this.idCrop = idCrop;
        this.idUser = idUser;
        this.type = type;
        this.fixedKc = fixedKc;
        this.curveKc = curveKc;
      }
    }

    vm.gridColumnsDef = [
      { headerName: $filter('translate')('crops.group'), field: 'groupName' },
      {
        headerName: $filter('translate')('crops.class'),
        field: 'className',
        filterParams: (cellValue) => {
          let filtersSelected = vm.filter.class.map((filter) => (filter.name || '').toLowerCase());
          return !filtersSelected.length || filtersSelected.includes(cellValue?.toLowerCase());
        },
      },
      {
        headerName: $filter('translate')('crops.variety'),
        field: 'name',
      },
      { headerName: $filter('translate')('crops.kcvalue'), field: 'kc', cellTemplate: (crop) => getCellTemplate(crop) },
    ];

    vm.overlayGridColumnsDef = [
      { headerName: $filter('translate')('crops.day'), field: '' },
      { headerName: $filter('translate')('crops.kc'), field: '' },
      { headerName: $filter('translate')('crops.delete'), field: '' },
    ];

    vm.fixedValueText = $filter('translate')('crops.fixedvalue');
    vm.curveValueText = $filter('translate')('crops.curvevalue');

    vm.errorMessage = $filter('translate')('crops.repeatErrorMessage');

    activate();

    function activate() {
      vm.currentState = $state.includes;

      vm.filter = {};
      vm.filter.idIdioma = 1;
      vm.filter.group = 1;
      vm.filter.class = [];

      vm.loadClass = loadClass;
      vm.getClassName = getClassName;
      vm.modalKc = modalKc;
      vm.cancelEdition = cancelEdition;
      vm.addNewCurve = addNewCurve;
      vm.delCurve = delCurve;
      vm.changeGraphic = changeGraphic;
      vm.saveKc = saveKc;
      backups = {};
      vm.dataGridRowClick = dataGridRowClick;
      vm.showContentModal = false;
      vm.changeCrop = changeCrop;
      vm.changingCrop = changingCrop;
      vm.nextOrPreviousItem = nextOrPreviousItem;
      vm.cancel = cancel;
      vm.validateInputs = validateInputs;
      vm.formHasErrors = false;
      vm.gridData;
      vm.updateFilter = updateFilter;
      vm.currentLang = langConverter($translate.use());

      loadGroups();
    }

    function langConverter(lang) {
      switch (lang) {
        case 'es':
          return 1;
        case 'it':
          return 4;
        case 'en':
          return 2;
        case 'fr':
          return 3;
        case 'pt':
          return 5;
        default:
          return 1;
      }
    }

    function updateFilter(param, field) {
      vm.gridFilter = {
        ...vm.gridFilter,
        [field]: param,
      };
    }

    function loadGroups() {
      const getGroupList = sectorFactory.cropsGroupText();

      const getClassList = sectorFactory.cropsClassAllText();
      $q.all([getGroupList, getClassList]).then((response) => {
        vm.groupList = response[0].filter((grp) => grp.pk.idIdioma === vm.currentLang);
        vm.isCropGroupSet = true;

        vm.classList = response[1].filter((grp) => grp.pk.idIdioma === vm.currentLang);
        vm.classListFilter = _.filter(vm.classList, (e) => {
          return e.groupId === Number(vm.filter.group);
        });
        getDataGrid(vm.filter.group, -1);
      });
    }

    function loadClass() {
      vm.classListFilter = vm.classList.filter((classItem) => {
        return classItem.groupId === Number(vm.filter.group);
      });
      vm.filter.class = [];
      getDataGrid(vm.filter.group, -1);
    }

    function getClassName(crop) {
      vm.langFilter = {
        pk: {
          idIdioma: langConverter($translate.use()),
        },
      };
      if (vm.classList !== undefined) {
        var clase = _.filter(vm.classList, (e) => {
          return (
            e.pk.id === extractClassId(crop.pk.id) &&
            e.groupId === crop.group &&
            e.pk.idIdioma === vm.langFilter.pk.idIdioma
          );
        });
        return clase[0] ? clase[0].name : '';
      }
    }

    function getCellTemplate(crop) {
      if (crop.kc.length) {
        if (crop.kc[0].type === 0 && crop.kc[0].fixedKc !== 0) {
          return `<vegga-text class="md">${crop.kc[0].fixedKc}</vegga-text>`;
        }
        if (
          crop.kc[0].type === 1 &&
          crop.kc[0].curveKc[crop.kc[0].curveKc.length - 1] !== 0 &&
          crop.kc[0].curveKc.length &&
          crop.kc[0].curveKc[0] != 0
        ) {
          return ` <vegga-icon icon="chart"></vegga-icon>`;
        }
      } else {
        return `<vegga-text class="md">-</vegga-text>`;
      }
    }

    function extractClassId(id) {
      var el = id + '';
      if (el.length === 5) {
        el = '0' + el;
      }
      el = el.slice(0, el.length - 2);
      el = el.slice(el.length === 2 ? 1 : 2, el.length);
      return Number(el);
    }

    function loadNormal(params) {
      var pageNumber = 1;
      if (params._params) {
        pageNumber = params._params.page;
      }
      return sectorFactory.crops(pageNumber).then((data) => {
        var register = data.plain();

        if (register.content !== undefined && register.content.length > 0) {
          var response = register.content;
          vm.tableParams.total(register.totalElements);
          return response;
        }
      });
    }

    function getDataGrid(cropGroup, cropClass) {
      vm.gridData = [];

      sectorFactory.cropsTextFinal(cropGroup, cropClass).then((data) => {
        vm.gridData = data
          .plain()
          .filter((variety) => variety.pk.idIdioma === vm.currentLang)
          .map((item) => {
            return {
              groupName: vm.groupList.find((group) => group.pk.id === item.group).name,
              className: vm.classListFilter.find((cropClass) => cropClass.pk.id === item.classId)?.name,
              name: item.name,
              kc: item.kc,
              ...item,
            };
          });
      });
    }

    function modalKc(crop, edit, index) {
      let chart;
      vm.kc;
      vm.modalKc;
      vm.curveKc = [];
      vm.edit = edit;
      vm.cropName = crop.name;
      _index = index;
      var kc_backup = {};
      angular.copy(crop.kc[0], kc_backup);

      if (crop.kc.length === 0) {
        // NEW KC
        crop.kc[0] = new kcStruct(crop.pk.id, null, 0, null, null);
      }

      vm.kc = crop.kc[0];
      backups[crop.kc[0].idCrop] = kc_backup;
      if (vm.kc.curveKc !== null) {
        parseKc(vm.kc.curveKc);
        $timeout(() => {
          chart = loadGraficas();
          chart.series[0].setData(vm.curveKc);
          chart.setTitle({ text: $filter('translate')('crops.kcGraphic') });
        }, 100);
      }
    }

    function parseKc(kc) {
      var curveKc = _.split(kc, ';');
      for (var i = 0; i < curveKc.length; i++) {
        curveKc[i] = curveKc[i].split(',');
        for (var x = 0; x < curveKc[i].length; x++) {
          vm.curveKc[i] = [parseInt(curveKc[i][0]), parseFloat(curveKc[i][1])];
        }
      }
    }

    function addNewCurve() {
      vm.curveKc = vm.curveKc ?? [];

      vm.curveKc.push([0, 0]);
    }

    function delCurve(index) {
      if (vm.curveKc.length > 0) {
        vm.curveKc.splice(index, 1);
        changeGraphic();
      }
    }

    function changeGraphic() {
      if (vm.curveKc?.length > 0) {
        $timeout(() => {
          if (vm.kc.type === 1) {
            if (vm.curveKc.length > 0 && vm.curveKc[vm.curveKc.length - 1][0]) {
              chart = loadGraficas();
              const sortedCurveKc = [...vm.curveKc].sort((a, b) => a[0] - b[0]);
              chart.series[0].setData(sortedCurveKc);
              chart.setTitle({ text: $filter('translate')('crops.kcGraphic') });
            }
          }
        }, 100);
      }
    }

    function validateInputs() {
      const repetitionErrors = checkRepetitionErrors(vm.curveKc);

      vm.repeatedErrorMessage = vm.curveKc.map((_, i) => {
        if (repetitionErrors.includes(i)) {
          return vm.errorMessage;
        } else {
          return '';
        }
      });

      vm.formHasErrors = [...new Set(vm.repeatedErrorMessage)].filter((el) => el === vm.errorMessage).length;
    }

    function checkRepetitionErrors(arr) {
      const repetitionErrors = [];

      for (let i = 0; i < arr.length; i++) {
        for (let j = 0; j < arr.length; j++) {
          if (arr[i][0] === arr[j][0] && i !== j) {
            repetitionErrors.push(i);
          }
        }
      }
      return repetitionErrors;
    }

    function cancelEdition() {
      vm.showContentModal = false;
      if (_.isEmpty(backups[vm.kc.idCrop])) {
        vm.tableParams.data[_index].kc = [];
      } else angular.copy(backups[vm.kc.idCrop], vm.kc);
      $confirm({
        text: $filter('translate')('programs.edit.cancelq'),
        title: $filter('translate')('programs.prog2'),
      }).then(() => {
        vm.kc.type = vm.prevKcType;
        vm.kc.fixedKc = vm.prevFixedKc;

        vm.curveKc = vm.prevCurveKc;
        vm.cropConfigForm.$setPristine();
        angular.copy(vm.unitDTOCopy, vm.unitDTO);
        vm.editOverlayView = 'info';

        if (typeof $event !== 'undefined') {
          document.querySelector('vegga-overlay').dismiss();
          //defaultView(vm.currentUnit)
        } else {
          setOverlayState('info');
        }
      });
      if (vm.curveKc.length && vm.kc.type === 1 && vm.curvekc[vm.curveKc.length - 1] !== 0) {
        $timeout(() => {
          chart = loadInfoChart();
          chart.series[0].setData(vm.curveKc);
          chart.setTitle({ text: $filter('translate')('crops.kcGraphic') });
        }, 100);
      }
    }

    function loadGraficas() {
      let id = 'graficaKc';

      chart = new Highcharts.Chart({
        chart: {
          renderTo: id,
          type: 'line',
        },
        credits: {
          enabled: false,
        },
        exporting: {
          enabled: false,
        },
        yAxis: {
          title: [
            {
              text: undefined,
            },
          ],
        },
        series: [
          {
            showInLegend: false,
            name: 'Kc',
            color: colors['vegga-color-primary-default'],
          },
        ],
      });
      return chart;
    }

    function loadInfoChart() {
      let id = 'infoKcChart';

      chart = new Highcharts.Chart({
        chart: {
          renderTo: id,
          type: 'line',
        },
        credits: {
          enabled: false,
        },
        exporting: {
          enabled: false,
        },
        yAxis: {
          title: [
            {
              text: undefined,
            },
          ],
        },
        series: [
          {
            showInLegend: false,
            name: 'Kc',
            color: colors['vegga-color-primary-default'],
          },
        ],
      });
      return chart;
    }

    function saveKc() {
      vm.prevKcType = vm.kc.type;
      vm.prevFixedKc = vm.kc.fixedKc;
      if (vm.curveKc.length && vm.curveKc[vm.curveKc.length - 1][0] === 0) {
        vm.curveKc.pop();
      }
      vm.prevCurveKc = vm.curveKc;
      vm.cropConfigForm.$setPristine();
      const sortedCurveKc = vm.curveKc.sort((a, b) => a[0] - b[0]);
      if (sortedCurveKc.length > 0) {
        var tmp = '';
        for (var x = 0; x < sortedCurveKc.length; x++) {
          tmp += sortedCurveKc[x][0].toString() + ',' + sortedCurveKc[x][1].toString();
          if (x + 1 < sortedCurveKc.length) tmp += ';';
        }
        vm.kc.curveKc = tmp;
      }
      sectorFactory.setKcCrop(vm.kc.idCrop, vm.kc).then(() => {
        vm.gridData = getDataGrid(vm.filter.group, -1);
      });
    }

    function dataGridRowClick({ detail }) {
      const crop = detail;
      vm.cropSelected = crop;
      vm.cropGroup = detail.groupName;
      vm.cropClass = detail.className;

      const veggaOverlay = document.querySelector('vegga-overlay');
      veggaOverlay.show();
      let chart;

      vm.curveKc = [];
      var kc_backup = {};

      if (crop.kc.length === 0) {
        crop.kc[0] = new kcStruct(crop.pk.id, null, 0, null, null);
      }
      vm.kc = crop.kc[0];
      vm.kc.fixedKc = crop.kc[0].fixedKc ?? '';
      vm.prevKcType = vm.kc.type;
      angular.copy(crop.kc[0], kc_backup);
      backups[crop.kc[0].idCrop] = kc_backup;
      if (vm.cropSelected.kc[0].curveKc !== null) {
        parseKc(vm.cropSelected.kc[0].curveKc);
        if (vm.editOverlayView === 'config' && vm.curveKc.length > 0 && vm.curveKc[vm.curveKc.length - 1][0]) {
          $timeout(() => {
            chart = loadGraficas();
            chart.series[0].setData(vm.curveKc);
            chart.setTitle({ text: $filter('translate')('crops.kcGraphic') });
          }, 100);
        }
        if (
          vm.editOverlayView === 'info' &&
          vm.curveKc.length &&
          vm.kc.type === 1 &&
          vm.curveKc[vm.curveKc.length - 1][0]
        ) {
          $timeout(() => {
            chart = loadInfoChart();
            chart.series[0].setData(vm.curveKc);
            chart.setTitle({ text: $filter('translate')('crops.kcGraphic') });
          }, 100);
        }
      }

      vm.showContentModal = true;
    }

    function parseCurveKc(curveKc) {
      return curveKc ? curveKc.split(';').map((val) => val.split(',').map((num) => +num)) : null;
    }

    function setOverlayState(state) {
      if (state === vm.editOverlayView) return;
      if (state !== 'info') {
        vm.prevKcType = vm.kc.type;
        vm.prevCurveKc = parseCurveKc(vm.kc.curveKc);
      }
      vm.showContentModal = true;
      if (vm.cropConfigForm && vm.cropConfigForm.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({
          text: $filter('translate')('programs.edit.cancelq'),
          title: $filter('translate')('programs.prog2'),
        }).then(() => {
          vm.kc.type = vm.prevKcType;
          vm.kc.fixedKc = vm.prevFixedKc;
          vm.curveKc = vm.prevCurveKc;

          vm.cropConfigForm.$setPristine();
          angular.copy(backups[vm.kc.idCrop], vm.kc);
          vm.editOverlayView = state;
          if (state === 'config') {
            vm.editOverlayView === 'config';
            if (vm.curveKc[vm.curveKc.length - 1][0]) {
              $timeout(() => {
                chart = loadGraficas();
                chart.series[0].setData(vm.curveKc);
                chart.setTitle({ text: $filter('translate')('crops.kcGraphic') });
              }, 200);
            }
          } else {
            vm.kc.type = vm.prevKcType;
            vm.kc.fixedKc = vm.prevFixedKc;
            vm.curveKc = vm.prevCurveKc;

            if (
              vm.editOverlayView === 'info' &&
              vm.curveKc?.length &&
              vm.kc.type === 1 &&
              vm.curveKc[vm.curveKc.length - 1][0]
            ) {
              $timeout(() => {
                chart = loadInfoChart();
                chart.series[0].setData(vm.curveKc);
                chart.setTitle({ text: $filter('translate')('crops.kcGraphic') });
              }, 200);
            }
          }
        });
      } else {
        vm.editOverlayView = state;
        if (state === 'edit') {
          vm.editOverlayView === 'edit';
        }
        if (state === 'config') {
          vm.editOverlayView === 'config';
          if (vm?.kc?.type === 1 && vm.curveKc?.length) {
            if (vm.curveKc[vm.curveKc.length - 1][0]) {
              $timeout(() => {
                chart = loadGraficas();
                chart.series[0].setData(vm.curveKc);
                chart.setTitle({ text: $filter('translate')('crops.kcGraphic') });
              }, 200);
            }
          }
        }
        if (state === 'info' && vm.curveKc?.length && vm.kc.type === 1 && vm.curveKc[vm.curveKc?.length - 1][0]) {
          $timeout(() => {
            chart = loadInfoChart();
            chart.series[0].setData(vm.curveKc);
            chart.setTitle({ text: $filter('translate')('crops.kcGraphic') });
          }, 200);
        }
      }
    }

    function changeCrop(e) {
      if (e.detail.value) {
        vm.cropSelected = e.detail.option;

        vm.kc = vm.cropSelected.kc[0];

        let chart;
        vm.cropGroup = vm.cropSelected.groupName;
        vm.cropClass = vm.cropSelected.className;
        vm.curveKc = [];
        var kc_backup = {};

        if (vm.cropSelected.kc.length === 0) {
          vm.cropSelected.kc[0] = new kcStruct(vm.cropSelected.pk.id, null, 0, 0, null);
        }
        vm.kc = vm.cropSelected.kc[0];

        angular.copy(vm.cropSelected.kc[0], kc_backup);
        backups[vm.cropSelected.kc[0].idCrop] = kc_backup;
        vm.editOverlayView = 'info';
        if (vm.cropSelected.kc[0].curveKc !== null) {
          parseKc(vm.cropSelected.kc[0].curveKc);
          if (vm.kc.type === 1 && vm.editOverlayView === 'config' && vm.curveKc[vm.curveKc.length - 1][0]) {
            $timeout(() => {
              chart = loadGraficas();
              chart.series[0].setData(vm.curveKc);
              chart.setTitle({ text: $filter('translate')('crops.kcGraphic') });
            }, 100);
          }
          if (
            vm.editOverlayView === 'info' &&
            vm.curveKc.length &&
            vm.kc.type === 1 &&
            vm.curveKc[vm.curveKc.length - 1][0]
          ) {
            $timeout(() => {
              chart = loadInfoChart();
              chart.series[0].setData(vm.curveKc);
              chart.setTitle({ text: $filter('translate')('crops.kcGraphic') });
            }, 100);
          }
        }
        changeState({ crop: vm.cropSelected, id: vm.cropSelected.pk.id }, $state.params.config);
      }
    }

    function changingCrop($event) {
      if (vm.cropConfigForm && vm.cropConfigForm.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          vm.kc.type = vm.prevKcType;
          vm.kc.fixedKc = vm.prevFixedKc;
          vm.curveKc = vm.prevCurveKc;
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.cropConfigForm.$setPristine(); //Actualitzem estat del formulari a inicial
          this.nextOrPreviousItem($event.detail.action, $event);
        });
      }
    }

    function changeState(params) {
      vm.active = null;
      vm.crop = params.crop;
      vm.crop.op = true;
    }

    function nextOrPreviousItem(action, $event) {
      const paginator = document.querySelector('vegga-item-paginator');
      switch (action) {
        case 'next-item':
          paginator.nextItem();
          break;
        case 'previous-item':
          paginator.previousItem();
          break;
        default:
          changeCrop($event);
          break;
      }
    }

    $scope.$on('refresh', function (event, args) {
      if (args.units !== null) {
      }
    });

    $scope.$on('lang-update', (ev, args) => {
      vm.langFilter.pk.idIdioma = langConverter(args.lang);
    });

    function cancel($event) {
      if (vm.cropConfigForm && vm.cropConfigForm.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({
          text: $filter('translate')('programs.edit.cancelq'),
          title: $filter('translate')('programs.prog2'),
        }).then(() => {
          vm.kc.type = vm.prevKcType;
          vm.kc.fixedKc = vm.prevFixedKc;
          vm.curveKc = vm.prevCurveKc;
          vm.cropConfigForm.$setPristine();
          angular.copy(backups[vm.kc.idCrop], vm.kc);
          if (typeof $event !== 'undefined') {
            document.querySelector('vegga-overlay').dismiss();
            vm.editOverlayView = 'info';
            //defaultView(vm.currentUnit)
          } else {
            setOverlayState('info');
          }
        });
      } else {
        if (typeof $event !== 'undefined') {
          document.querySelector('vegga-overlay').dismiss();
          vm.editOverlayView = 'info';
          //defaultView(vm.currentUnit);
        } else {
          setOverlayState('info');
        }
      }
    }

    $scope.$watch('vm.kc.type', (e) => {});
  }
})();
