import { getContainer } from '@vegga/front-store';
import { Subject, take } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('generalHeadersController', generalHeadersController);

  generalHeadersController.$inject = ['$scope', '$state', '$confirm', '$filter', 'unitFactory', '$rootScope'];

  function generalHeadersController($scope, $state, $confirm, $filter, unitFactory, $rootScope) {
    var vm = this;
    vm.currentHeader;
    vm.changeState = changeState;
    vm.changeHeader = changeHeader;
    vm.destroy$ = new Subject();
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    activate();

    function activate() {
      vm.devicesFacade.legacyUnitResponse.value$.subscribe((currentUnit) => {
        if (!currentUnit) {
          $state.go('units');
        }
        loadData(currentUnit);
      });
    }

    function loadData(unit) {
      vm.currentUnit = unit;
      vm.currentState = $state.includes;
      vm.selected = 0;
      vm.currentHeader = $state.params.headerId || '1';
      $state.go('.filters', {
        unit: vm.currentUnit,
        id: vm.currentUnit.id,
        headerId: vm.currentHeader,
      });
    }

    function changeHeader(params) {
      vm.currentState.url = $state.current.name;
      vm.currentHeader = params.headerId;

      $rootScope.$emit('generalStateChange', { location: vm.currentState.url, params });
    }

    function changeState(location, params) {
      $rootScope.$emit('generalStateChange', { location, params });
    }

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
