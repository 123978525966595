import { getContainer } from '@vegga/front-store';
import { UNITS } from '../utils/units.enum';
import { Subject, takeUntil } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('totalsMetersController', totalsMetersController);

  totalsMetersController.$inject = ['$scope', '$state', 'NgTableParams', 'unitFactory'];

  function totalsMetersController($scope, $state, NgTableParams, unitFactory) {
    var vm = this;
    vm.activeList;
    vm.UNITS = UNITS;
    vm.deleteTotals = deleteTotals;

    vm.destroy$ = new Subject();
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    activate();

    function activate() {
      vm.currentState = $state.includes;
      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((currentUnit) => {
        if (!currentUnit) {
          return;
        }
        vm.currentUnit = currentUnit;
        initGrid();
      });
    }

    function initGrid() {
      vm.tableParams = new NgTableParams(
        {
          page: 1,
          count: 99,
        },
        {
          counts: [],
          getData: function (params) {
            return unitFactory.totalsMeter(vm.currentUnit.id).then((data) => {
              vm.tableParams.total(data.length);
              return data;
            });
          },
        },
      );
    }

    function deleteTotals() {
      $confirm({ text: 'Enviar orden manual?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No' }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        switch (vm.currentUnit.type) {
          case 2:
          case 6:
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 10;
            manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
            break;
          case 3:
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 15;
            manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
            break;
          case 4:
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 14;
            manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
            break;
          case 5:
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 13;
            manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
            break;
        }
        modalInstance.result.then((result) => {
          initGrid();
        });
      });
    }

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
