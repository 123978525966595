import moment from 'moment/moment';
import { UNITS } from '../../utils/units.enum';
import Highcharts from 'highcharts/highcharts';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .constant('moment', moment)

    .controller('sensorLayerController', sensorLayerController);

  sensorLayerController.$inject = [
    '$log',
    '$rootScope',
    '$scope',
    'Restangular',
    '$state',
    'mapsFactory',
    'sectorFactory',
    '$filter',
    'progFactory',
    '$q',
    'sensorsFactory',
    '$confirm',
    'calculatedsFactory',
    'graphicsFactory',
    '$timeout',
  ];

  function sensorLayerController(
    $log,
    $rootScope,
    $scope,
    Restangular,
    $state,
    mapsFactory,
    sectorFactory,
    $filter,
    progFactory,
    $q,
    sensorsFactory,
    $confirm,
    calculatedsFactory,
    graphicsFactory,
    $timeout,
  ) {
    var vm = this;
    var maps;
    var defaultAreaStyle;
    var props;
    var layerLoaded;
    var currentMarker;
    var data_labels;
    vm.equipo;
    var highchart;

    var datos_grafica = {};
    activate();

    /*******************/

    function activate() {
      if (!$state.params.sensor) {
        $state.go('^');
        return;
      }

      vm.deleteSensorMarker = deleteSensorMarker;
      vm.closeSensorInfo = closeSensorInfo;
      vm.returnType = returnType;
      vm.unitHour = unitHour;
      vm.loadSelectionSensors = loadSelectionSensors;
      vm.sensorHistory = sensorHistory;
      vm.advanceWeek = advanceWeek;
      vm.delayWeek = delayWeek;
      vm.history_type = 0;
      layerLoaded = false;
      vm.checkStatus = checkStatus;
      vm.loadHistoryData = loadHistoryData;
      vm.actionView = 0;
      vm.addWeekButton = true;

      vm.dateFrom = new moment().subtract(7, 'days')._d;
      vm.dateTo = new moment()._d;
      vm.dateFromFormat = moment(vm.dateFrom).format('DD-MM-YYYY');
      vm.dateToFormat = moment(vm.dateTo).format('DD-MM-YYYY');
      vm.units = $state.params.units || [];
      vm.unitsEnum = UNITS;

      if ($state.params.sensor !== null) {
        vm.sensor = $state.params.sensor;
        vm.sensor.xValue = _.round(vm.sensor.xValue, 2);
        vm.currentUnit = $state.params.unit;
        vm.shared = $state.params.map.shared;
        vm.sharingLvl = $state.params.map.sharingLvl;
        vm.type = $state.params.type;
        if (vm.currentUnit !== undefined && vm.currentUnit !== null) {
          loadHistoryData();
        }
      }
    }

    function advanceWeek() {
      var today = new moment()._d;
      today = moment().format('DD-MM-YYYY');

      vm.dateFrom = moment(vm.dateFrom).add(7, 'days')._d;
      vm.dateTo = moment(vm.dateTo).add(7, 'days')._d;

      vm.dateFromFormat = moment(vm.dateFrom).format('DD-MM-YYYY');
      vm.dateToFormat = moment(vm.dateTo).format('DD-MM-YYYY');

      if (vm.dateToFormat !== today) vm.addWeekButton = false;
      else vm.addWeekButton = true;
      sensorHistory();
    }

    function delayWeek() {
      var today = new moment()._d;
      today = moment().format('DD-MM-YYYY');

      vm.dateFrom = moment(vm.dateFrom).subtract(7, 'days')._d;
      vm.dateTo = moment(vm.dateTo).subtract(7, 'days')._d;

      vm.dateFromFormat = moment(vm.dateFrom).format('DD-MM-YYYY');
      vm.dateToFormat = moment(vm.dateTo).format('DD-MM-YYYY');

      if (vm.dateToFormat !== today) vm.addWeekButton = false;
      sensorHistory();
    }

    function checkStatus(device) {
      if (device && device.status !== undefined && device.status === 'ok') {
        return device.irrigation ? 'irrig' : 'nirrig';
      } else if (device && device.status !== undefined) {
        return device.status;
      }
    }

    function getRangeColor(caption, value) {
      switch (caption.numLvl) {
        case 3:
          if (value > caption.range1from && value <= caption.range1to) {
            return caption.color1;
          }
          if (value > caption.range2from && value <= caption.range2to) {
            return caption.color2;
          }
          if (value > caption.range3from && value <= caption.range3to) {
            return caption.color3;
          }
          if (value <= caption.range1from) {
            return caption.color1;
          }
          if (value >= caption.range3to) {
            return caption.color3;
          }
          break;
        case 5:
          if (value > caption.range1from && value < caption.range1to) {
            return caption.color1;
          }
          if (value > caption.range2from && value < caption.range2to) {
            return caption.color2;
          }
          if (value > caption.range3from && value < caption.range3to) {
            return caption.color3;
          }
          if (value > caption.range4from && value < caption.range4to) {
            return caption.color4;
          }
          if (value > caption.range5from && value < caption.range5to) {
            return caption.color5;
          }
          if (value < caption.range1from) {
            return caption.color1;
          }
          if (value > caption.range5to) {
            return caption.color5;
          }
          break;
      }
    }

    function validateCaption(sensor) {
      if (sensor.caption === null) {
        sensor.caption_valid = false;
        return sensor.caption_valid;
      } else {
        switch (sensor.caption.numLvl) {
          case 3:
            if (
              sensor.caption.range1from !== null &&
              sensor.caption.range2from !== null &&
              sensor.caption.range3from !== null
            ) {
              if (
                sensor.caption.range1to !== null &&
                sensor.caption.range2to !== null &&
                sensor.caption.range3to !== null
              ) {
                if (
                  sensor.caption.color1 !== null &&
                  sensor.caption.color2 !== null &&
                  sensor.caption.color3 !== null
                ) {
                  sensor.caption_valid = true;
                  return sensor.caption_valid;
                }
              }
            }
            sensor.caption_valid = false;
            return sensor.caption_valid;
            break;
          case 5:
            if (
              sensor.caption.range1from !== null &&
              sensor.caption.range2from !== null &&
              sensor.caption.range3from !== null &&
              sensor.caption.range4from !== null &&
              sensor.caption.range5from !== null
            ) {
              if (
                sensor.caption.range1to !== null &&
                sensor.caption.range2to !== null &&
                sensor.caption.range3to !== null &&
                sensor.caption.range4to !== null &&
                sensor.caption.range5yo !== null
              ) {
                if (
                  sensor.caption.color1 !== null &&
                  sensor.caption.color2 !== null &&
                  sensor.caption.color3 !== null &&
                  sensor.caption.color4 !== null &&
                  sensor.caption.color5 !== null
                ) {
                  sensor.caption_valid = true;
                  return sensor.caption_valid;
                }
              }
            }
            sensor.caption_valid = false;
            return sensor.caption_valid;
            break;
          default:
            sensor.caption_valid = false;
            return sensor.caption_valid;
        }
      }
    }

    function formatSensorValue(sensor, format) {
      if (format !== undefined) {
        var value = '' + sensor.xValue;
        if (format.decimals > 0) {
          var first = value.slice(0, value.length - format.decimals);
          var last = value.slice(value.length - format.decimals, value.length);
          value = first + '.' + last;
        }
        sensor.value = _.toNumber(value) + ' ' + format.units;
        value = _.toNumber(value);
        format = format.units;
      }
      return [value, format];
    }

    function showSensorInfo(e) {
      vm.history = false;
      var unit = _.filter(vm.units, { id: Number(e.layer.properties.deviceid) });
      if (!_.isEmpty(unit)) {
        vm.currentUnit = unit[0];
        vm.sensor = e.layer.properties.sensor;
        prepareSensorInfo(e.layer.properties);
        vm.areaInfo = false;
        vm.moduleInfo = false;
        vm.equipoInfo = false;
        vm.sensorInfo = true;
        currentMarker = e.layer;
      }
    }

    function loadHistoryData() {
      vm.history = false;

      switch (vm.sensor.type) {
        case 0:
          if ((vm.sensor.record && vm.currentUnit.type === UNITS.A_4000) || vm.currentUnit.type !== UNITS.A_4000) {
            switch (vm.history_type) {
              case 0:
                sensorsFactory
                  .registerAnalogs(vm.currentUnit.id, vm.sensor.pk.id, vm.currentUnit.type)
                  .then(function (data) {
                    if (!data.plain().length) {
                      vm.isAnalogsDataEmtpy = true;
                      return;
                    }
                    if (
                      (vm.currentUnit.type === UNITS.A_5500 || vm.currentUnit.type === UNITS.A_4500) &&
                      !Object.keys(data.plain()[0]).length
                    ) {
                      vm.isAnalogsDataEmtpy = true;
                      return;
                    }

                    vm.isAnalogsDataEmtpy = false;
                    populateHistoryBar(data.plain());
                  });
                break;
              case 1:
                sensorsFactory.historyAnalogs(vm.currentUnit.id, vm.sensor.pk.id).then(function (data) {
                  if (!data.length) {
                    vm.isAvgDataEmpty = true;
                    return;
                  }
                  vm.isAvgDataEmpty = false;
                  prepareLineChart(data);
                });
                break;
            }
          } else {
            vm.isAnalogsDataEmtpy = true;
            vm.isAvgDataEmpty = true;
          }
          break;
        case 1:
          vm.history_type = 0;
          if (vm.sensor.value3 === 1) {
            if (vm.sensor.pk.id === '35') {
              vm.sensor.label = 'l/m2';
            } else {
              vm.sensor.label = 'm3/h';
              prepareMeterData();
            }
            sensorsFactory
              .registerDigitals(vm.currentUnit.id, vm.sensor.pk.id, vm.currentUnit.type)
              .then(function (data) {
                if (!data.plain().length) {
                  vm.isAnalogsDataEmtpy = true;
                  return;
                }
                vm.isAnalogsDataEmtpy = false;
                populateHistoryBar(addEmptyDays(data.plain()));
              });
          } else if (
            vm.currentUnit.type === UNITS.A_2500 ||
            vm.currentUnit.type === UNITS.A_BIT ||
            vm.currentUnit.type === UNITS.A_4500
          ) {
            sensorsFactory
              .registerDigitals(vm.currentUnit.id, vm.sensor.pk.id, vm.currentUnit.type)
              .then(function (data) {
                if (!data.plain().length) {
                  vm.isAnalogsDataEmtpy = true;
                  return;
                }
                vm.isAnalogsDataEmtpy = false;
                populateHistoryBar(addEmptyDays(data.plain()));
              });
          } else if (vm.currentUnit.type === UNITS.A_4000) {
            var id;
            switch (Number(vm.sensor.pk.id)) {
              case 1:
                id = 11;
                break;
              case 2:
                id = 12;
                break;
              case 3:
                id = 13;
                break;
              case 4:
                id = 14;
                break;
              case 5:
                id = 23;
                break;
              case 6:
                id = 24;
                break;
              case 7:
                id = 25;
                break;
              case 8:
                id = 26;
                break;
            }

            sensorsFactory.registerDigitals(vm.currentUnit.id, id, vm.currentUnit.type).then(function (data) {
              if (!data.plain().length) {
                vm.isAnalogsDataEmtpy = true;
                return;
              }
              vm.isAnalogsDataEmtpy = false;
              populateHistoryBar(addEmptyDays(data.plain()));
            });
          }
          break;

        case 2:
          vm.history_type = 2;
          break;
        default:
          vm.history_type = 0;
          break;
      }
    }
    function closeSensorInfo() {
      $rootScope.$broadcast('closeSensor');
      $state.go('^');
    }
    function addEmptyDays(dates) {
      let labels = [];
      labels.push({ value: 0, type: '4', date: moment().subtract(6, 'days') });
      labels.push({ value: 0, type: '4', date: moment().subtract(5, 'days') });
      labels.push({ value: 0, type: '4', date: moment().subtract(4, 'days') });
      labels.push({ value: 0, type: '4', date: moment().subtract(3, 'days') });
      labels.push({ value: 0, type: '4', date: moment().subtract(2, 'days') });
      labels.push({ value: 0, type: '4', date: moment().subtract(1, 'days') });
      labels.push({ value: 0, type: '4', date: moment() });
      _.each(labels, (l, i) => {
        _.each(dates, (d) => {
          if (l.date.format('DD') === d.date[0] + d.date[1]) {
            labels[i] = d;
            return false;
          }
        });
      });
      return labels;
    }
    function formatSensor(value, format) {
      switch (vm.sensor.type) {
        case 0:
          return formatAnalog(value);
        case 1:
          if (_.isNumber(format)) return formatCounterA25(value, format);
          return formatCounter(value);
          break;
      }
    }
    function formatCounterA25(value, format) {
      //        vm.currentUnit.version  <= 218
      switch (format) {
        case 0: // 0000m3 register l.
          if (vm.currentUnit.version <= 218) value = value / 1000;
          vm.sensor.label = 'm3';
          break;
        case 1: // 000.0m3 register l.
          value = value / 1000;
          vm.sensor.label = 'm3';
          break;
        case 2: // 000.00m3 register l.
          value = value / 1000;
          vm.sensor.label = 'm3';
          break;
        case 3: // 00000l. register l.
          vm.sensor.label = 'l';
          break;
        case 4: // 000.0l register l.
          value = value / 100;
          vm.sensor.label = 'l';
          break;
        case 5: // 000.00l register l.
          value = value / 100;
          vm.sensor.label = 'l';
          break;
        case 6: // 0000l/m2 register l.
          vm.sensor.label = 'l/m2';
          break;
        case 7: // 000.0 l/m2 register cl.
          value = value / 100;
          vm.sensor.label = 'l/m2';
          break;
        case 8: // 000.00 l/m2 register cl.
          vm.sensor.label = 'l/m2';
          value = value / 100;
          break;
        default: // Old
          value = value / 1000;
          vm.sensor.label = 'm3';
          break;
      }
      return value;
    }
    function formatAnalog(value) {
      if (vm.sensor.format !== undefined) {
        value = '' + value;
        if (vm.sensor.format.decimals > 0) {
          var first = value.slice(0, value.length - vm.sensor.format.decimals);
          var last = value.slice(value.length - vm.sensor.format.decimals, value.length);
          value = first + '.' + last;
        }
        value = _.toNumber(value);
        vm.sensor.label = vm.sensor.format.units;
      }
      return value;
    }

    function formatCounter(value) {
      value = '' + value;
      if (vm.currentUnit.installer) {
        var decimals = 1;
        if (vm.currentUnit.installer.instantFlow === 0) {
          decimals = 2;
        }
        var first = value.slice(0, value.length - decimals);
        var last = value.slice(value.length - decimals, value.length);
        value = first + '.' + last;
      }
      return _.toNumber(value);
    }

    function populateHistoryBar(dates) {
      var format;
      vm.labels = _.map(dates, (o) => {
        return moment(o.date, 'DD-MM-YYYY HH:mm').format('DD/MM');
      });
      var background = [];
      var value = _.map(dates, (o) => {
        return formatSensor(o.value, o.type);
      });

      if (!value.length || !dates.length) {
        vm.data = null;
        return;
      }
      vm.data = [value];
      if (validateCaption(vm.sensor)) {
        _.forEach(value, (val, i) => {
          if (vm.sensor.type === 0) {
            background.push(getRangeColor(vm.sensor.caption, dates[i].value));
          } else {
            background.push(getRangeColor(vm.sensor.caption, val));
          }
        });
      } else {
        _.forEach(value, (val) => {
          background.push('#5882FA');
        });
      }
      vm.datasetOverride = [
        {
          yAxisID: 'y-axis-1',
          label: vm.sensor.label,
          borderWidth: 0,
          backgroundColor: background,
          hoverBackgroundColor: background,
        },
      ];
      vm.options = {
        scales: {
          yAxes: [
            {
              id: 'y-axis-1',
              display: true,
              position: 'left',
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      };
      //vm.colors = ['#5882FA', '#5882FA'];
      vm.history = true;
    }
    function opacity(color, opacity) {
      var alpha = opacity === undefined ? 0.5 : 1 - opacity;
      return Color(color).alpha(alpha).rgbString();
    }
    function prepareLineChart(data) {
      let max = _.map(data, (o) => {
        return o.max;
      });
      let min = _.map(data, (o) => {
        return o.min;
      });
      let avg = _.map(data, (o) => {
        return o.avg;
      });
      /* data_labels = _.map(data,o => {
            return moment(o.to,"DD-MM-YYYY HH:mm").format("DD/MM HH:mm");
        }); */
      let labels = _.map(data, (o) => {
        return moment(o.to, 'DD-MM-YYYY HH:mm');
      });

      let ctx = document.getElementById('base2').getContext('2d');

      if (vm.lineChart) {
        vm.lineChart.destroy();
      }

      var chartData = {
        datasets: [
          {
            label: 'max',
            backgroundColor: '#154360',
            borderColor: '#36A2EB',
            data: max,
            fill: false,
          },
          {
            label: 'min',
            backgroundColor: opacity('#36A2EB', 0.7),
            borderColor: '#66ff99',
            data: min,
            fill: '-1',
          },
          {
            label: 'avg',
            backgroundColor: '#EC7063',
            borderColor: '#FF6384',
            data: avg,
            fill: false,
          },
        ],
        labels: labels,
      };
      vm.lineChart = new Chart(ctx, {
        type: 'line',
        data: chartData,
        options: {
          scales: {
            xAxes: [
              {
                type: 'time',
                time: {
                  isoWeekday: true,
                  unit: 'day',
                  tooltipFormat: 'DD/MM HH:mm',
                  displayFormats: {
                    day: 'DD/MM',
                    hour: 'DD/MM HH:mm',
                  },
                },
              },
            ],
          } /* 
                     tooltips: {
                        callbacks: {
                            title: function(tooltipItem, data) {
                                var label = data_labels[tooltipItem[0].index];
                                return label;
                            }
                        },
                        mode: 'index',
                        intersect: false,
                    },  */,
          elements: {
            line: {
              tension: 0,
            },
            point: {
              pointStyle: 'circle',
              radius: 0,
              hitRadius: 5,
            },
          },
          hover: {
            mode: 'nearest',
            intersect: true,
          },
        },
      });
      vm.history = true;
    }

    function returnType(type) {
      switch (type) {
        case 2:
          return 'Agrónic 4000';
          break;
        case 3:
          return 'Agrónic 2500';
          break;
        case 4:
          return 'Agrónic BIT';
          break;
        case 5:
          return 'Agrónic 7000';
          break;
        case 6:
          return 'Agrónic 5500';
          break;
      }
    }

    function unitHour() {
      if (vm.currentUnit && vm.currentUnit.date) {
        return moment(vm.currentUnit.date, 'DD-MM-YYYY HH:mm').format('DD/MM/YYYY | HH:mm');
      } else {
        return '--:--';
      }
    }

    function deleteSensorMarker() {
      $rootScope.$broadcast('deleteSensor');
      closeSensorInfo();
    }

    function loadSelectionSensors() {
      switch (vm.sensorType) {
        case 0: //Analog
          sensorsFactory.analogsactive(vm.unit.id, 'all').then((resp) => {
            vm.sensors = resp.plain();
          });
          break;
        case 1: //Meter
          if (vm.unit.type === 2) {
            //A4000
            sensorsFactory.digitalsPage(vm.unit.id, { limit: 50, page: 1 }).then(function (data) {
              vm.sensors = extractCounters(data.content);
            });
          } else {
            sensorsFactory.meters(vm.unit.id).then((resp) => {
              vm.sensors = resp.plain();
            });
          }
          break;
        case 2: //Digital
          if (vm.unit.type === 2) {
            //A4000
            sensorsFactory.digitalsPage(vm.unit.id, { limit: 50, page: 1 }).then(function (data) {
              var sensors = data.content;
              var counters = extractCounters(sensors);
              vm.sensors = _.xor(sensors, counters);
            });
          } else {
            sensorsFactory.digitalsPage(vm.unit.id, { limit: 50, page: 1 }).then(function (data) {
              vm.sensors = data.content;
            });
          }
          break;
      }
    }

    function extractCounters(sensors) {
      return _.filter(sensors, (o) => {
        return Number(o.pk.id) >= 11 && Number(o.pk.id) <= 35;
      });
    }

    /* FUNCIONALITATS GRAFIQUES (DPV,ETO,WATERDISP) */
    function sensorHistory() {
      vm.sensor.history = true;
      datos_grafica = [];
      vm.type = vm.type.toString();

      let params = {
        to: moment(vm.dateTo).format('DD-MM-YYYY'),
        from: moment(vm.dateFrom).format('DD-MM-YYYY'),
        group: '2',
      };
      switch (vm.type) {
        case '3':
          params.group = '1';
          graphicsFactory
            .getDPV(vm.sensor.pk.userId, vm.sensor.pk.id, params)
            .then((result) => {
              var mdata = [];
              mdata = result;
              mdata.forEach(function (valor, array) {
                var date_timestamp = moment(valor.date, 'DD-MM-YYYY HH:mm').format('x');
                date_timestamp = parseInt(date_timestamp);
                datos_grafica.push([date_timestamp, valor.value]);
              });
              highchart = loadGraficas(datos_grafica, 'DPV', 'line');
            })
            .catch(function (e) {});
          break;

        case '4':
          graphicsFactory
            .getETO(vm.sensor.pk.userId, vm.sensor.pk.id, params)
            .then((result) => {
              var mdata = [];
              mdata = result;
              mdata.forEach(function (valor, array) {
                var date_timestamp = moment(valor.date, 'DD-MM-YYYY').format('x');
                date_timestamp = parseInt(date_timestamp);
                datos_grafica.push([date_timestamp, valor.value]);
              });

              highchart = loadGraficas(datos_grafica, 'ETo', 'column');
            })
            .catch(function (e) {});
          break;

        case '5':
          params.group = '1';
          graphicsFactory
            .getWaterDisp(vm.sensor.pk.userId, vm.sensor.pk.id, 0, params)
            .then((result) => {
              var mdata = [];
              mdata = result;
              mdata.forEach(function (valor, array) {
                var date_timestamp = moment(valor.date, 'DD-MM-YYYY HH:mm').format('x');
                date_timestamp = parseInt(date_timestamp);
                datos_grafica.push([date_timestamp, valor.value]);
              });
              highchart = loadGraficas(datos_grafica, 'Water Disp.', 'line');
            })
            .catch(function (e) {});
          break;
      }
    }

    function loadGraficas(datos, name, gtype) {
      let id = 'sensorCalc';

      vm.hasChartData = !!datos.find((el) => el[1] !== null);
      let highchart = new Highcharts.Chart({
        time: {
          useUTC: false,
        },
        title: {
          text: vm.sensor.name,
        },
        chart: {
          zoomType: 'xy',
          renderTo: id,
          type: gtype,
        },
        exporting: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },

        xAxis: {
          type: 'datetime',
          labels: {
            format: '{value:%d-%m-%Y}',
            rotation: -65,
          },
        },
        yAxis: {
          title: '',
        },

        series: [
          {
            name: name,
            data: datos,
          },
        ],
      });
      return highchart;
    }

    $scope.$on('actionViewChange', (ev, args) => {
      vm.actionView = args;
    });

    $scope.$on('editPaginatorChange', (ev, { sensor, currentUnit, type }) => {
      vm.sensor = $state.params.fetchedSensors.find((fs) => fs.pk.id == sensor.prop3) || {};
      vm.sensor.type = type;
      vm.currentUnit = currentUnit;
      vm.type = type;

      $rootScope.$emit('loadSensorData', { sensor, currentUnit });
      loadHistoryData();
    });
  }
})();
