import { getContainer } from '@vegga/front-store';
import { UNITS } from '../../utils/units.enum';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('pcCommController', pcCommController);

  pcCommController.$inject = ['$scope', '$state', 'commFactory', 'unitFactory'];

  function pcCommController($scope, $state, commFactory, unitFactory) {
    var vm = this;
    var backup;
    vm.activeList;
    vm.save;
    vm.cancel;
    vm.open;
    vm.destroy$ = new Subject();
    vm.devicesFacade = getContainer().resolve('devicesFacade');

    activate();

    function activate() {
      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((currentUnit) => {
        if (!currentUnit) {
          return;
        }
        loadData(currentUnit);
      });
    }

    function loadData(currentUnit) {
      vm.currentState = $state.includes;
      vm.UNITS = UNITS;
      vm.save = save;
      vm.cancel = cancel_edition;
      vm.open = open;
      vm.currentUnit = currentUnit;

      vm.selected = 0;

      initCombos();

      loadComm();
    }

    function initCombos() {
      switch (vm.currentUnit.type) {
        case UNITS.A_2500:
        case UNITS.A_BIT:
          vm.types = {
            0: 'Ninguno',
            1: 'Cable',
            2: 'Radioenlace',
            3: 'Módem GSM',
            4: 'GPRS Socket',
            5: 'Radioenlace RDM',
            6: 'WiFi Socket',
          };
          break;
        case UNITS.A_4000:
          vm.types = {
            0: 'Ninguno',
            1: 'Cable',
            2: 'Módem GSM',
            3: 'GPRS Socket',
            4: 'Ethernet',
          };
          break;
        case UNITS.A_4500:
          vm.types = {
            0: 'Ninguno',
            1: 'Cable',
            2: 'Módem/WiFi',
          };
          break;
        case UNITS.A_5500:
          vm.types = {
            0: 'Ninguno',
            1: 'Cable',
            2: 'Radioenlace',
            3: 'GPRS Socket',
            4: 'Radioenlace RDM',
            5: 'WiFi Socket',
          };
          break;
        case UNITS.A_7000:
          vm.types = {
            0: 'Ninguno',
            1: 'Cable',
            2: 'Módem GSM',
            3: 'GPRS Socket',
          };
          break;
      }

      vm.accessType = {
        0: 'Total',
        1: 'Programación',
        2: 'Consulta',
      };
      vm.ports = [0, 1, 2, 3, 4, 5];
    }

    function loadComm() {
      commFactory.pcComm(vm.currentUnit.id).then((users) => {
        vm.users = users;
        backup = angular.copy(users);
      });
    }

    function open(index) {
      if (vm.selected === index) {
        vm.selected = null;
      } else {
        vm.selected = index;
      }
    }

    function save() {
      _.forEach(vm.users, (user) => {
        user.unittype = vm.currentUnit.type;
      });
      commFactory.updatePcComm(vm.currentUnit.id, vm.users).then((result) => {
        vm.users = null;
        vm.users = _.orderBy(result, 'pk.id');
        vm.form.$setPristine();
      });
    }

    function cancel_edition() {
      vm.form.$setPristine();
      vm.users = angular.copy(backup);
    }

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e, args) => {
      save();
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
