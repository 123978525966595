import { UNITS, UNITS_V2 } from '../utils/units.enum';
import { SLOGICS_OPS } from '../utils/enums/sensors/slogics-operations-type-enum';
import { SLOGICS_SIMPLE_OPS_TYPE } from '../utils/enums/sensors/slogics-simple-ops-type-enum';
import { SLOGICS_AND_OR_OPS_TYPE } from '../utils/enums/sensors/slogics-and-or-ops-enum';
import {
  IOSelectorItemResponseElementTypeEnum,
  IOSelectorItemResponseOutputTypeEnum,
} from '../utils/enums/io-selector.enum';
import { from, of, Subject } from 'rxjs';
import { getContainer } from '@vegga/front-store';
import { switchMap } from 'rxjs/operators';

import { showLoader, hideLoader } from '@vegga/front-utils';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('sensorsConfigLogicController', sensorsConfigLogicController);

  sensorsConfigLogicController.$inject = [
    '$log',
    '$rootScope',
    '$scope',
    '$state',
    'Restangular',
    '$mdDialog',
    'UserData',
    'sensorsFactory',
    'unitFactory',
    'sectorFactory',
    '$confirm',
    '$filter',
  ];

  function sensorsConfigLogicController(
    $log,
    $rootScope,
    $scope,
    $state,
    Restangular,
    $mdDialog,
    UserData,
    sensorsFactory,
    unitFactory,
    sectorFactory,
    $confirm,
    $filter,
  ) {
    var vm = this;
    vm.activeList;
    vm.lastSensor;
    vm.nextSensor;
    vm.selectSensor;
    vm.cancel;
    vm.save;
    activate();

    function activate() {
      vm.overlayEl = document.querySelector('#sensor-config-overlay');
      vm.overlayEl.show();
      vm.UNITS = UNITS;
      vm.SLOGICS_OPS = SLOGICS_OPS;
      vm.SLOGICS_SIMPLE_OPS_TYPE = SLOGICS_SIMPLE_OPS_TYPE;
      vm.SLOGICS_AND_OR_OPS_TYPE = SLOGICS_AND_OR_OPS_TYPE;
      vm.IOSelectorItemResponseOutputTypeEnum = IOSelectorItemResponseOutputTypeEnum;
      vm.IOSelectorItemResponseElementTypeEnum = IOSelectorItemResponseElementTypeEnum;
      vm.destroy$ = new Subject();
      vm.ioFacade = getContainer().resolve('ioFacade');
      vm.currentState = $state.includes;
      if ($state.params.unit !== null) {
        vm.currentUnit = $state.params.unit;
        vm.currentHeader = $state.params.headerId || 0;
        vm.forms = [];
        vm.page = 1;
        loadLogics();
        vm.selectSensor = loadLogics;
        vm.nextSensor = nextSensor;
        vm.lastSensor = lastSensor;
        vm.cancel = cancel_edition;
        vm.save = save;
        vm.setColor = setColor;
        vm.captionRange = _.range(0, 5);
        vm.changeState = changeState;
        vm.changeSensor = changeSensor;
        vm.changingSensor = changingSensor;
        vm.nextOrPreviousItem = nextOrPreviousItem;
        vm.resetElement = resetElement;
        vm.changeElementType = changeElementType;

        vm.sensorOperations = [
          $filter('translate')('sensors.operations.inactive'),
          $filter('translate')('sensors.operations.sum'),
          $filter('translate')('sensors.operations.difference'),
          $filter('translate')('sensors.operations.average'),
          $filter('translate')('sensors.operations.and'),
          $filter('translate')('sensors.operations.or'),
        ];
        vm.sensorTypes = [
          $filter('translate')('sensors.analog'),
          $filter('translate')('sensors.sMeterFlow'),
          $filter('translate')('sensors.slogicValue'),
          $filter('translate')('sensors.analogicOutput'),
        ];
        vm.logicElementsIndex = [
          { id: 1, nameType: 'vm.sensor.type1', nameNum: 'vm.sensor.num1', condition: 'vm.sensor.condition1' },
          { id: 2, nameType: 'vm.sensor.type2', nameNum: 'vm.sensor.num2', condition: 'vm.sensor.condition2' },
          { id: 3, nameType: 'vm.sensor.type3', nameNum: 'vm.sensor.num3', condition: 'vm.sensor.condition3' },
          { id: 4, nameType: 'vm.sensor.type4', nameNum: 'vm.sensor.num4', condition: 'vm.sensor.condition4' },
          { id: 5, nameType: 'vm.sensor.type5', nameNum: 'vm.sensor.num5', condition: 'vm.sensor.condition5' },
        ];
        vm.sensorTypesAndOr = [
          $filter('translate')('sensors.sdigital'),
          $filter('translate')('sensors.conditioner'),
          $filter('translate')('sensors.digitalOutput'),
          $filter('translate')('sensors.slogicDigital'),
          $filter('translate')('sensors.sector'),
          $filter('translate')('sensors.analog'),
          $filter('translate')('sensors.sMeterFlow'),
          $filter('translate')('sensors.slogicValue'),
          $filter('translate')('sensors.analogicOutput'),
        ];
        vm.condition = [$filter('translate')('sensors.state_true'), $filter('translate')('sensors.state_false')];

        if (vm.sensor !== null && vm.sensor !== undefined) backup();
        loadMeasureTypeData();
      } else {
        $state.go('units');
      }
    }

    function loadMeasureTypeData() {
      vm.isDataLoaded = false;

      // Simple operations data
      vm.simpleOperationsData = [[], [], [], []];
      // And and or Data
      vm.AndOrData = [[], [], [], [], [], [], []];
      Promise.all([
        sensorsFactory.analogsAll(vm.currentUnit.id).then((data) => {
          vm.simpleOperationsData[SLOGICS_SIMPLE_OPS_TYPE.ANALOG_SENSOR] = data.filter((analog) => analog.input > 0);
          vm.AndOrData[SLOGICS_AND_OR_OPS_TYPE.ANALOG_SENSOR] = data.filter((analog) => analog.input > 0);
          vm.analogs = data.filter((analog) => analog.input > 0);
        }),
        sensorsFactory.meters(vm.currentUnit.id).then((data) => {
          vm.simpleOperationsData[SLOGICS_SIMPLE_OPS_TYPE.FLOW_METER_SENSOR] = data.filter((meter) => meter.input > 0);
          vm.AndOrData[SLOGICS_AND_OR_OPS_TYPE.FLOW_METER_SENSOR] = data.filter((meter) => meter.input > 0);
          vm.meters = data.filter((meter) => meter.input > 0);
        }),
        sensorsFactory.digitalsAll(vm.currentUnit.id).then((data) => {
          vm.AndOrData[SLOGICS_AND_OR_OPS_TYPE.DIGITAL_SENSOR] = data.filter((digital) => digital.input > 0);
        }),
        unitFactory.conditioners(vm.currentUnit.id).then((data) => {
          vm.AndOrData[SLOGICS_AND_OR_OPS_TYPE.CONDITIONER] = data.filter((cond) => cond.function > 0);
        }),
        sectorFactory.allSectors(vm.currentUnit.id).then((data) => {
          vm.AndOrData[SLOGICS_AND_OR_OPS_TYPE.SECTOR] = data.filter((sector) => sector.output > 0);
        }),
      ]).then(() => {
        vm.AndOrData[SLOGICS_AND_OR_OPS_TYPE.VALUE_LOGIC_SENSOR] = vm.logicsValue;
        // salida digital
        vm.simpleOperationsData[SLOGICS_SIMPLE_OPS_TYPE.VALUE_LOGIC_SENSOR] = vm.logicsValue;

        vm.simpleOperationsData[SLOGICS_SIMPLE_OPS_TYPE.ANALOG_OUTPUT] = [];

        // salida digital
        vm.AndOrData[SLOGICS_AND_OR_OPS_TYPE.DIGITAL_OUTPUT] = [];
        //salida analogica
        vm.AndOrData[SLOGICS_AND_OR_OPS_TYPE.ANALOG_OUTPUT] = [];

        vm.isDataLoaded = true;
      });
    }

    //preparar totes les queries i ficar el html corresppnent.
    function loadLogics() {
      /*         const params = new HttpParams()
              .set("active", false); */
      const params = { active: 'false' };
      showLoader();
      vm.logicsDataLoaded = false;
      return sensorsFactory
        .logicSensorsPage(vm.currentUnit.id, params)
        .then((data) => {
          vm.logics = data;
          /*             data[vm.page-1].elements.forEach(elem => {
                        return setAndLoadAndOrData(elem.elementType);
                    }); */
          vm.logicsValue = data.filter(
            (logic) => logic.operation != SLOGICS_OPS.AND && logic.operation != SLOGICS_OPS.OR,
          );
          vm.logicsDigital = data.filter(
            (logic) => logic.operation == SLOGICS_OPS.AND || logic.operation == SLOGICS_OPS.OR,
          );
          changeState(vm.page);

          vm.ioFacade.loadConfiguredOutputs({
            deviceId: vm.currentUnit.id,
            deviceType: UNITS_V2[vm.currentUnit.type],
          });

          // Init StagingList in order to check the current io selector.
          vm.ioFacade.initializationStagingOutputs(vm.currentUnit.id, UNITS_V2[vm.currentUnit.type]);
          hideLoader();
          vm.logicsDataLoaded = true;
        })
        .catch((e) => {
          vm.logicsDataLoaded = true;
          throw e;
        });
    }

    function resetElement(index, checked, isOperationAndOr) {
      const element = vm.logics[vm.page - 1].elements[index];
      vm.logics[vm.page - 1].elements[index] = {
        ...element,
        elementType: checked ? (isOperationAndOr ? 1 : 0) : null,
        elementId: 0,
        condition: 0,
        reference: 0,
        differential: 0,
      };
    }

    function changeElementType($event, index) {
      const element = vm.logics[vm.page - 1].elements[index];
      vm.logics[vm.page - 1].elements[index] = {
        ...element,
        elementType: $event.detail.value,
        elementId: null,
      };
    }

    function changeState(params) {
      vm.active = null;
      vm.sensor = vm.logics[params - 1];
    }

    function changingSensor($event) {
      if (vm.form45 && vm.form45.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({ text: $filter('translate')('sensors.cancelq') }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.form45.$setPristine(); //Actualitzem estat del formulari a inicial
          this.nextOrPreviousItem($event.detail.action, $event);
        });
      }
    }

    function nextOrPreviousItem(action, $event) {
      const paginator = document.querySelector('vegga-item-paginator');
      switch (action) {
        case 'next-item':
          paginator.nextItem();
          break;
        case 'previous-item':
          paginator.previousItem();
          break;
        default:
          changeSensor($event);
          break;
      }
    }

    function setColor() {
      vm.pickerActive = false;
      switch (vm.sensor.caption.numLvl) {
        case 3:
          vm.sensor.caption.color1 = sensorsFactory.lightenDarkenColor(vm.sensor.color.hex, 60);
          vm.sensor.caption.color2 = vm.sensor.color.hex;
          vm.sensor.caption.color3 = sensorsFactory.lightenDarkenColor(vm.sensor.color.hex, -60);
          break;
        case 5:
          vm.sensor.caption.color1 = sensorsFactory.lightenDarkenColor(vm.sensor.color.hex, 60);
          vm.sensor.caption.color2 = sensorsFactory.lightenDarkenColor(vm.sensor.color.hex, 30);
          vm.sensor.caption.color3 = vm.sensor.color.hex;
          vm.sensor.caption.color4 = sensorsFactory.lightenDarkenColor(vm.sensor.color.hex, -30);
          vm.sensor.caption.color5 = sensorsFactory.lightenDarkenColor(vm.sensor.color.hex, -60);
          break;
      }
    }

    function checkFunctionType(key) {
      var id = Number(key);
      if (vm.currentUnit.type !== UNITS.A_7000) {
        switch (true) {
          case id >= 1 && id <= 5:
          case id === 10:
          case id >= 44 && id <= 48: {
            return 0;
            break;
          }
          case id >= 6 && id <= 9: {
            vm.typeStop = [
              { id: 1, text: 'temporal' },
              { id: 2, text: 'conditional' },
              { id: 3, text: 'definitive' },
            ];
            return 1;
            break;
          }
          case id >= 11 && id <= 14: {
            return 2;
            break;
          }
          case id >= 15 && id <= 22: {
            return 3;
            break;
          }
          case id >= 23 && id <= 26: {
            return 4;
            break;
          }
          case id >= 27 && id <= 34: {
            return 5;
            break;
          }
          case id === 35: {
            return 6;
            break;
          }
          case id >= 36 && id <= 43: {
            return 7;
            break;
          }
        }
      } else {
        switch (true) {
          case id >= 1 && id <= 6: {
            return 0;
            break;
          }
          case id >= 7 && id <= 14: {
            vm.typeStop = [
              { id: 1, text: 'temporal' },
              { id: 2, text: 'conditional' },
              { id: 3, text: 'definitive' },
            ];
            return 1;
            break;
          }
          case id >= 15 && id <= 17:
          case id >= 20 && id <= 22:
          case id == 38: {
            return 2;
            break;
          }
          case id == 18: {
            return 3;
            break;
          }
          case id == 19: {
            return 4;
            break;
          }
          case id >= 23 && id <= 28: {
            vm.typeStop = [
              { id: 1, text: 'temporal' },
              { id: 2, text: 'conditional' },
              { id: 3, text: 'definitive' },
            ];
            return 5;
            break;
          }
          case id >= 29 && id <= 37: {
            return 6;
            break;
          }
          case id >= 39 && id <= 47: {
            return 7;
            break;
          }
        }
      }
    }

    function lastSensor() {
      --vm.page;
      if (vm.page === 0) {
        vm.page = vm.paginator.totalPages;
      }
      loadLogics();
    }

    function nextSensor() {
      ++vm.page;
      if (vm.page > vm.paginator.totalPages) {
        vm.page = 1;
      }
      loadLogics();
    }

    function changeSensor(e) {
      if (e.detail.value) {
        vm.page = +e.detail.value;
        loadLogics();
      }
    }

    function save() {
      vm.loading = true;
      vm.sensor.unittype = vm.currentUnit.type;
      vm.form45.$setPristine();

      vm.ioFacade
        .validateOutputsToTheCurrentView()
        .pipe(
          switchMap((result) => {
            if (result || (vm.sensor.operation !== vm.SLOGICS_OPS.AND && vm.sensor.operation !== vm.SLOGICS_OPS.OR)) {
              return from(sensorsFactory.updateLogics(vm.currentUnit.id, vm.sensor));
            } else {
              return of(null);
            }
          }),
        )
        .subscribe((response) => {
          if (response) {
            vm.sensor = response;
          }
        });
    }

    function backup() {
      vm.sensors_backup = {};
      angular.copy(vm.sensor, vm.sensors_backup);
      vm.sensor.backup = true;
    }

    function cancel_edition() {
      //backup
      angular.copy(vm.sensors_backup, vm.sensor);
    }

    $scope.$on('refresh', function (event, args) {});

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form45,
      () => {
        $scope.$emit('formUpdated', vm.form45);
      },
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e, args) => {
      save(e, vm.form45);
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
