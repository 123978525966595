import { getContainer } from '@vegga/front-store';
import { Subject, take, takeUntil } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('programsConfigController', programsConfigController);

  programsConfigController.$inject = [
    '$log',
    '$scope',
    '$state',
    'Restangular',
    '$mdDialog',
    'progFactory',
    'UserData',
    'resFactory',
    '$rootScope',
    'sensorsFactory',
  ];

  function programsConfigController(
    $log,
    $scope,
    $state,
    Restangular,
    $mdDialog,
    progFactory,
    UserData,
    resFactory,
    $rootScope,
    sensorsFactory,
  ) {
    var vm = this;
    vm.activeList;
    vm.formats = [];
    vm.conditioners;
    vm.currentUnit = $scope.$parent.vm.currentUnit;
    vm.destroy$ = new Subject();
    vm.programsFacade = getContainer().resolve('programsFacade');
    initSubscriptions();

    function initSubscriptions() {
      const programId = $state.params.program.pk.id;

      vm.programsFacade.getA4000Program(vm.currentUnit.id, programId);
      vm.programsFacade.programById$.pipe(takeUntil(vm.destroy$), take(1)).subscribe((res) => {
        vm.program = res;
        activate();
      });
    }
    function activate() {
      vm.currentState = $state.includes;

      vm.groupList = [
        { name: '0', type: 0 },
        { name: '1', type: 1 },
        { name: '2', type: 2 },
        { name: '3', type: 3 },
        { name: '4', type: 4 },
        { name: '5', type: 5 },
        { name: '6', type: 6 },
        { name: '7', type: 7 },
        { name: '8', type: 8 },
        { name: '9', type: 9 },
      ];

      if ($state.params.program !== null) {
        vm.updateFormat = updateFormat;

        vm.conditionerList = resFactory
          .cond(vm.currentUnit.type)
          .map((cond) => ({ ...cond, name: `${cond.type}: ${cond.name}` }));
        vm.cancel = cancel_edition;
        vm.save = save;

        loadSensors();

        backup();
      } else {
        $state.go('units');
      }
    }

    function loadSensors() {
      var params = { page: 1, limit: 40 };
      vm.sensor = {};
      vm.sensors = [];
      vm.loading = true;
      sensorsFactory.analogsPage(vm.currentUnit.id, params).then(function (data) {
        var paginator = data.plain();
        angular.copy(paginator.content[0], vm.sensor);
        var formatId = vm.sensor.formatId;
        vm.sensors = paginator.content;
        vm.loading = false;
      });
    }

    function updateFormat(id, index, model1, model2) {
      model1 = 0;
      model2 = 0;
      if (id !== null && id > 0) {
        id = parseInt(id);

        if (id === 41) {
          vm.program.conditioners[index].value2 = 0;
          vm.formats[index] = {
            units: 'L/m2',
          };
        } else {
          var formatId = vm.sensors[id - 1].formatId;

          if (formatId === 0) {
            vm.formats[index] = {};
            vm.formats[index].integers = 0;
            vm.formats[index].decimals = 0;
            vm.formats[index].units = '';
          } else {
            vm.formats[index] = vm.currentUnit.formats[formatId - 1];

            if (
              vm.program.conditioners[index].type === 3 ||
              vm.program.conditioners[index].type === 7 ||
              vm.program.conditioners[index].type === 8 ||
              vm.program.conditioners[index].type === 9
            ) {
              vm.formats[index + 1] = {};
              if (vm.sensors[id - 1].formatId === 1) {
                vm.formats[index + 1].integers = 4;
                vm.formats[index + 1].decimals = 0;
                vm.formats[index + 1].units = 'ºCh';
              } else if (vm.currentUnit.sanalog[id - 1].formatId === 2) {
                vm.formats[index + 1].integers = 5;
                vm.formats[index + 1].decimals = 0;
                vm.formats[index + 1].units = 'Whm2';
              }
            }
          }
        }
      } else {
        vm.formats[index] = null;
      }
    }

    function backup() {
      vm.program_backup = {};
      angular.copy(vm.program, vm.program_backup);
      vm.program.backup = true;
    }

    function cancel_edition() {
      vm.form.$setPristine();
      angular.copy(vm.program_backup, vm.program);
    }

    function save() {
      vm.form.$setPristine();
      vm.program.progtype = vm.currentUnit.type;
      progFactory.update(vm.program).then((response) => {
        $rootScope.$broadcast('updateEdit', { message: 'refreshing' });
      });
    }

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      },
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e, args) => {
      save();
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
