import { UNITS } from '../utils/units.enum';

import moment from 'moment/moment';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('sensorsDetailController', sensorsDetailController);

  sensorsDetailController.$inject = [
    '$scope',
    '$state',
    'progFactory',
    'sensorsFactory',
    '$q',
    '$anchorScroll',
    'sectorFactory',
    'resFactory',
  ];

  function sensorsDetailController(
    $scope,
    $state,
    progFactory,
    sensorsFactory,
    $q,
    $anchorScroll,
    sectorFactory,
    resFactory,
  ) {
    var vm = this;
    vm.activeAll;
    vm.activeDigitals;
    vm.activeAnalogs;
    vm.activeCounters;
    vm.currentUnit;
    vm.digitals;
    vm.UNITS = UNITS;

    activate();

    function activate() {
      if ($state.params.unit !== null) {
        vm.currentUnit = $state.params.unit;

        $q.all([loadDigitals().then(), loadAnalogs().then()]).then((o) => {
          if (vm.currentUnit.type === 2) {
            checkConditioners();
          }
        });
        loadConditionersLiterals();
        vm.activeAll = true;
        vm.showSensors = showSensors;
        vm.openSensor = openSensor;
        vm.closeSensor = closeSensor;
        vm.changeToProgram = changeToProgram;
        vm.checked = false;
        var formats = vm.currentUnit.formats;
      } else {
        $state.go('units');
      }
    }

    function openSensor(sensor, type) {
      if (!vm.checked) {
        vm.selected = sensor;
        vm.selected.type = type;
        vm.checked = !vm.checked;
        if (vm.checked) $anchorScroll();

        switch (type) {
          case 'analog':
            if ((sensor.record && vm.currentUnit.type === 2) || vm.currentUnit.type !== 2) {
              //|| vm.currentUnit.type === 3
              sensorsFactory
                .registerAnalogs(vm.currentUnit.id, sensor.pk.id, vm.currentUnit.type)
                .then(function (data) {
                  if (vm.currentUnit.type !== 5) populateHistoryBar(data.plain());
                  else populateHistoryBar(data.plain(), sensor.format);
                });
            }
            break;
          case 'counter':
            if (sensor.value3 === 1) {
              if (vm.selected.pk.id === '35') {
                vm.selected.pluviometer = true;
                vm.selected.label = 'l/m2';
              } else {
                vm.selected.label = 'm3/h';
                prepareMeterData();
              }
              sensorsFactory
                .registerDigitals(vm.currentUnit.id, sensor.pk.id, vm.currentUnit.type)
                .then(function (data) {
                  populateHistoryBar(data.plain());
                });
            } else if (vm.currentUnit.type === 3 || vm.currentUnit.type === 4) {
              sensorsFactory
                .registerDigitals(vm.currentUnit.id, sensor.pk.id, vm.currentUnit.type)
                .then(function (data) {
                  populateHistoryBar(data.plain());
                });
            }
            break;
        }
      }
    }

    function closeSensor() {
      vm.checked = false;
      vm.selected = {};
      vm.data = [];
    }

    function loadDigitals() {
      vm.digitals = [];
      return sensorsFactory.digitalsactive(vm.currentUnit.id).then(function (data) {
        var sensors = data;
        if (vm.currentUnit.type === vm.UNITS.A_4000 || vm.currentUnit.type === vm.UNITS.A_7000) {
          var counters = extractCounters(sensors);
          sensors = _.xor(sensors, counters);
          vm.digitals = sensors;
          vm.counters = counters;
          prepareCounters(vm.counters);
        } else {
          vm.digitals = sensors;
          loadCounters();
        }

        return 1;
      });
    }

    function loadCounters() {
      return sensorsFactory.meters(vm.currentUnit.id).then((data) => {
        vm.counters = data.plain().filter((o) => o.input !== 0);

        if (vm.currentUnit.progtype === vm.UNITS.A_4000.toString()) {
          prepareCounters(vm.counters);
        } else {
          prepareCountersA2(vm.counters);
        }
      });
    }

    function loadAnalogs() {
      vm.analogs = [];
      return sensorsFactory.analogsactive(vm.currentUnit.id).then(function (data) {
        var sensors = data;
        checkFormats(sensors);
        vm.analogs = sensors;
        return 1;
      });
    }

    function extractCounters(sensors) {
      if (vm.currentUnit.type === vm.UNITS.A_4000) {
        return sensors.filter((o) => Number(o.pk.id) >= 11 && Number(o.pk.id) <= 35);
      } else {
        return sensors.filter(
          (o) => (Number(o.pk.id) >= 1 && Number(o.pk.id) <= 14) || (Number(o.pk.id) >= 39 && Number(o.pk.id) <= 47),
        );
      }
    }

    function prepareCountersA2(counters) {
      var formats = {
        0: 'm3/h',
        1: 'L/h',
        2: 'L/s',
      };
      _.forEach(counters, (c) => {
        if (
          ((vm.currentUnit.type === vm.UNITS.A_2500 || vm.currentUnit.type === vm.UNITS.A_BIT) && c.meterType !== 1) ||
          (vm.currentUnit.type === vm.UNITS.A_5500 && c.type !== 3)
        ) {
          //Tipus digitial NO pluviometre

          var decimals = 2;
          if (c.xFlow === null) c.xFlow = 0;
          c.xFlow = c.xFlow.toString();
          var first = c.xFlow.slice(0, c.xFlow.length - decimals);
          var last = c.xFlow.slice(c.xFlow.length - decimals, c.xFlow.length);
          c.xFlow = first + '.' + last;
          c.value = Number(c.xFlow) + formats[c.flowFormat];
          switch (c.usedBy) {
            case 0:
              if (Number(c.xFlow) !== 0) {
                c.active = true;
                c.error = Number(c.assigned) !== 0;
              }
              break;
            case 1:
            case 2:
              c.active = true;
              break;
          }
        }
      });
    }

    function prepareCounters(counters) {
      var meters = [];
      var fertmeters = [];
      sensorsFactory.meters(vm.currentUnit.id).then((data1) => {
        sensorsFactory.fertmeters(vm.currentUnit.id).then((data2) => {
          meters = data1.plain();
          fertmeters = data2.plain();
          if (meters.length > 0 || fertmeters.length > 0) {
            _.forEach(counters, (c) => {
              if (vm.currentUnit.type === vm.UNITS.A_4000) {
                switch (c.pk.id) {
                  case '11':
                  case '23':
                    c.valueNumber = parseUnitFlow(meters[0]);
                    c.value = c.valueNumber + ' m3/h';
                    c.active = meters[0].xActive || c.valueNumber > 0;
                    c.meter = 1;
                    break;
                  case '12':
                  case '24':
                    c.valueNumber = parseUnitFlow(meters[1]);
                    c.value = c.valueNumber + ' m3/h';
                    c.active = meters[1].xActive || c.valueNumber > 0;
                    c.meter = 2;
                    break;
                  case '13':
                  case '25':
                    c.valueNumber = parseUnitFlow(meters[2]);
                    c.value = c.valueNumber + ' m3/h';
                    c.active = meters[2].xActive || c.valueNumber > 0;
                    c.meter = 3;
                    break;
                  case '14':
                  case '26':
                    c.valueNumber = parseUnitFlow(meters[3]);
                    c.value = c.valueNumber + ' m3/h';
                    c.active = meters[3].xActive || c.valueNumber > 0;
                    c.meter = 4;
                    break;
                  case '15':
                  case '27':
                    c.valueNumber = parseUnitFert(fertmeters[0]);
                    c.value = c.valueNumber + ' l/h';
                    c.active = fertmeters[0].xActive || c.valueNumber > 0;
                    break;
                  case '16':
                  case '28':
                    c.valueNumber = parseUnitFert(fertmeters[1]);
                    c.value = c.valueNumber + ' l/h';
                    c.active = fertmeters[1].xActive || c.valueNumber > 0;
                    break;
                  case '17':
                  case '29':
                    c.valueNumber = parseUnitFert(fertmeters[2]);
                    c.value = c.valueNumber + ' l/h';
                    c.active = fertmeters[2].xActive || c.valueNumber > 0;
                    break;
                  case '18':
                  case '30':
                    c.valueNumber = parseUnitFert(fertmeters[3]);
                    c.value = c.valueNumber + ' l/h';
                    c.active = fertmeters[3].xActive || c.valueNumber > 0;
                    break;
                  case '19':
                  case '31':
                    c.valueNumber = parseUnitFert(fertmeters[4]);
                    c.value = c.valueNumber + ' l/h';
                    c.active = fertmeters[4].xActive || c.valueNumber > 0;
                    break;
                  case '20':
                  case '32':
                    c.valueNumber = parseUnitFert(fertmeters[5]);
                    c.value = c.valueNumber + ' l/h';
                    c.active = fertmeters[5].xActive || c.valueNumber > 0;
                    break;
                  case '21':
                  case '33':
                    c.valueNumber = parseUnitFert(fertmeters[6]);
                    c.value = c.valueNumber + ' l/h';
                    c.active = fertmeters[6].xActive || c.valueNumber > 0;
                    break;
                  case '22':
                  case '34':
                    c.valueNumber = parseUnitFert(fertmeters[7]);
                    c.value = c.valueNumber + ' l/h';
                    c.active = fertmeters[7].xActive || c.valueNumber > 0;
                    break;
                  case '35': //Irrigation meter
                    c.active = true;
                    break;
                }
              } else {
                let id = Number(c.pk.id);
                switch (c.pk.id) {
                  case '1':
                  case '2':
                  case '3':
                  case '4':
                  case '5':
                  case '6':
                    c.valueNumber = parseUnitFlow(meters[id - 1]);
                    c.value = c.valueNumber + ' m3/h';
                    c.active = meters[id - 1].xActive || c.valueNumber > 0;
                    c.meter = id;
                    break;
                  case '7':
                  case '8':
                  case '9':
                  case '10':
                  case '11':
                  case '12':
                  case '13':
                  case '14':
                    c.valueNumber = parseUnitFert(meters[id - 1]);
                    c.value = c.valueNumber + ' l/h';
                    c.active = meters[id - 1].xActive || c.valueNumber > 0;
                    c.meter = id;
                    break;
                }
              }
            });
          }
        });
      });
    }

    function prepareMeterData() {
      if (vm.selected.active && vm.selected.meter !== undefined) {
        sectorFactory.irrigation(vm.currentUnit.id).then(function (data) {
          var sectors = data.plain().filter({ irrigationMeter: vm.selected.meter });
          var plannedFlow = 0;
          _.forEach(sectors, (o) => {
            plannedFlow = plannedFlow + o.plannedFlow;
          });

          vm.selected.plannedFlow = plannedFlow / 100;
        });
        sectorFactory.accumulated(vm.currentUnit.id, vm.selected.meter).then(function (data) {
          vm.selected.accumulated = Number(data.volume / 1000); // l -> m3
        });
      }
    }

    function parseUnitFlow(value) {
      value = '' + value.xFlow;
      if (vm.currentUnit.installer) {
        var decimals;
        if (vm.currentUnit.installer.instantFlow === 0 || vm.currentUnit.type == vm.UNITS.A_7000) {
          decimals = 2;
        } else {
          decimals = 1;
        }
        var first = value.slice(0, value.length - decimals);
        var last = value.slice(value.length - decimals, value.length);
        value = first + '.' + last;
      }
      return _.toNumber(value);
    }

    function parseUnitFert(value) {
      value = '' + value.xFlow;
      if (vm.currentUnit.installer) {
        var decimals = 1;
        if (vm.currentUnit.installer.instantFlow === 0) {
          decimals = 2;
        }
        var first = value.slice(0, value.length - decimals);
        var last = value.slice(value.length - decimals, value.length);
        value = first + '.' + last;
      }
      return _.toNumber(value);
    }

    function checkFormats(sensors) {
      _.forEach(sensors, (s) => {
        if (s.xState === 1) {
          if (vm.currentUnit.type !== vm.UNITS.A_7000) var format = vm.currentUnit.formats[s.formatId - 1];
          else var format = s.format;
          if (format !== undefined) {
            var value = '' + s.xValue;
            if (format.decimals > 0) {
              if (s.xValue < 0) {
                var sign = value.slice(0, 1);
                value = value.slice(1);
              }

              if (format.decimals > value.length) {
                var tlength = format.decimals + value.length;
                value = _.padStart(value, tlength, '0');
              }

              var first = value.slice(0, value.length - format.decimals);
              var last = value.slice(value.length - format.decimals, value.length);

              if (sign == '-') value = sign + '' + first + '.' + last;
              else value = first + '.' + last;
            }
            value = _.toNumber(value) + ' ' + format.units;
            s.value = value;
          }
        }
      });
    }

    function showSensors(value) {
      switch (value) {
        case 'all':
          vm.activeAll = true;
          vm.activeDigitals = false;
          vm.activeAnalogs = false;
          vm.activeCounters = false;
          break;
        case 'digitals':
          vm.activeAll = false;
          vm.activeDigitals = true;
          vm.activeAnalogs = false;
          vm.activeCounters = false;
          break;
        case 'analogs':
          vm.activeAll = false;
          vm.activeDigitals = false;
          vm.activeAnalogs = true;
          vm.activeCounters = false;
          break;
        case 'counters':
          vm.activeAll = false;
          vm.activeDigitals = false;
          vm.activeAnalogs = false;
          vm.activeCounters = true;
          break;
      }
    }

    function formatSensorValue(value, format, format7) {
      switch (vm.selected.type) {
        case 'analog':
          return formatAnalog(value, format7);
        case 'counter':
          if (_.isNumber(format)) {
            return formatCounterA25(value, format); // A2500,A5500,ABIT,A7000
          } else {
            //A4000
            if (vm.selected.pluviometer) {
              value = value / 10;
              return value;
            } else {
              return formatCounter(value);
            }
          }
          break;
      }
    }

    function formatAnalog(value, format7) {
      if (vm.currentUnit.type !== vm.UNITS.A_7000) var format = vm.currentUnit.formats[vm.selected.formatId - 1];
      else var format = format7;
      if (format !== undefined) {
        value = '' + value;
        if (format.decimals > 0) {
          var first = value.slice(0, value.length - format.decimals);
          var last = value.slice(value.length - format.decimals, value.length);
          value = first + '.' + last;
        }
        value = _.toNumber(value);
        vm.selected.label = format.units;
      }
      return value;
    }

    function formatCounter(value) {
      value = '' + value;
      if (vm.currentUnit.installer) {
        var decimals = 1;
        if (vm.currentUnit.installer.instantFlow === 0) {
          decimals = 2;
        }
        var first = value.slice(0, value.length - decimals);
        var last = value.slice(value.length - decimals, value.length);
        value = first + '.' + last;
      }
      return _.toNumber(value);
    }

    function formatCounterA25(value, format) {
      //        vm.currentUnit.version  <= 218
      switch (format) {
        case 0: // 0000m3 register l.
          if (vm.currentUnit.version <= 218 && vm.currentUnit.type === vm.UNITS.A_2500) value = value / 1000;
          vm.selected.label = 'm3';
          break;
        case 1: // 000.0m3 register l.
          value = value / 1000;
          vm.selected.label = 'm3';
          break;
        case 2: // 000.00m3 register l.
          value = value / 1000;
          vm.selected.label = 'm3';
          break;
        case 3: // 00000l. register l.
          vm.selected.label = 'l';
          break;
        case 4: // 000.0l register l.
          value = value / 100;
          vm.selected.label = 'l';
          break;
        case 5: // 000.00l register l.
          value = value / 100;
          vm.selected.label = 'l';
          break;
        case 6: // 0000l/m2 register l.
          vm.selected.label = 'l/m2';
          break;
        case 7: // 000.0 l/m2 register cl.
          value = value / 100;
          vm.selected.label = 'l/m2';
          break;
        case 8: // 000.00 l/m2 register cl.
          vm.selected.label = 'l/m2';
          value = value / 100;
          break;
        default: // Old
          value = value / 1000;
          vm.selected.label = 'm3';
          break;
      }
      return value;
    }

    function populateHistoryBar(dates, format) {
      vm.labels = _.map(dates, (o) => {
        return moment(o.date, 'DD-MM-YYYY HH:mm').format('DD-MM');
      });
      var value = _.map(dates, (o) => {
        return formatSensorValue(o.value, o.type, format);
      });

      vm.data = [value];
      vm.datasetOverride = [{ yAxisID: 'y-axis-1', label: vm.selected.label, color: '#45b7cd' }];
      vm.options = {
        scales: {
          yAxes: [
            {
              id: 'y-axis-1',
              display: true,
              position: 'left',
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      };
      vm.colors = ['#45b7cd', '#5882FA'];
      // vm.series = ['Riego', 'Lluvia'];
    }

    function checkConditioners() {
      progFactory.programs(vm.currentUnit.id, vm.currentUnit.type, true).then(function (data) {
        var activeList = progFactory.activePrograms();
        _.forEach(activeList, (prog) => {
          var cond = prog.conditioners.filter((o) => o.sensor !== 0);
          _.forEach(cond, (c) => {
            if (c.type !== 4) {
              //Analog sensor
              var id = c.sensor + '';
              var index = _.findIndex(vm.analogs, (a) => {
                return a.pk.id === id;
              });
              if (index !== -1) {
                if (!_.isArray(vm.analogs[index].programs)) {
                  vm.analogs[index].programs = [];
                }
                if (!_.isArray(vm.analogs[index].conditioners)) {
                  vm.analogs[index].conditioners = [];
                }
                vm.analogs[index].programs.push(prog);
                vm.analogs[index].conditioners.push(c);
              }
            } else {
              //Digital sensor
              var id = c.sensor + 35 + '';
              var index = _.findIndex(vm.digitals, (d) => {
                return d.id === id;
              });
              if (index !== -1) {
                if (!_.isArray(vm.digitals[index].programs)) {
                  vm.digitals[index].programs = [];
                }
                if (!_.isArray(vm.digitals[index].conditioners)) {
                  vm.digitals[index].conditioners = [];
                }
                vm.digitals[index].programs.push(prog);
                vm.digitals[index].conditioners.push(c);
              }
            }
          });
        });
      });
    }

    function loadConditionersLiterals() {
      vm.conditioners = resFactory.cond(vm.currentUnit.type);
    }

    function changeToProgram(program) {
      if (program !== undefined) {
        $state.go('programs.detail', { program: program, id: program.pk.id, unit: vm.currentUnit });
      }
    }

    $scope.$on('refresh', function (event, args) {
      if (args.unit) {
        angular.copy(args.unit, vm.currentUnit);
        $q.all([loadDigitals().then(), loadAnalogs().then()]).then((o) => {
          if (vm.currentUnit.type === vm.UNITS.A_4000) {
            checkConditioners();
          }
        });
        $scope.$emit('refreshed', { message: 'refreshed' });
      }
    });
  }
})();
