import { getContainer } from '@vegga/front-store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import moment from 'moment/moment';
import { UNITS } from '../../utils/units.enum';
import { exportToDocument } from '@vegga/front-utils';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('historyMetersController', historyMetersController);

  historyMetersController.$inject = [
    '$scope',
    '$state',
    'UserData',
    'historyFactory',
    'sensorsFactory',
    '$filter',
    'unitFactory',
    'historyUtilsFactory',
    '$rootScope',
  ];

  function historyMetersController(
    $scope,
    $state,
    UserData,
    historyFactory,
    sensorsFactory,
    $filter,
    unitFactory,
    historyUtilsFactory,
    $rootScope,
  ) {
    var vm = this;
    vm.filter = {};
    vm.pagination;

    vm.UNITS = UNITS;
    vm.destroy$ = new Subject();

    vm.currentPage = 1;
    vm.itemsPerPage = 15;
    vm.totalData;
    vm.devicesFacade = getContainer().resolve('devicesFacade');

    activate();

    function activate() {
      vm.currentState = $state.includes;

      vm.from = historyUtilsFactory.from;
      vm.to = historyUtilsFactory.to;
      vm.minDateValue = historyUtilsFactory.getMinDate(UserData.profesional);
      vm.maxDateValue = historyUtilsFactory.maxDateValue;
      vm.updateRangeDates = updateRangeDates;
      vm.loadHistory = loadHistory;
      vm.exportXLS = exportXLS;
      vm.getHistory = getHistory;

      vm.pageChangeHandler = pageChangeHandler;
      vm.pageSizeChangeHandler = pageSizeChangeHandler;
      vm.sortGrid = sortGrid;

      vm.groupBy = historyUtilsFactory.getGroupBy();

      vm.sensorListDTO = [{ text: $filter('translate')('sensors.all'), value: '0' }];

      vm.filter.groupBy = '2';
      vm.filter.sensor = '0';
      vm.sortDirection = 'DESC';
      vm.sortProperty = 'from';

      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((currentUnit) => {
        if (!currentUnit) {
          return;
        }
        loadData(currentUnit);
      });
    }

    function loadData(currentUnit) {
      vm.currentUnit = currentUnit;
      loadMeters();
      getColumnDefinition(vm.currentUnit.type);
      loadHistory();
    }
    function getColumnDefinition(type) {
      switch (type) {
        case UNITS.A_4000:
          vm.gridColumnsDef = historyUtilsFactory.generatedColumnDef(
            [
              historyUtilsFactory.getDateColumnDef(),
              { field: 'sensor' },
              {
                field: 'sensorName',
                width: 150,
              },
              { field: 'avg' },
              { field: 'max' },
              { field: 'min' },
            ],
            true,
          );
          break;
        case UNITS.A_7000:
          vm.gridColumnsDef = historyUtilsFactory.generatedColumnDef([
            historyUtilsFactory.getDateColumnDef(),
            { field: 'sensor' },
            { field: 'type' },
            { field: 'volume' },
          ]);
          break;
        case UNITS.A_2500:
        case UNITS.A_BIT:
        case UNITS.A_5500:
          vm.gridColumnsDef = historyUtilsFactory.generatedColumnDef([
            historyUtilsFactory.getDateColumnDef(),
            { field: 'sensor' },
            {
              field: 'sensorName',
              width: 150,
            },
            { field: 'avg' },
            { field: 'min' },
          ]);
          break;
        case UNITS.A_4500:
          vm.gridColumnsDef = historyUtilsFactory.generatedColumnDef([
            historyUtilsFactory.getDateColumnDef(),
            { field: 'sensor' },
            {
              field: 'sensorName',
              width: 150,
            },
            {
              field: 'flow',
              width: 150,
            },
            {
              field: 'volume',
              width: 150,
            },
            {
              field: 'escape',
              width: 150,
            },
            { field: 'desv' },
          ]);
          break;
      }
    }

    function checkDates(i) {
      if (i.from === undefined) {
        return i[2] + ' - ' + i[3];
      } else {
        return i.from + ' - ' + i.to;
      }
    }

    function getType7(i) {
      switch (i[6]) {
        case 5:
          return 'Riego';
        case 6:
          return 'Fertilizante';
        default:
          return '';
      }
    }

    function getVolume7(i) {
      switch (i[6]) {
        case 5:
          let r = Number(i[5]) / 1000;
          isNaN(r) ? (r = 0) : r.toString();
          return r + ' m3';
        case 6:
          let f = Number(i[5]) / 1000;
          isNaN(f) ? (f = 0) : '';
          return f.toString() + ' L';
        default:
          return '';
      }
    }

    function updateRangeDates(e) {
      const date = e.detail;

      const [start, end] = date;

      vm.from = start;
      vm.to = end;
      loadHistory();
    }

    function loadHistory(exporting) {
      var params = {
        //TODO: We have to add 1 day maybe becaue in the backend the check is "lower than".
        from: vm.from,
        to: moment(vm.to, 'DD-MM-YYYY').add(1, 'd').format('DD-MM-YYYY'),
        type: vm.currentUnit.type,
        filter: vm.filter,
        page: vm.currentPage,
        limit: vm.itemsPerPage,
        'sort.direction': vm.sortDirection,
        'sort.property': vm.sortProperty,
      };

      if (exporting) {
        $rootScope.$emit('preventSkeleton', true);
        params = { ...params, limit: vm.totalData ? vm.totalData : 1 };
      }

      return historyFactory.meters(vm.currentUnit.id, params).then((data) => {
        var meter = data;
        vm.totalData = data.total;

        if (meter.content !== undefined && meter.content.length > 0) {
          vm.hstySMetersData = meter.content.map((i) => {
            if (Array.isArray(i)) {
              //4500 - 7000
              return {
                from: checkDates(i),
                sensor: i[4],
                sensorName: i[5],
                flow: i[7],
                volume: vm.currentUnit.type === vm.UNITS.A_4500 ? i[9] : getVolume7(i),
                escape: i[10],
                desv: i[12],
                type: getType7(i),
              };
            } else {
              //4000 - 5500
              return {
                from: checkDates(i),
                sensor: i.sensorId,
                sensorName: i.sensorName,
                avg: i.avg,
                max: i.max,
                min: i.min,
              };
            }
          });
          if (exporting) {
            exportToDocument('xls', vm.gridColumnsDef, vm.hstySMetersData, {
              fileName: $filter('translate')('history.meterSensorHistory'),
            });
          }
        } else {
          vm.hstySMetersData = [];
          if (exporting) {
            exportToDocument('xls', vm.gridColumnsDef, vm.hstySMetersData, {
              fileName: $filter('translate')('history.meterSensorHistory'),
            });
          }
        }
      });
    }

    function pageChangeHandler(event) {
      vm.currentPage = event.detail;
      loadHistory();
    }

    function sortGrid({ detail }) {
      const { colField, dir } = detail;

      vm.sortDirection = dir;
      vm.sortProperty = colField;

      loadHistory();
    }

    function pageSizeChangeHandler(event) {
      vm.itemsPerPage = event.detail;
      vm.currentPage = 1;
      loadHistory();
    }

    function exportXLS() {
      loadHistory(true);
    }

    function getHistory() {
      historyFactory.getHistory(vm.currentUnit.type, vm.currentUnit.id);
    }

    function loadMeters() {
      if (vm.currentUnit.type === UNITS.A_4000) {
        sensorsFactory.getDigitals(vm.currentUnit.id).then(function (data) {
          vm.sensorList = _.filter(data.plain(), (o) => {
            return o.pk.id >= 11 && o.pk.id <= 35;
          });
          vm.sensorListDTO.push(
            ...vm.sensorList.map((sensor) => {
              return {
                text: sensor.pk.id + ' - ' + (sensor.name ? sensor.name : 'Sensor ' + sensor.pk.id),
                value: sensor.pk.id,
              };
            }),
          );
        });
      } else if (vm.currentUnit.type === UNITS.A_7000) {
        sensorsFactory.getDigitals(vm.currentUnit.id).then(function (data) {
          vm.sensorList = _.filter(data.plain(), (o) => {
            return (o.pk.id >= 1 && o.pk.id <= 14) || (o.pk.id >= 39 && o.pk.id <= 47) || o.pk.id == 18;
          });
          vm.sensorListDTO.push(
            ...vm.sensorList.map((sensor) => {
              return {
                text: sensor.pk.id + ' - ' + (sensor.name ? sensor.name : 'Sensor ' + sensor.pk.id),
                value: sensor.pk.id,
              };
            }),
          );
        });
      } else if (
        vm.currentUnit.type === UNITS.A_4500 ||
        vm.currentUnit.type === UNITS.A_5500 ||
        vm.currentUnit.type === UNITS.A_2500 ||
        vm.currentUnit.type === UNITS.A_BIT
        //A-2500 AND A-4500 -> No data available, we can not test if works
      ) {
        sensorsFactory.meters(vm.currentUnit.id).then(function (data) {
          var sensorList = data.plain();
          vm.sensorListDTO.push(
            ...sensorList.map((sensor) => {
              return {
                text: sensor.pk.id + ' - ' + (sensor.name ? sensor.name : 'Sensor ' + sensor.pk.id),
                value: sensor.pk.id,
              };
            }),
          );
        });
      } else {
        //This function was commented because metersOne gives us one meter, and we want to display a list
        sensorsFactory.metersOne(vm.currentUnit.id).then(function (data) {
          vm.sensorList = data.plain();
          vm.sensorListDTO.push(
            ...vm.sensorList.map((sensor) => {
              return {
                text: sensor.pk.id + ' - ' + (sensor.name ? sensor.name : 'Sensor ' + sensor.pk.id),
                value: sensor.pk.id,
              };
            }),
          );
        });
      }
    }

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
