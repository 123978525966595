import { getContainer } from '@vegga/front-store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import moment from 'moment/moment';
import { UNITS } from '../../utils/units.enum';
import { exportToDocument } from '@vegga/front-utils';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('historyProgramsController', historyProgramsController);

  historyProgramsController.$inject = [
    '$scope',
    '$state',
    'UserData',
    'historyFactory',
    '$filter',
    'unitFactory',
    'historyUtilsFactory',
    '$rootScope',
  ];

  function historyProgramsController(
    $scope,
    $state,
    UserData,
    historyFactory,
    $filter,
    unitFactory,
    historyUtilsFactory,
    $rootScope,
  ) {
    var vm = this;
    vm.filter = {};
    vm.destroy$ = new Subject();

    vm.hstyProgramsData = [];
    vm.currentPage = 1;
    vm.itemsPerPage = 15;
    vm.totalData;
    vm.devicesFacade = getContainer().resolve('devicesFacade');

    activate();

    function activate() {
      vm.currentState = $state.includes;
      vm.UNITS = UNITS;

      vm.programs = ['1', '2', '3', '4', '5', '6', '7', '8'];
      vm.from = historyUtilsFactory.from;
      vm.to = historyUtilsFactory.to;
      vm.minDateValue = historyUtilsFactory.getMinDate(UserData.profesional);
      vm.maxDateValue = historyUtilsFactory.maxDateValue;
      vm.updateRangeDates = updateRangeDates;
      vm.loadHistory = loadHistory;
      vm.exportXLS = exportXLS;
      vm.getHistory = getHistory;

      vm.pageChangeHandler = pageChangeHandler;
      vm.pageSizeChangeHandler = pageSizeChangeHandler;
      vm.sortGrid = sortGrid;

      vm.filter.groupBy = '2';
      vm.sortDirection = 'DESC';
      vm.sortProperty = 'from';

      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((currentUnit) => {
        if (!currentUnit) {
          return;
        }
        loadData(currentUnit);
      });
    }

    function loadData(currentUnit) {
      vm.currentUnit = currentUnit;
      vm.groupBy =
        vm.currentUnit.type !== UNITS.A_4000
          ? historyUtilsFactory.getGroupBy(true)
          : historyUtilsFactory.getGroupBy(true, true);

      getColumnDefinition(vm.currentUnit.type);
      loadHistory();
    }
    function getColumnDefinition(type) {
      switch (type) {
        case vm.UNITS.A_4000:
          return (vm.gridColumnsDef = historyUtilsFactory.generatedColumnDef(
            [
              historyUtilsFactory.getDateColumnDef(),
              {
                field: 'programName',
              },
              { field: 'starts' },
            ],
            true,
          ));
        case vm.UNITS.A_5500:
          return (vm.gridColumnsDef = historyUtilsFactory.generatedColumnDef([
            historyUtilsFactory.getDateColumnDef(),
            {
              field: 'programName',
            },
            { field: 'starts' },
          ]));

        case vm.UNITS.A_7000:
          return (vm.gridColumnsDef = historyUtilsFactory.generatedColumnDef([
            historyUtilsFactory.getDateColumnDef(),
            { field: 'program' },
            {
              field: 'programName',
            },
            { field: 'starts' },
          ]));
      }
    }

    function checkDates(i) {
      if (i.from === undefined) {
        return i[2] + ' - ' + i[3];
      } else {
        return i.from + ' - ' + i.to; //4000
      }
    }

    function updateRangeDates(e) {
      const date = e.detail;

      const [start, end] = date;

      vm.from = start;
      vm.to = end;
      loadHistory();
    }

    function loadHistory(exporting) {
      var params = {
        from: vm.from,
        to: moment(vm.to, 'DD-MM-YYYY').add(1, 'd').format('DD-MM-YYYY'),
        filter: vm.filter,
        page: vm.currentPage,
        limit: vm.itemsPerPage,
        'sort.direction': vm.sortDirection,
        'sort.property': vm.sortProperty,
      };

      if (exporting) {
        $rootScope.$emit('preventSkeleton', true);
        params = { ...params, limit: vm.totalData ? vm.totalData : 1 };
      }

      return historyFactory.programs(vm.currentUnit.id, params).then((data) => {
        var program = data;
        vm.totalData = data.total;

        if (program.content !== undefined && program.content.length > 0) {
          vm.hstyProgramsData = program.content.map((i) => {
            return {
              from: checkDates(i),
              program: Array.isArray(i) ? i[4] : i.program,
              programName: Array.isArray(i) ? i[1] : i.programName,
              starts: Array.isArray(i) ? i[6] : i.value,
            };
          });
          if (exporting) {
            exportToDocument('xls', vm.gridColumnsDef, vm.hstyProgramsData, {
              fileName: $filter('translate')('history.programHistory'),
            });
          }
        } else {
          vm.hstyProgramsData = [];
          if (exporting) {
            exportToDocument('xls', vm.gridColumnsDef, vm.hstyProgramsData, {
              fileName: $filter('translate')('history.programHistory'),
            });
          }
        }
      });
    }

    function pageChangeHandler(event) {
      vm.currentPage = event.detail;
      loadHistory();
    }

    function sortGrid({ detail }) {
      const { colField, dir } = detail;

      vm.sortDirection = dir;
      vm.sortProperty = colField;

      loadHistory();
    }

    function pageSizeChangeHandler(event) {
      vm.itemsPerPage = event.detail;
      vm.currentPage = 1;
      loadHistory();
    }

    function exportXLS() {
      loadHistory(true);
    }

    function getHistory() {
      historyFactory.getHistory(vm.currentUnit.type, vm.currentUnit.id);
    }

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
