import moment from 'moment/moment';
import angular from 'angular';
import subprogram_modal from '../subprogram_modal.html';
import suspendModal from '../suspend-modal.html';
import freqDays from '../freq_days.html';
import activationsManual from '../activations_modal.html';
import defStopManual from '../stopDefinitiveCheck-modal.html';
import { FERT_MODE } from '../../utils/enums/programs/programs-fertilization-mode.enum';
import { FERT_TYPE } from '../../utils/enums/programs/programs-fertilization-type.enum';
import { FERT_UNITS } from '../../utils/enums/programs/programs-fertilization-units.enum';
import { getContainer } from '@vegga/front-store';
import { Subject, filter, take, takeUntil } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    //TODO - check moment
    .constant('moment', moment)

    .controller('programsDetailControllerA45', programsDetailController);

  programsDetailController.$inject = [
    '$log',
    '$scope',
    '$state',
    '$uibModal',
    '$q',
    'progFactory',
    'unitFactory',
    'registerFactory',
    'NgTableParams',
    '$filter',
    'fertilizerFactory',
    'manualFactory',
    '$confirm',
    'utilsFactory',
    'sensorsFactory',
    'conditionerFactory',
    '$rootScope',
  ];

  function programsDetailController(
    $log,
    $scope,
    $state,
    $uibModal,
    $q,
    progFactory,
    unitFactory,
    registerFactory,
    NgTableParams,
    $filter,
    fertilizerFactory,
    manualFactory,
    $confirm,
    utilsFactory,
    sensorsFactory,
    conditionerFactory,
    $rootScope,
  ) {
    var vm = this;
    vm.activeList;
    vm.sectorsArea;
    vm.programed;
    vm.getSubprogramStartTime;
    vm.programsFacade = getContainer().resolve('programsFacade');
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    vm.showSkeleton = true;
    vm.getSubprogramStartTime = getSubprogramStartTime;
    vm.getSubprogramStartTimeNoIrrig = getSubStartTimeNoIrrig;
    vm.getTimeFromMins = progFactory.getTimeFromMins;
    vm.toDate = toDate;
    vm.toHour = toHour;
    vm.formatDate = formatDate;
    vm.selectedOp = '1';
    vm.getValueInUnit = getValueInUnit;
    vm.formatFertilizerValue = formatFertilizerValue;
    vm.manualStart = manualStart;
    vm.manualEnd = manualEnd;
    vm.manualStart = manualStart;
    vm.manualStop = manualStop;
    vm.exitManualStop = exitManualStop;
    vm.manualOutOf = manualOutOf;
    vm.manualRunOf = manualRunOf;
    vm.manualSuspend = manualSuspend;
    vm.manualFreqDays = manualFreqDays;
    vm.manualActivations = manualActivations;
    vm.manualEndSecurityIrrig = manualEndSecurityIrrig;
    vm.manualDefinitiveStop = manualDefinitiveStop;
    vm.manualDefinitiveStopFert = manualDefinitiveStopFert;
    vm.manualDefinitiveStopPh = manualDefinitiveStopPh;
    vm.getTimeFromMins = getTimeFromMins;

    vm.getFormatedValue = getFormatedValue;
    vm.getFormatedXValue = getFormatedXValue;
    vm.getFormatedPreIrrig = getFormatedPreIrrig;
    vm.getFormatedPostIrrig = getFormatedPostIrrig;
    vm.getFormatedXPreIrrig = getFormatedXPreIrrig;
    vm.getFormatedXPostIrrig = getFormatedXPostIrrig;
    vm.getFormatedFert = getFormatedFert;
    vm.getFormatedFito = getFormatedFito;
    vm.fromMinutesToHHMM = fromMinutesToHHMM;

    vm.getProgramSectorState = getProgramSectorState;

    vm.showSecurityTime = showSecurityTime;
    vm.showSecurityTimeColumn = showSecurityTimeColumn;
    vm.showSecurityTimeSubprograms = showSecurityTimeSubprograms;
    vm.fertilizers = [];
    vm.goToSector = goToSector;
    vm.viewChangeClicked = viewChangeClicked;

    vm.destroy$ = new Subject();

    activate();
    function activate() {
      initSubscriptions();
      vm.viewDate = moment().format();
    }

    function initSubscriptions() {
      vm.programsFacade.clearProgramResponse();
      vm.programsFacade.programById$.pipe(take(1)).subscribe((prog) => {
        const program = progFactory.formatProgramView(prog);
        vm.program = progFactory.checkIrrigation(program, vm.currentUnit.type);
        vm.activeSubList = progFactory.filterSubPrograms(vm.program);
        vm.parsedDays = parseDays();
        doAllQueries();
      });

      vm.programsFacade.isLoading$
        .pipe(
          filter((isLoading) => !isLoading),
          take(1)
        )
        .subscribe((isLoading) => {
          if (!isLoading) {
            vm.showSkeleton = isLoading;
          }
        });

      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((currentUnit) => {
        vm.FERT_TYPE = FERT_TYPE;
        vm.FERT_UNITS = FERT_UNITS;
        vm.FERT_MODE = FERT_MODE;
        vm.currentUnit = currentUnit;
        vm.programsFacade.getA4500Program(vm.currentUnit.id, $state.params.id);
      });
    }

    function doAllQueries() {
      loadRegisterData();
      loadFitos();

      let queries = [];
      queries.push(
        progFactory.getProgramSectors(vm.currentUnit.id, vm.program.pk.id), // programSectors
        progFactory.subprograms(vm.currentUnit.id, vm.program.pk.id), //fertilizers
      );
      var progSectIdx = 0;
      var fertIdx = 1;
      var fertHeaderIdx;
      var genFertHeaderIdx;
      var conditionersIdx;

      if (vm.program.xHeader >= 0 && vm.program.xHeader <= 4) {
        queries.push(
          fertilizerFactory.getFertilizationHeaders(vm.currentUnit.id),
          fertilizerFactory.getGeneralFertilizationHeader(vm.currentUnit.id, vm.program.xHeader),
        );
        fertHeaderIdx = 2;
        genFertHeaderIdx = 3;
        conditionersIdx = 4;
      } else {
        vm.fertHeader = 'Header error';
        vm.genFertHeader = 'Header error';
        conditionersIdx = 2;
      }
      queries.push(conditionerFactory.getProgramConditioners(vm.currentUnit.id, vm.program.pk.id));

      $q.all(queries).then((response) => {
        loadSubprograms(response[progSectIdx]);
        if (fertHeaderIdx) {
          loadFertilizersHeaders(response[fertHeaderIdx]);
        }
        loadFertilizers(response[fertIdx]);
        if (genFertHeaderIdx) {
          loadGeneralFertilizersHeader(response[genFertHeaderIdx]);
          if (vm.fertHeader && vm.fertHeader.fertilizersMode === 0 && vm.fertHeader.ceSecurity > 0) {
            loadSecurityAnalogSensor(vm.fertHeader.ceSecurity);
          }
          if (vm.fertHeader && vm.fertHeader.fertilizersMode === 0 && vm.fertHeader.ceInput > 0) {
            loadCEEntradaAnalogSensor(vm.fertHeader.ceInput);
          }
        }
        loadProgramConditioners(response[conditionersIdx]);
      });
    }

    function showSecurityTimeColumn() {
      if (vm.currentUnit.installer.timeVolume) {
        if (vm.currentUnit.installer.prePostIrrigForSubprog === 1 && vm.program.sectorsByGroup === 1) {
          return true;
        } else {
          const irrigUnits = vm.program.irrigUnits;
          return irrigUnits === 1 || irrigUnits === 2 || irrigUnits === 5;
        }
      } else {
        return false;
      }
    }

    function showSecurityTime(programSector) {
      if (vm.currentUnit.installer.timeVolume) {
        let irrigUnits;
        if (vm.currentUnit.installer.prePostIrrigForSubprog === 1 && vm.program.sectorsByGroup === 1) {
          irrigUnits = programSector.irrigUnits;
        } else {
          irrigUnits = vm.program.irrigUnits;
        }
        return irrigUnits === 1 || irrigUnits === 2 || irrigUnits === 5;
      }
      return false;
    }

    function showSecurityTimeSubprograms(subprogram) {
      if (vm.currentUnit.installer.timeVolume && subprogram) {
        if (vm.currentUnit.installer.prePostIrrigForSubprog === 1) {
          const irrigUnits = subprogram.irrigUnits;
          return irrigUnits === 1 || irrigUnits === 2 || irrigUnits === 5;
        } else {
          const irrigUnits = vm.program.irrigUnits;
          return irrigUnits === 1 || irrigUnits === 2 || irrigUnits === 5;
        }
      } else {
        return false;
      }
    }

    function loadFitos() {
      if (vm.program.xHeader >= 0 && vm.program.xHeader <= 4) {
        progFactory.getFitos(vm.currentUnit.id).then((response) => {
          let allFitos = response.plain();
          vm.headerFito1 = allFitos[vm.program.xHeader * 2 - 2];
          vm.headerFito2 = allFitos[vm.program.xHeader * 2 - 1];
        });
      }
    }

    function loadSecurityAnalogSensor(sensorId) {
      sensorsFactory.analogById(vm.currentUnit.id, sensorId).then((response) => {
        let result = response.plain();
        vm.fertHeader.ceSecuritySensor = result;
      });
    }

    function loadCEEntradaAnalogSensor(sensorId) {
      sensorsFactory.analogById(vm.currentUnit.id, sensorId).then((response) => {
        let result = response.plain();
        vm.fertHeader.ceInputSensor = result;
      });
    }

    function loadGeneralFertilizersHeader(response) {
      vm.genFertHeader = response.plain();
      for (let genFertHeader of vm.genFertHeader) {
        const fertValue = genFertHeader.xQuantity;
        const irrigValue = fertValue % Math.pow(2, 16);
        const newFertValue = (fertValue - irrigValue) / Math.pow(2, 16);
        genFertHeader.xPropFert = newFertValue;
        genFertHeader.xPropIrrig = irrigValue;
      }
    }

    function loadFertilizersHeaders(response) {
      let fertsHeaders = response.plain();
      vm.fertHeader = fertsHeaders[vm.program.xHeader - 1];
      if (vm.fertHeader && vm.fertHeader.xNumProgramCE > 0) {
        progFactory
          .program(vm.currentUnit.id, vm.fertHeader.xNumProgramCE, {
            'init-program-ferts': true,
            header: vm.fertHeader.id,
          })
          .then((response) => {
            if (response.plain().headerPid) {
              vm.reference = `${response.plain().headerPid?.xReference / 10} mS`;
            } else {
              vm.reference = '0 mS';
            }
          });
      } else {
        vm.reference = '-';
      }
    }

    function loadFertilizers(response) {
      if (vm.fertHeader) {
        let ferts = response.plain();
        if (vm.program.programType === 0) {
          vm.fert = ferts[vm.program.xSubProgramInProgress - 1];
          copyFertsToProgramSectors(ferts, vm.programSectors);
        } else {
          vm.fert = ferts[0];
          parseProportionalValues(vm.fert);
        }
      }
    }

    function copyFertsToProgramSectors(ferts, programSectors) {
      for (let i = 0; i < programSectors.length; i++) {
        programSectors[i].fert = ferts[i];
        if (
          vm.fertHeader.fertilizationMode !== vm.FERT_MODE.SERIAL &&
          (vm.program.fertType === vm.FERT_TYPE.PROPORTIONAL_L_M3 ||
            vm.program.fertType === vm.FERT_TYPE.PROPORTIONAL_cl_L)
        ) {
          parseProportionalValues(ferts[i]);
        }
      }
    }

    function parseProportionalValues(fert) {
      for (let i = 1; i <= 8; i++) {
        eval(`fert.propFert${i} = fert.proportionalParsedFertValue${i}_1`);
        eval(`fert.propIrrig${i} = fert.proportionalParsedFertValue${i}_2`);
      }
    }

    function getProgramSectorState(subprogram) {
      switch (subprogram.xStatus) {
        case 0:
          return 'Parado';
        case 1:
          return 'Regando';
        case 2:
          return 'Esperando postriego';
        case 3:
          return 'Finalizado';
      }
    }

    function getTimeFromMins(minutes) {
      return progFactory.getTimeFromMins(minutes).format('HH:mm');
    }

    function getIrrigUnits(program, programSector, prePostIrrigForSubprog) {
      return utilsFactory.getIrrigUnits(
        prePostIrrigForSubprog,
        program.programType,
        programSector.irrigUnits,
        program.sectorsByGroup,
        program.irrigUnits,
      );
    }

    function getFormatedXValue(programSector) {
      if (programSector === undefined) return;
      const xValue = programSector.xValue;
      const irrigUnits = getIrrigUnits(vm.program, programSector, vm.currentUnit.installer.prePostIrrigForSubprog);

      return utilsFactory.getFormatedXValue4500(xValue, irrigUnits, vm.currentUnit.installer.irrigDecimals);
    }
    function getFormatedValue(programSector) {
      if (programSector === undefined) return;
      const value = programSector.value;
      const irrigUnits = getIrrigUnits(vm.program, programSector, vm.currentUnit.installer.prePostIrrigForSubprog);
      return utilsFactory.getFormatedValue4500(value, irrigUnits, vm.currentUnit.installer.irrigDecimals);
    }

    function getFormatedPreIrrig(programSector) {
      if (programSector === undefined) return;
      const preIrrig = programSector.preIrrig;
      const irrigUnits = getIrrigUnits(vm.program, programSector, vm.currentUnit.installer.prePostIrrigForSubprog);
      return utilsFactory.getFormatedPrePostIrrig4500(preIrrig, irrigUnits, vm.currentUnit.installer.irrigDecimals);
    }

    function getFormatedXPreIrrig(programSector) {
      if (programSector === undefined) return;
      const xPreIrrig = vm.program.xPrePostIrrigation;
      const irrigUnits = getIrrigUnits(vm.program, programSector, vm.currentUnit.installer.prePostIrrigForSubprog);
      return utilsFactory.getFormatedPrePostIrrig4500(xPreIrrig, irrigUnits, vm.currentUnit.installer.irrigDecimals);
    }

    function getFormatedPostIrrig(programSector) {
      if (programSector === undefined) return;
      const postIrrig = programSector.postIrrig;
      const irrigUnits = getIrrigUnits(vm.program, programSector, vm.currentUnit.installer.prePostIrrigForSubprog);
      return utilsFactory.getFormatedPrePostIrrig4500(postIrrig, irrigUnits, vm.currentUnit.installer.irrigDecimals);
    }

    function getFormatedXPostIrrig(programSector) {
      if (programSector === undefined) return;
      const xPostIrrig = vm.program.xPrePostIrrigation;
      const irrigUnits = getIrrigUnits(vm.program, programSector, vm.currentUnit.installer.prePostIrrigForSubprog);
      return utilsFactory.getFormatedPrePostIrrig4500(xPostIrrig, irrigUnits, vm.currentUnit.installer.irrigDecimals);
    }

    function getFormatedFert(fertValue) {
      const fertUnits = vm.program.fertUnits;
      var value = fertValue;
      if (
        vm.program.fertType === vm.FERT_TYPE.PROPORTIONAL_L_M3 ||
        vm.program.fertType === vm.FERT_TYPE.PROPORTIONAL_cl_L
      ) {
        value = fertValue * 100;
      }
      return utilsFactory.getFormatedFertilizationA4500(value, fertUnits);
    }

    function fromMinutesToHHMM(minutes) {
      return utilsFactory.fromMinutesToHHMM(minutes);
    }

    function getFormatedFito(fitoValue, fitoUnits) {
      return utilsFactory.getFormatedFitoA4500(fitoValue, fitoUnits);
    }

    function loadSubprograms(response) {
      vm.programSectors = response.plain();
      vm.activeProgramSector = vm.programSectors[vm.program.xSubProgramInProgress - 1];
      let programSectorsArray = _.filter(vm.programSectors, (sector) => {
        return sector.sector1 !== 0;
      });
      vm.program.subprograms = programSectorsArray;
      vm.program.unittype = vm.currentUnit.type;
      loadManualActions();
    }

    function loadManualActions() {
      if (vm.program.xState == 0) {
        if (vm.program.subprograms.length > 0) {
          vm.startProgram = true;
        } else vm.endProgram = false;
      } else if (
        vm.program.xState == 1 ||
        vm.program.xState == 3 ||
        vm.program.xState == 5 ||
        vm.program.xState == 7 ||
        vm.program.xState == 6 ||
        vm.program.xState == 10
      ) {
        vm.endProgram = true;
        vm.startProgram = false;
      } else {
        vm.endProgram = false;
        vm.startProgram = false;
      }
    }

    function parseDays() {
      const DAYS = {
        1: 'monday',
        2: 'tuesday',
        3: 'wednesday',
        4: 'thursday',
        5: 'friday',
        6: 'saturday',
        7: 'sunday',
      };

      return Object.keys(DAYS)
        .filter((dayKey) => vm.program[DAYS[dayKey]])
        .map((dayKey) => +dayKey);
    }

    function toDate(date) {
      return moment(date, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY');
    }

    function toHour(date) {
      return moment(date, 'DD-MM-YYYY HH:mm').format('HH:mm');
    }

    function goToSector(id) {
      $state.go('sectors', { unit: vm.currentUnit, load: id });
    }

    function getSubprogramStartTime(index) {
      var subprogram = vm.activeSubList[index];
      if (subprogram !== undefined && (subprogram.unit === 0 || subprogram.unit === 1)) {
        var total = 0;
        if (subprogram.unit === 0) {
          if (index === Number(vm.program.xSubprogramCourse) + 1) {
            total = vm.program.xValue;
          } else {
            total = subprogram.value;
          }
        }
        if (subprogram.unit === 1) {
          if (index === Number(vm.program.xSubprogramCourse) + 1) {
            total = vm.program.xValue / 60;
          } else {
            total = subprogram.value / 60;
          }
        }

        var current;
        if (vm.currentUnit.ram && vm.currentUnit.ram.date) {
          current = moment(vm.currentUnit.ram.date, 'DD-MM-YYYY HH:mm').format('HH:mm');
        } else {
          current = moment().format('HH:mm');
        }
        var parts = current.split(':');
        var hoursToMin = (parseInt(parts[0]) * 60) | 0;
        var temp = hoursToMin + parseInt(parts[1]) + total;
        if (temp > 1440) {
          temp = temp - 1440;
        }
        var irrigationHour = progFactory.getTimeFromMins(temp);
        return irrigationHour.format('HH:mm');
      } else {
        subprogram = _.last(vm.activeSubList);
        if (subprogram.unit === 0 || subprogram.unit === 1) {
          var total = 0;
          if (subprogram.unit === 0) {
            total = subprogram.value;
          }
          if (subprogram.unit === 1) {
            total = subprogram.value / 60;
          }
          var current;
          if (vm.currentUnit.ram && vm.currentUnit.ram.date) {
            current = moment(vm.currentUnit.ram.date, 'DD-MM-YYYY HH:mm').format('HH:mm');
          } else {
            current = moment().format('HH:mm');
          }
          var parts = current.split(':');
          var hoursToMin = (parseInt(parts[0]) * 60) | 0;
          var temp = hoursToMin + parseInt(parts[1]) + total;
          if (temp > 1440) {
            temp = temp - 1440;
          }
          var irrigationHour = progFactory.getTimeFromMins(temp);
          return irrigationHour.format('HH:mm');
        } else {
          return '-';
        }
      }
    }

    function getSubStartTimeNoIrrig(index) {
      if (index === 0 || vm.program.sequential) {
        return vm.program.irrigationHour;
      } else {
        var total = 0;
        var parts = vm.program.irrigationHour.split(':');
        for (var i = 0; i < index; i++) {
          total = total + vm.activeSubList[i].totalTime;
        }
        var hoursToMin = (parseInt(parts[0]) * 60) | 0;
        var temp = hoursToMin + parseInt(parts[1]) + total;
        if (temp > 1440) {
          temp = temp - 1440;
        }
        var irrigationHour = progFactory.getTimeFromMins(temp);
        return irrigationHour.format('HH:mm');
      }
    }

    function viewChangeClicked(nextView) {
      if (nextView === 'year' || nextView === 'day' || nextView === 'week') {
        return false;
      }
    }

    function successRegister(data) {
      var register = data.plain();

      if (register.content !== undefined && register.content.length > 0) {
        registerFactory.parseRegister(vm.currentUnit.type, register.content);
        // TODO - code refactored
        //   switch(vm.currentUnit.type){
        //       case 3:
        //           registerFactory.parseRegister( "3" , register.content );
        //         break;
        //       case 4:
        //           registerFactory.parseRegister( "4" , register.content );
        //         break;
        //   }

        var response = register.content;

        vm.tableParams.total(register.totalElements);

        return response;
      }
    }

    function loadProgramConditioners(response) {
      vm.conditioners = response.plain();
      _.forEach(vm.conditioners, (conditioner) => {
        conditioner.stopIrrig = true;
        switch (conditioner.xStatus) {
          case 0:
            conditioner.status = 'stop';
            break;
          case 1:
            conditioner.status = 'active';
            break;
          case 2:
            conditioner.status = 'out';
            break;
          case 3:
            conditioner.status = 'error';
            break;
        }
        // groupByType(conditioner);
        setSensorType(conditioner);
      });
    }

    function setSensorType(conditioner) {
      switch (Number(conditioner.from)) {
        case 0: // digital
          conditioner.sensorType = 0;
          break;
        case 1: // analog
          conditioner.sensorType = 1;
          break;
        case 2: // logic
          conditioner.sensorType = 3;
          break;
        case 3:
        case 4:
        case 5: // meter
          conditioner.sensorType = 2;
          break;
        case 6: // analog cer
          conditioner.sensor = vm.fertHeader.ceRegulation;
          conditioner.sensorType = 1;
          break;
        case 7: // analog phr
          conditioner.sensor = vm.fertHeader.pHRegulation;
          conditioner.sensorType = 1;
          break;
        case 8: // analog cer
          conditioner.sensor = vm.fertHeader.ceRegulation;
          conditioner.sensorType = 1;
          break;
        case 9: // analog phr
          conditioner.sensor = vm.fertHeader.pHRegulation;
          conditioner.sensorType = 1;
          break;
        case 10: // analog ces
          conditioner.sensor = vm.fertHeader.ceSecurity;
          conditioner.sensorType = 1;
          break;
        case 11: // analog phs
          conditioner.sensor = vm.fertHeader.pHSecurity;
          conditioner.sensorType = 1;
          break;
        case 13: // analog cee
          conditioner.sensor = vm.fertHeader.ceInput;
          conditioner.sensorType = 1;
          break;
      }
    }

    function loadRegisterData() {
      vm.tableParams = new NgTableParams(
        {
          page: 1,
          count: 15,
          filter: {
            anomaly: true,
            prog: true,
            comm: false,
            clean: false,
            fert: false,
            other: false,
            sensors: false,
            pivots: false,
            manual: true,
            cond: false,
            programs: ['' + vm.program.pk.id],
          },
        },
        {
          counts: [],
          getData: function (params) {
            var pageNumber = 1;
            var from = moment().subtract(7, 'day')._d;
            var to = moment()._d;
            if (params._params) {
              pageNumber = params._params.page;
            }

            return registerFactory
              .get(vm.currentUnit.id, params._params.filter, from, to, pageNumber, 1000)
              .then((data) => {
                var register = data.plain();
                //var filter = params._params.filter;
                //register = filterRegister(register,filter);

                if (register.content !== undefined && register.content.length > 0) {
                  registerFactory.parseRegister(vm.currentUnit.type, register.content);

                  var response = register.content;

                  vm.tableParams.total(register.total);
                  return response;
                }
              });
          },
        },
      );

      // vm.tableParams = new NgTableParams({
      //     page: 1,
      //     count: 15,
      //     filter: { type: "" }

      // },
      //     {
      //         counts: [],
      //         getData: function (params) {
      //             var pageNumber = 1
      //             if (params._params) {
      //                 pageNumber = params._params.page;
      //             }

      //             if (params._params.filter.type !== "") {
      //                 var type = params._params.filter.type;
      //                 return unitFactory.programRegisters(vm.program.pk.deviceId, vm.program.pk.id, type, pageNumber).then(successRegister);
      //             } else {
      //                 return unitFactory.programRegisters(vm.program.pk.deviceId, vm.program.pk.id, null, pageNumber).then(successRegister);
      //             }

      //         }
      //     });
    }

    function getValueInUnit(input) {
      var unit = vm.program.unit;
      if (unit === 0 || unit === 16) {
        input = input / 60;
      }
      switch (unit) {
        case 0:
          return $filter('parsetime')(input * 60);
          break;
        case 1:
          return (input / 1000).toFixed(2) + 'm3';
          break;
        case 3:
          var mins = input;
          return $filter('parsetime', true)(mins);
          break;
        case 4:
          return input + 'l';
          break;
        case 2:
          //if(prog == 1) input = vm.programed;
          return (input / 1000).toFixed(2) + 'm3';
          break;
      }
    }

    function formatDate(date) {
      return moment(date).format('MMMM-YYYY');
    }

    function formatFertilizerValue(value) {
      var general = vm.currentUnit.fertilizer;

      switch (general.fertUnits) {
        case 0: //A25_UNIDADES_FERT_HM:
          // Passem a minuts,, si el resto es diferent de 0 => sumem 1
          if (value % 60 != 0) {
            value = value / 60 + 1;
          } else {
            value = value / 60;
          }
          value = $filter('parsetime')(value * 60, true);
          break;
        case 1: //A25_UNIDADES_FERT_L:
        case 2: //A25_UNIDADES_FERT_LHA:
          value = value / 100 + ' l'; //cl to l
          /*                switch(general.fertDecimals)
                                    {
                                        case 0:
                                            value = value + ' l';    
                                            break;
                                        case 1:
                                            value = value/10 + ' l';    
                                            break;
                                        case 2:        
                                            value = value/100 + ' l';    
                                            break;
                                    }*/
          break;

        case 3: //A25_UNIDADES_FERT_MS:
          var mins = value * 60 * 60;
          value = $filter('parsetime')(mins);
          break;
      }
      return value;
    }

    function manualStart() {
      //vm.program.programType tipus de programa -> 0 subprogrames, 1 Lineal

      if (vm.program.programType == 1) {
        $confirm({ text: 'Enviar inicio manual?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No' }).then(function () {
          var params = {};
          params.type = vm.currentUnit.type;
          params.deviceId = vm.program.pk.deviceId;
          params.action = 6;
          params.parameter1 = Number(vm.program.pk.id);
          params.parameter2 = 1;
          manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
          var modalInstance = manualFactory.showLoadingBar();
          modalInstance.result.then((result) => {
            $state.go('a45programs', { unit: $state.params.unit }, { reload: 'a45programs' });
            $scope.$emit('reload', { message: 'reload' });
          });
        });
      } else if (vm.program.programType == 0) {
        var modalInstance = $uibModal.open({
          animation: true,
          ariaLabelledBy: 'modal-title',
          ariaDescribedBy: 'modal-body',
          template: subprogram_modal,
          controller: 'ModalInstanceCtrl',
          controllerAs: 'vm',
          resolve: {
            program: function () {
              return vm.program;
            },
          },
        });
        modalInstance.result.then(
          function (selectedItem) {
            var modalInstance = manualFactory.showLoadingBar();
            manualFactory.start(vm.currentUnit, vm.program.pk.id, selectedItem).then((response) => {});

            modalInstance.result.then((result) => {
              //                $state.go('programs',{unit:vm.currentUnit},{reload:true});
              //                $scope.$emit('reload',{message:'reload'});
              $rootScope.$broadcast('reload', { message: 'refreshing' });
            });
          },
          function () {
            $log.info('Modal dismissed at: ' + new Date());
          },
        );
      }
    }

    function manualEnd() {
      $confirm({ text: 'Quiere enviar un paro de programa?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No' }).then(
        function () {
          var params = {};
          params.type = vm.currentUnit.type;
          params.deviceId = vm.program.pk.deviceId;
          params.action = 7;
          params.parameter1 = Number(vm.program.pk.id);
          params.parameter2 = 0;
          manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
          var modalInstance = manualFactory.showLoadingBar();
          modalInstance.result.then((result) => {
            $state.go('a45programs', { unit: $state.params.unit }, { reload: 'a45programs' });
            $scope.$emit('reload', { message: 'reload' });
          });
        },
      );
    }

    function manualStop() {
      $confirm({ text: 'Quiere enviar un paro de programa?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No' }).then(
        function () {
          var params = {};
          params.type = vm.currentUnit.type;
          params.deviceId = vm.program.pk.deviceId;
          params.action = 5;
          params.parameter1 = Number(vm.program.pk.id);
          params.parameter2 = 1;
          manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
          var modalInstance = manualFactory.showLoadingBar();
          modalInstance.result.then((result) => {
            $state.go('a45programs', { unit: $state.params.unit }, { reload: 'a45programs' });
            $scope.$emit('reload', { message: 'reload' });
          });
        },
      );
    }

    function exitManualStop() {
      $confirm({ text: 'Quiere enviar un paro de programa?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No' }).then(
        function () {
          var params = {};
          params.type = vm.currentUnit.type;
          params.deviceId = vm.program.pk.deviceId;
          params.action = 5;
          params.parameter1 = Number(vm.program.pk.id);
          params.parameter2 = 0;
          manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
          var modalInstance = manualFactory.showLoadingBar();
          modalInstance.result.then((result) => {
            $state.go('a45programs', { unit: $state.params.unit }, { reload: 'a45programs' });
            $scope.$emit('reload', { message: 'reload' });
          });
        },
      );
    }

    function manualOutOf() {
      $confirm({ text: 'Enviar fuera de servicio?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No' }).then(
        function () {
          var params = {};
          params.type = vm.currentUnit.type;
          params.deviceId = vm.program.pk.deviceId;
          params.action = 1;
          params.parameter1 = Number(vm.program.pk.id);
          params.parameter2 = 1;
          manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
          var modalInstance = manualFactory.showLoadingBar();
          modalInstance.result.then((result) => {
            $state.go('a45programs', { unit: $state.params.unit }, { reload: 'a45programs' });
            $scope.$emit('reload', { message: 'reload' });
          });
        },
      );
    }

    function manualRunOf() {
      $confirm({ text: 'Enviar orden de activacion?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No' }).then(
        function () {
          var params = {};
          params.deviceId = vm.program.pk.deviceId;
          params.type = vm.currentUnit.type;
          params.action = 1;
          params.parameter1 = Number(vm.program.pk.id);
          params.parameter2 = 0;
          manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
          var modalInstance = manualFactory.showLoadingBar();
          modalInstance.result.then((result) => {
            $state.go('a45programs', { unit: $state.params.unit }, { reload: 'a45programs' });
            $scope.$emit('reload', { message: 'reload' });
          });
        },
      );
    }

    function manualSuspend() {
      var modalInstance = $uibModal.open({
        animation: true,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        template: suspendModal,
        controller: 'ModalInstanceSuspendCtrl',
        controllerAs: 'vm',
      });

      modalInstance.result.then(
        function (selectedItem) {
          var modalInstance = manualFactory.showLoadingBar();

          var params = {};
          params.type = vm.currentUnit.type;
          params.deviceId = vm.program.pk.deviceId;
          params.action = 2;
          params.parameter1 = Number(vm.program.pk.id);
          params.parameter2 = selectedItem % Math.pow(2, 8);
          params.parameter3 = selectedItem >> 8;
          manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
          modalInstance.result.then((result) => {
            $rootScope.$broadcast('reload', { message: 'refreshing' });
          });
        },
        function () {
          $log.info('Modal dismissed at: ' + new Date());
        },
      );
    }

    function manualFreqDays() {
      var modalInstance = $uibModal.open({
        animation: true,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        template: freqDays,
        controller: 'ModalInstanceCtrl',
        controllerAs: 'vm',
        resolve: {
          program: function () {
            return vm.program;
          },
        },
      });

      modalInstance.result.then(
        function (selectedItem) {
          var params = {};
          params.type = vm.currentUnit.type;
          params.deviceId = vm.program.pk.deviceId;
          params.action = 3;
          params.parameter1 = Number(vm.program.pk.id);
          params.parameter2 = selectedItem;
          var modalInstance = manualFactory.showLoadingBar();
          manualFactory.genericManualAction(params.deviceId, params).then((response) => {});

          modalInstance.result.then((result) => {
            $rootScope.$broadcast('reload', { message: 'refreshing' });
          });
        },
        function () {
          $log.info('Modal dismissed at: ' + new Date());
        },
      );
    }

    function manualActivations() {
      var modalInstance = $uibModal.open({
        animation: true,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        template: activationsManual,
        controller: 'ModalInstanceActivationCtrl',
        controllerAs: 'vm',
        resolve: {
          program: function () {
            return vm.program;
          },
        },
      });
      modalInstance.result.then(
        function (transferData) {
          var params = {};
          params.type = vm.currentUnit.type;
          params.deviceId = vm.program.pk.deviceId;
          params.action = 4;
          params.parameter1 = Number(vm.program.pk.id);
          params.parameter2 = transferData.activations;
          params.parameter3 = transferData.time;
          var modalInstance = manualFactory.showLoadingBar();
          manualFactory.genericManualAction(params.deviceId, params).then((response) => {});

          modalInstance.result.then((result) => {
            $rootScope.$broadcast('reload', { message: 'refreshing' });
          });
        },
        function () {
          $log.info('Modal dismissed at: ' + new Date());
        },
      );
    }

    function manualEndSecurityIrrig() {
      $confirm({
        text: 'Enviar orden de finalizar reigo de seguridad?',
        title: 'Manual AGRONIC',
        ok: 'Si',
        cancel: 'No',
      }).then(function () {
        var params = {};
        params.deviceId = vm.program.pk.deviceId;
        params.type = vm.currentUnit.type;
        params.action = 8;
        params.parameter1 = Number(vm.program.pk.id);
        manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
        var modalInstance = manualFactory.showLoadingBar();
        modalInstance.result.then((result) => {
          $state.go('a45programs', { unit: $state.params.unit }, { reload: 'a45programs' });
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }

    function manualDefinitiveStop() {
      var modalInstance = $uibModal.open({
        animation: true,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        template: defStopManual,
        controller: 'ModalInstanceDefStopCtrl',
        controllerAs: 'vm',
        resolve: {
          program: function () {
            return vm.program;
          },
        },
      });
      modalInstance.result.then(
        function (irrigDelay) {
          var params = {};
          params.type = vm.currentUnit.type;
          params.deviceId = vm.program.pk.deviceId;
          params.action = 9;
          params.parameter1 = Number(vm.program.pk.id);
          params.parameter2 = irrigDelay;
          var modalInstance = manualFactory.showLoadingBar();
          manualFactory.genericManualAction(params.deviceId, params).then((response) => {});

          modalInstance.result.then((result) => {
            $rootScope.$broadcast('reload', { message: 'refreshing' });
          });
        },
        function () {
          $log.info('Modal dismissed at: ' + new Date());
        },
      );
    }

    function manualDefinitiveStopFert() {
      $confirm({
        text: 'Enviar orden de paro definitivo fertilizante del programa?',
        title: 'Manual AGRONIC',
        ok: 'Si',
        cancel: 'No',
      }).then(function () {
        var params = {};
        params.deviceId = vm.program.pk.deviceId;
        params.type = vm.currentUnit.type;
        params.action = 10;
        params.parameter1 = Number(vm.program.pk.id);
        //params.parameter2 = 0;
        manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
        var modalInstance = manualFactory.showLoadingBar();
        modalInstance.result.then((result) => {
          $state.go('a45programs', { unit: $state.params.unit }, { reload: 'a45programs' });
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }

    function manualDefinitiveStopPh() {
      $confirm({
        text: 'Enviar orden de paro definitivo pH del programa?',
        title: 'Manual AGRONIC',
        ok: 'Si',
        cancel: 'No',
      }).then(function () {
        var params = {};
        params.deviceId = vm.program.pk.deviceId;
        params.type = vm.currentUnit.type;
        params.action = 10;
        params.parameter1 = Number(vm.program.pk.id);
        params.parameter2 = 1;
        manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
        var modalInstance = manualFactory.showLoadingBar();
        modalInstance.result.then((result) => {
          $state.go('a45programs', { unit: $state.params.unit }, { reload: 'a45programs' });
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }
    $scope.$watch(
      function () {
        return moment(vm.viewDate).format('MM-YYYY');
      },
      function (month) {
        vm.events = progFactory.getEventTable(vm.program, month);
      },
    );

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
      vm.previousState = null;
    });
  }
})();
