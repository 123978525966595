import { getContainer } from '@vegga/front-store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('modbusExternoController', modbusExternoController);

  modbusExternoController.$inject = ['$rootScope', '$scope', '$state', 'ModBusExterno', 'commFactory', 'unitFactory'];

  function modbusExternoController($rootScope, $scope, $state, ModBusExterno, commFactory, unitFactory) {
    var vm = this;
    var backup;
    vm.save;
    vm.cancel;

    vm.devicesFacade = getContainer().resolve('devicesFacade');
    vm.destroy$ = new Subject();
    activate();

    function activate() {
      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((currentUnit) => {
        if (!currentUnit) {
          return;
        }
        loadData(currentUnit);
      });
    }

    function loadData(currentUnit) {
      vm.currentState = $state.includes;
      vm.save = save;
      vm.cancel = cancel_edition;
      vm.saveModule = saveModule;
      vm.cancelModule = cancelModule;
      vm.currentUnit = currentUnit;
      vm.modBusExternos = ModBusExterno;
      vm.setCalibrationPointsToZero = setCalibrationPointsToZero;
      vm.openModuleWindow = openModuleWindow;
      backup = angular.copy(vm.modBusExternos);
    }

    function setCalibrationPointsToZero(element) {
      element.realPoint1 = 0;
      element.realPoint2 = 0;
      element.logicPoint1 = 0;
      element.logicPoint2 = 0;
    }

    function openModuleWindow(index) {
      vm.page = index;
      vm.opened = true;
      vm.selectedModBus = angular.copy(vm.modBusExternos[vm.page]);
      document.getElementById('vegga-modal-modbus').show();
    }

    function cancelModule() {
      vm.opened = false;
      document.getElementById('vegga-modal-modbus').hide();
    }

    function saveModule() {
      vm.modBusExternos[vm.page] = angular.copy(vm.selectedModBus);
      vm.opened = false;
      document.getElementById('vegga-modal-modbus').hide();
      //vm.form.$dirty = true;
    }

    function save() {
      vm.form.$setPristine();
      let modifiedModBusSet = new Set();

      for (let i = 0; i < vm.modBusExternos.length; i++) {
        if (!_.isEqual(vm.modBusExternos[i], backup[i])) {
          vm.modBusExternos[i].unittype = vm.currentUnit.type;
          modifiedModBusSet.add(vm.modBusExternos[i]);
        }
      }
      commFactory.updateModBusExterno(vm.currentUnit.id, [...modifiedModBusSet]).then((result) => {
        backup = angular.copy(vm.modBusExternos);
      });
    }

    function cancel_edition() {
      vm.modBus = angular.copy(backup);
    }

    $scope.$on('refresh', function (event, args) {});

    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
