(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('programsControllerA7', programsControllerA7);

  programsControllerA7.$inject = [
    '$log',
    '$rootScope',
    '$scope',
    '$state',
    'Restangular',
    '$mdDialog',
    'progFactory',
    'sectorFactory',
    '$filter',
    '$confirm',
  ];

  function programsControllerA7(
    $log,
    $rootScope,
    $scope,
    $state,
    Restangular,
    $mdDialog,
    progFactory,
    sectorFactory,
    $filter,
    $confirm,
  ) {
    var vm = this;
    var loadingState;
    vm.activeList = [];
    vm.filterList = [];
    vm.gridColumnsDef = [
      { headerName: $filter('translate')('general.programs'), field: 'name' },
      { headerName: $filter('translate')('general.start_irrigation'), field: 'irrigationHour' },
      { headerName: $filter('translate')('general.status'), field: '_state' },
    ];
    vm.newProgram;
    vm.dataGridRowClick;

    activate();

    function activate() {
      vm.currentState = $state;
      vm.changeState = changeState;
      if ($state.params.unit !== null) {
        vm.currentUnit = $state.params.unit;
        vm.program = $state.params.program;
        vm.newProgram = newProgram;
        vm.openUnoperative = openUnoperative;

        vm.dataGridRowClick = dataGridRowClick;
        vm.changeProgram = changeProgram;
        vm.changingProgram = changingProgram;
        vm.save = save;
        vm.cancel = cancel;
        vm.filterByName = filterByName;

        loadingState = false;
        loadFirstProgram($state.params.unit.id, $state.params.config);
      } else {
        $state.go('units');
      }
    }

    function dataGridRowClick(e, prog) {
      const veggaOverlay = document.querySelector('vegga-overlay');
      veggaOverlay.show();
      vm.programSelected = prog.pk.id;
      changeState({ program: prog, id: prog.pk.id }, $state.params.config);
    }

    function loadFirstProgram(id, config) {
      if (!loadingState) {
        return progFactory.programs(id, vm.currentUnit.type, true).then(function (data) {
          vm.activeList = _.orderBy(
            progFactory.activePrograms(),
            [
              'op',
              (o) => {
                return new Number(o.pk.id);
              },
            ],
            ['asc', 'asc'],
          );

          var actives = _.map(vm.activeList, (o) => {
            return Number(o.pk.id);
          });
          var comparator = _.range(1, 25);
          vm.unactiveList = _.xor(actives, comparator);
          vm.anyActiveProgram = vm.activeList.some((program) => program.op);
          filterByName();
          if (vm.program === undefined) {
            //Cargando los sectores que se analizaran en la vista de programa
            sectorFactory.allSectors(vm.currentUnit.id).then((sectors) => {
              vm.currentUnit.sectors = sectors.plain();
              // if(vm.activeList && vm.activeList.length > 0){
              //     changeState({unit:vm.currentUnit,program:vm.activeList[0],id:vm.activeList[0].pk.id},config)
              // }
            });
          }
        });
      }
    }

    function loadPrograms(id) {
      return progFactory.programs(id, vm.currentUnit.type, true).then(function (data) {
        vm.activeList = _.orderBy(
          progFactory.activePrograms(),
          [
            'op',
            (o) => {
              return new Number(o.pk.id);
            },
          ],
          ['asc', 'asc'],
        );
        var actives = _.map(vm.activeList, (o) => {
          return Number(o.pk.id);
        });
        var comparator = _.range(1, 25);
        vm.unactiveList = _.xor(actives, comparator);
      });
    }

    function changeState(params, config) {
      vm.active = null;
      vm.program = params.program;
      vm.program.op = true;
      if ($state.includes('a7programs.edit')) {
        $state.go('.', { unit: vm.currentUnit, program: vm.program, id: vm.program.pk.id });
      } else if (config) {
        $state.go('a7programs.config', { unit: vm.currentUnit, program: vm.program, id: vm.program.pk.id }, true);
      } else {
        //Cargando los sectores que se analizaran en la vista de programa
        sectorFactory.allSectors(vm.currentUnit.id).then((sectors) => {
          vm.currentUnit.sectors = sectors.plain();
          if (vm.activeList && vm.activeList.length > 0) {
            $state.go('a7programs.detail', { unit: vm.currentUnit, program: vm.program, id: vm.program.pk.id });
          }
        });
      }
    }

    function changeProgram(e) {
      if (e.detail.value) {
        vm.programSelected = e.detail.value;
        changeState(
          { program: vm.activeList.find((prog) => prog.pk.id === vm.programSelected), id: vm.programSelected },
          $state.params.config,
        );
      }
    }

    function changingProgram($event) {
      if (vm.formProgram && vm.formProgram.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.formProgram.$setPristine(); //Actualitzem estat del formulari a inicial
          this.nextOrPreviousItem($event.detail.action, $event);
        });
      }
    }

    function openUnoperative(id) {
      vm.active = id;
      progFactory.program(vm.currentUnit.id, id).then((result) => {
        result.op = false;
        vm.program = result;
        if ($state.includes('a7programs.config')) {
          $state.go('a7programs.config', { program: result, id: result.pk.id, unit: vm.currentUnit });
        } else {
          $state.go('a7programs.edit', { program: result, id: result.pk.id, unit: vm.currentUnit });
        }
      });

      //prepareModel(model,id);
    }

    function newProgram() {
      var model;
      progFactory.getModel(vm.currentUnit.type).then((item) => {
        model = item;
        prepareModel(model);
        vm.program = model;
        $state.go('a7programs.edit', { unit: vm.currentUnit, program: vm.program, id: vm.program.pk.id });
      });
    }

    function prepareModel(model) {
      var actives = _.map(vm.activeList, (o) => {
        return Number(o.pk.id);
      });
      var comparator = _.range(1, 36);
      var result = _.xor(actives, comparator);
      var newProgram;

      newProgram = _.min(result);

      model.new = true;
      model.selectorNumber = result;
      model.pk.id = newProgram;
      model.pk.deviceId = vm.currentUnit.id;
      model.name = 'Programa ' + newProgram;
      _.forEach(model.subprograms, (item) => {
        item.pk.programId = newProgram;
        item.pk.deviceId = vm.currentUnit.id;
      });
      _.forEach(model.conditioners, (item) => {
        item.pk.programId = newProgram;
        item.pk.deviceId = vm.currentUnit.id;
      });
    }

    function save(_ev) {
      if (vm.formProgram && vm.formProgram.$dirty) {
        $confirm({ text: $filter('translate')('programs.edit.saveq') }).then(() => {
          $scope.$broadcast('formSubmit');
        });
      }
    }

    function cancel() {
      if (vm.formProgram && vm.formProgram.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({
          text: $filter('translate')('programs.edit.cancelq'),
          title: $filter('translate')('programs.prog2'),
        }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.formProgram.$setPristine(); //Actualitzem estat del formulari a inicial
          document.querySelector('vegga-overlay').dismiss();

          $state.go('a7programs', { program: vm.program, unit: vm.currentUnit });
        });
      } else {
        document.querySelector('vegga-overlay').dismiss();
        $state.go('a7programs', { program: vm.program, unit: vm.currentUnit });
      }
    }

    function filterByName() {
      if (vm.searchByProgramName) {
        vm.filterList = vm.activeList.filter((program) =>
          program.name.toLowerCase().includes(vm.searchByProgramName.toLowerCase()),
        );
      } else {
        vm.filterList = vm.activeList;
      }
    }

    $scope.$on('refresh', function (event, args) {
      loadPrograms($state.params.unit.id).then(function () {
        if ($state.includes('a7programs.detail')) {
          if ($state.params.program !== undefined && vm.activeList.length > 0) {
            var program = _.filter(vm.activeList, (o) => {
              return o.pk.id === $state.params.program.pk.id;
            });
            if (program !== undefined && program.length > 0) {
              program[0].op = true;
              changeState({ program: program[0], id: program[0].pk.id });
              //$state.go($state.current,{program:program[0],id:program[0].pk.id, unit:$state.params.unit},{reload: true});
            } else if (!$state.params.program.op) {
              $state.go(
                $state.current,
                { program: $state.params.program, id: $state.params.program.pk.id, unit: $state.params.unit },
                { reload: true },
              );
            } else {
              $state.go('a7programs', { unit: $state.params.unit });
            }
            $scope.$emit('refreshed', { message: 'refreshed' });
          } else {
            $state.go(
              $state.current,
              { program: $state.params.program, id: $state.params.program.pk.id, unit: $state.params.unit },
              { reload: true },
            );
          }
        } else {
          $scope.$emit('refreshed', { message: 'refreshed' });
        }
      });
    });

    /**
     * Event listener per gestionar l'estat del formulari
     */
    $scope.$on('formUpdated', (e, args) => {
      vm.formProgram = args;
    });

    //TODO
    $scope.$on('updateEdit7', function (event, args) {
      loadPrograms($state.params.unit.id).then(function () {
        if ($state.includes('a7programs.edit') || $state.includes('a7programs.config')) {
          if ($state.params.program !== undefined && $state.params.program.op) {
            var program = _.filter(vm.activeList, (o) => {
              return o.pk.id === $state.params.program.pk.id;
            });
            if (program !== undefined && program.length > 0) {
              vm.program = program[0];
              vm.program.op = true;
              // $state.go('a7programs.detail',{program:program[0],id:program[0].pk.id, unit:$state.params.unit});
            } else {
              $state.go('a7programs', { unit: $state.params.unit });
            }
            $scope.$emit('refreshed', { message: 'refreshed' });
          }
        } else {
          $scope.$emit('refreshed', { message: 'refreshed' });
        }
      });
    });
  }
})();
