import moment from 'moment/moment';
import { Subject } from 'rxjs';
import { filter, map, mergeMap, pairwise, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { UNITS } from '../utils/units.enum';
import { getType, unitTimeFormatter } from '../utils/utils';
import { getContainer } from '@vegga/front-store';
(function () {
  'use strict';

  angular
    .module('agronicwebApp')
    //TODO - check moment
    .constant('moment', moment)
    .controller('unitsController', unitsController);

  unitsController.$inject = [
    '$rootScope',
    '$scope',
    '$state',
    'UserData',
    'userFactory',
    'unitFactory',
    'refreshFactory',
    '$filter',
    'veggaModalConfirmService',
    '$translate',
  ];

  function unitsController(
    $rootScope,
    $scope,
    $state,
    UserData,
    userFactory,
    unitFactory,
    refreshFactory,
    $filter,
    veggaModalConfirmService,
    $translate,
  ) {
    var vm = this;
    var order, ordersplit;
    vm.sort = {
      column: '',
      descending: false,
    };
    vm.units;
    vm.UNITS = UNITS;
    vm.checkStatus;
    vm.selectUnit;
    vm.openPrograms;
    vm.openSectors;
    vm.subusers = null;
    vm.unitTimeFormatter;
    vm.destroy$ = new Subject();
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    vm.showSkeleton = !vm.devicesFacade.devicesICMResponse.value;

    vm.areUnitsLoading = true;
    vm.filteredUnits = Array(10)
      .fill('')
      .map((_, i) => i);

    activate();

    vm.unitsType = [
      { id: 2, name: 'A-4000' },
      { id: 3, name: 'A-2500' },
      { id: 4, name: 'A-BIT' },
      { id: 5, name: 'A-7000' },
      { id: 6, name: 'A-5500' },
      { id: 7, name: 'A-4500' },
    ];

    vm.unitIrrigationStatusTypes = ['irrigation', 'no_irrigation', 'outService', 'systemStop', 'filterMalfunction'];

    function activate() {
      vm.checkStatus = unitFactory.checkStatus;
      vm.updateFilters = updateFilters;

      refreshFactory.getSkeletonStatus$().subscribe((isVisible) => {
        vm.showSkeleton = isVisible;
      });
      vm.currentState = $state.includes;

      order = userFactory.getOrderStorage(UserData.id);
      if (order) {
        ordersplit = _.split(order, ',');
        vm.sort.column = ordersplit[0];
        if (ordersplit[1] === 'true') {
          vm.sort.descending = true;
        } else {
          vm.sort.descending = false;
        }
      }

      vm.selectUnit = selectUnit;
      vm.showUserUnits = showUserUnits;
      if (UserData.master) {
        loadMasterUsers();
      }

      vm.devicesFacade.devicesICMResponse.value$
        .pipe(
          take(1),
          // set initial units

          tap((units) => {
            vm.areUnitsLoading = false;
            return (vm.units = vm.filteredUnits = parseUnits(units));
          }),
          // set refresh
          mergeMap(() =>
            refreshFactory.getRefresh$().pipe(
              // request units
              switchMap(() => unitFactory.getUnits(UserData.id)),
            ),
          ),
          // close subscription on destroy
          takeUntil(vm.destroy$),
        )
        .subscribe((units) => {
          vm.units = parseUnits(units);
          updateFilters();
          refreshFactory.completeRefresh();
          const layout = document.querySelector('vegga-layout-content');
          vm.viewHeight = `${layout.getBoundingClientRect().height}px`;
        });

      vm.devicesFacade.isLoading$
        .pipe(
          filter((isLoading) => !isLoading),
          take(1)
        )
        .subscribe((isLoading) => {
          vm.showSkeleton = isLoading;
        });

      vm.model = [];
      vm.unitStatus = [];
      vm.irrigationStatus = [];
      vm.serialNumber = '';
      vm.unitsFilter = { name: '', model: [], serialNumber: '', unitStatus: [], irrigationStatus: [] };
    }

    function updateFilters(value, filterType) {
      if (value && filterType) {
        vm.unitsFilter = { ...vm.unitsFilter, [filterType]: value };
      }
      vm.filteredUnits = vm.units;

      const filteredByName = filterByName(vm.filteredUnits);
      const filteredBySerialNumber = filterBySerialNumber(filteredByName);
      const filteredByType = filterByModel(filteredBySerialNumber);
      const filteredByUnitStatus = filterByUnitStatus(filteredByType);
      const filteredByIrrigationStatus = filterByIrrigationStatus(filteredByUnitStatus);
      vm.filteredUnits = filteredByIrrigationStatus;
    }

    function filterByName(units) {
      const { name } = vm.unitsFilter;
      return name.length ? units.filter((unit) => unit.name.toLowerCase().includes(name.toLowerCase())) : units;
    }
    function filterBySerialNumber(units) {
      const { serialNumber } = vm.unitsFilter;

      return serialNumber.toString().length
        ? units.filter((unit) => unit.numSerie.toString().includes(serialNumber.toString()))
        : units;
    }

    function filterByModel(units) {
      const { model } = vm.unitsFilter;

      return model.length ? units.filter((unit) => model.includes(unit.rawType)) : units;
    }

    function filterByUnitStatus(units) {
      const { unitStatus } = vm.unitsFilter;

      return unitStatus.length ? units.filter((unit) => unitStatus.includes(unit.connect)) : units;
    }

    function filterByIrrigationStatus(units) {
      const { irrigationStatus } = vm.unitsFilter;

      return irrigationStatus.length ? units.filter((unit) => irrigationStatus.includes(unit.statusLabel)) : units;
    }

    function parseUnits(units) {
      return units.map((unit) => ({
        ...unit,
        date: unitTimeFormatter($translate, unit.date),
        typeName: getType(unit.type),
        statusLabel: `${unit.status === 'ok' ? (unit.irrigation ? 'irrigation' : 'no_irrigation') : unit.status}`,
        irrigationStatus: unitFactory.getIrrigationStatus(unit),
        rawType: unit.type,
      }));
    }

    function loadMasterUsers() {
      userFactory.masterUsers(UserData.id).then((result) => {
        vm.subusers = result.plain();
      });
    }

    function selectUnit(unit) {
      if (
        $rootScope.env.FLAGS_ENABLED_REDIRECT_TO_AGRONIC &&
        unit.type !== vm.UNITS.A_2500 &&
        unit.type !== vm.UNITS.A_4000 &&
        unit.type !== vm.UNITS.A_4500
      ) {
        veggaModalConfirmService
          .showVeggaModalConfirm({
            header: $filter('translate')('units_v2.not_migrated.title'),
            content: $filter('translate')('units_v2.not_migrated.text'),
            cancelText: $filter('translate')('AUTOGENERATED.ACTIONS.ACT0003_CANCEL'),
            confirmText: $filter('translate')('units.not_migrated.actions.accept'),
          })
          .then((isConfirmed) => {
            //TODO - There is a lack of information from AgronicWeb on how users who come from here want to log in
            if (isConfirmed) {
              window.location.href = 'https://www.agronicweb.com';
            }
          });
        return;
      }

      const cb = () => $scope.$emit('selectUnit', { message: 'reload', unit });

      if (!unit.connect) {
        veggaModalConfirmService
          .showVeggaModalConfirm({
            header: $filter('translate')('units_v2.not_available.title'),
            content: $filter('translate')('units_v2.not_available.text'),
            cancelText: $filter('translate')('AUTOGENERATED.ACTIONS.ACT0003_CANCEL'),
            confirmText: $filter('translate')('general.accept'),
          })
          .then((isConfirmed) => {
            if (isConfirmed) {
              validateOperation(unit, cb);
            }
          });

        return;
      }

      validateOperation(unit, cb);
    }

    function validateOperation(obj, callback) {
      if (obj.connected) {
        callback(); // $scope.$emit('selectUnit',{message:'reload',unit:obj});
      } else {
        callback();
      }
    }

    function showUserUnits(user) {
      if (!user.remark) {
        user.remark = true;
        _.forEach(user.devices, (o, k) => {
          let tmp = _.find(vm.units, (d) => {
            return Number(k) === d.id;
          });
          if (tmp !== undefined) tmp.remark = true;
        });
      } else {
        user.remark = false;
        _.forEach(user.devices, (o, k) => {
          let tmp = _.find(vm.units, (d) => {
            return Number(k) === d.id;
          });
          if (tmp !== undefined) tmp.remark = false;
        });
      }
    }

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
