import { getContainer } from '@vegga/front-store';
import { Subject, takeUntil } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('regulationGeneralController', regulationGeneralController);

  regulationGeneralController.$inject = [
    '$state',
    '$scope',
    '$rootScope',
    'fertilizerFactory',
    'filterFactory',
    '$q',
    'unitFactory',
  ];

  function regulationGeneralController($state, $scope, $rootScope, fertilizerFactory, filterFactory, $q, unitFactory) {
    var vm = this;
    let allFertilizationHeaders, allGeneralHeader;
    vm.activeList;
    vm.getType;
    vm.save = save;
    vm.cancel = cancel_edition;
    vm.devicesFacade = getContainer().resolve('devicesFacade');

    vm.destroy$ = new Subject();

    activate();

    function activate() {
      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((currentUnit) => {
        if (!currentUnit) {
          return;
        }
        vm.currentUnit = currentUnit;

        vm.currentHeader = $state.params.headerId || '1';
        loadFertilizationHeaders();
      });
    }

    function loadFertilizationHeaders() {
      fertilizerFactory.getFertilizationHeaders(vm.currentUnit.id).then((data) => {
        allFertilizationHeaders = data.plain();
        vm.fertilizationHeader = allFertilizationHeaders[vm.currentHeader - 1];
      });
    }

    function save() {
      fertilizerFactory.updateFertilizationHeader(vm.currentUnit.id, [vm.fertilizationHeader]).then((response) => {
        vm.form.$setPristine();
        vm.fertilizationHeader = response.plain()[0];
      });
    }

    function cancel_edition() {
      vm.form.$setPristine();
    }

    $scope.$on('refresh', function (event, args) {});

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      },
    );
    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e, args) => {
      save();
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });
  }
})();
