import { UNITS } from '../utils/units.enum';
(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('sensorsFormatsController', sensorsFormatsController);

  sensorsFormatsController.$inject = ['$rootScope', '$scope', '$state', 'sensorsFactory', '$timeout', '$filter'];

  function sensorsFormatsController(
    $rootScope,
    $scope,
    $state,

    sensorsFactory,
    $timeout,
    $filter,
  ) {
    var vm = this;
    vm.activeList;
    vm.getFormatExample;
    vm.save;
    vm.cancel;

    activate();

    function activate() {
      vm.overlayEl = document.querySelector('#sensor-config-overlay');
      vm.overlayEl.show();
      vm.currentState = $state.includes;
      vm.UNITS = UNITS;
      if ($state.params.unit !== null) {
        vm.currentUnit = $state.params.unit;
        vm.getFormatExample = getFormatExample;
        vm.getFormatExample4500 = getFormatExample4500;
        vm.cancel = cancel_edition;
        vm.formatChanged = formatChanged;
        vm.filterUnidadesByIdMagnitude = filterUnidadesByIdMagnitude;
        vm.save = save;
        vm.resetUnidades = resetUnidades;
        vm.setFormatMinMax = setFormatMinMax;

        vm.forms = [];
        vm.gridColumnsDef = [
          { headerName: $filter('translate')('sensors.s34'), field: '' },
          { headerName: $filter('translate')('sensors.s1'), field: '' },
          { headerName: $filter('translate')('sensors.s35'), field: '' },
          { headerName: $filter('translate')('sensors.s36'), field: '' },
          { headerName: $filter('translate')('sensors.s37'), field: '' },
          { headerName: $filter('translate')('sensors.s38'), field: '' },
          { headerName: $filter('translate')('sensors.s39'), field: '' },
          { headerName: $filter('translate')('sensors.s40'), field: '' },
        ];
        vm.subGridColumnsDef = [
          { headerName: $filter('translate')('sensors.s42'), field: '' },
          { headerName: $filter('translate')('sensors.s42'), field: '' },
          { headerName: $filter('translate')('sensors.s42'), field: '' },
          { headerName: $filter('translate')('sensors.s42'), field: '' },
        ];

        backup();
        switch (vm.currentUnit.type) {
          case UNITS.A_4000:
            vm.max = 26;
            break;
          case UNITS.A_4500:
            //get formats to currentUnit formats
            sensorsFactory.analogformatsViewAll(vm.currentUnit.id).then((data) => {
              vm.formats = data
                .plain()
                .map((element) => ({ ...element, units: element.format ?? element.format.split(' ')[1] }));
            });

            getAllMagnitudes(vm.currentUnit.id);
            getAllUnidades(vm.currentUnit.id);
          default:
            vm.max = 31;
        }

        vm.customUnidades = new Array(14).fill('').map((_, i) => {
          return {
            deviceId: vm.currentUnit.id,
            id: i,
            name: '',
            symbol: '',
          };
        });
      } else {
        $state.go('units');
      }
    }

    function getAllMagnitudes(deviceId) {
      sensorsFactory.magnitudeAll(deviceId).then((data) => {
        vm.magnitudes = data.plain();
      });
    }

    function getAllUnidades(deviceId) {
      sensorsFactory.unidadesAll(deviceId).then((data) => {
        vm.unidades = data.plain();
        getAllCustomUnidades(deviceId);
      });
    }

    function getAllCustomUnidades(deviceId) {
      sensorsFactory.customUnidadesAll(deviceId).then((data) => {
        let result = data.plain().map((elem, i) => {
          elem.idMagnitud = 26;
          vm.customUnidades[i] = { ...elem };
          return elem;
        });

        addCustomUnidadesToUnidadesList(result);
      });
    }

    function addCustomUnidadesToUnidadesList(customUnidades) {
      customUnidades.forEach((customUnidad) => {
        vm.unidades.push(customUnidad);
      });
    }

    function filterUnidadesByIdMagnitude(idMagnitud) {
      return function (unidad) {
        return unidad.idMagnitud == idMagnitud;
      };
    }

    function getFormatExample(format) {
      var example = '';
      if (format.sign) {
        example = example + '\u00B1 ';
      }

      _.times(format.integers, (o) => {
        example = example + '0';
      });
      if (format.decimals > 0) {
        example = example + '.';
        _.times(format.decimals, (o) => {
          example = example + '0';
        });
      }
      if (vm.currentUnit.type == 7) {
        example = example + ' ' + vm.symbol;
        format.format = example;
      } else {
        example = example + ' ' + format.units;
      }

      return example;
    }

    function getFormatExample4500(format) {
      if (vm.currentUnit.type === vm.UNITS.A_4500) {
        getFormatUnidad(format.idMagnitud, format.idUnidad).then((symbol) => {
          vm.symbol = symbol;
          getFormatExample(format);
        });
      }
    }

    async function getFormatUnidad(magnitudeId, unidadId) {
      let promise = null;

      if (magnitudeId == 26) {
        promise = new Promise((resolve) =>
          resolve(sensorsFactory.customUnidad45ById(vm.currentUnit.id, unidadId).then((unidad) => unidad.symbol)),
        );
        return await promise;
      } else {
        promise = new Promise((resolve) =>
          resolve(
            sensorsFactory.unidad45ByMagAndId(vm.currentUnit.id, unidadId, magnitudeId).then((unidad) => unidad.symbol),
          ),
        );
        return await promise;
      }
    }

    function formatCustomUnits(unitsToFormat) {
      var formatted = [];
      var id = 1;

      unitsToFormat.forEach((format) => {
        //console.log(format.name.$$rawModelValue);
        formatted.push({
          deviceId: vm.currentUnit.id,
          id: format.id.$$rawModelValue,
          name: format.name.$$rawModelValue,
          symbol: format.symbol.$$rawModelValue,
          unittype: vm.UNITS.A_4500,
          modified: 1,
          modifiedBy: 0,
        });
        id += 1;
      });

      return formatted;
    }

    function resetUnidades(format) {
      format.idUnidad = 1;
      setFormatMinMax(format);
    }

    function setFormatMinMax(format) {
      let totalDigits = Number(format.integers) + Number(format.decimals);

      if (totalDigits >= 5) {
        format.max = (32767 / 10 ** Number(format.decimals)).toFixed(Number(format.deciamls));
        format.min = '0';

        let formatExample = '';
        if (format.sign) {
          formatExample = '±';
          format.min = (-32768 / 10 ** Number(format.decimals)).toFixed(Number(format.decimals));
        }

        for (let i = 0; i < totalDigits; i++) {
          formatExample += '0';
          if (i === Number(format.integers) - 1) {
            formatExample += '.';
          }
        }
        let unitFormat = vm.unidades.filter((unit) => {
          return unit.idMagnitud === format.idMagnitud && unit.id === format.idUnidad;
        })[0];
        format.format = formatExample + ' ' + unitFormat.symbol;
      } else {
        let max = '';
        let min = 0;
        for (let i = 0; i < totalDigits; i++) {
          max += '9';
        }

        max = Number((max / 10 ** format.decimals).toFixed(format.decimals));
        format.format = (' ' + max).replace(/9/g, '0');

        if (format.sign) {
          format.format = '±' + format.format;
          min = -max;
        }
        format.min = min;
        format.max = max;
        let unitFormat = vm.unidades.filter((unit) => {
          return unit.idMagnitud === format.idMagnitud && unit.id === format.idUnidad;
        })[0];
        format.format = format.format + ' ' + unitFormat.symbol;
        //getFormatExample4500(format);
      }
    }

    function save(_ev, form) {
      if (vm.currentUnit.type === vm.UNITS.A_4500) {
        if ($state.includes('sensors.formats')) {
          let formats = [];
          for (let i = 0; i < 31; i++) {
            if (form[i].$dirty) {
              vm.formats[i].unittype = vm.currentUnit.type;
              formats.push(vm.formats[i]);
            }
          }

          sensorsFactory.updateFormats45(vm.currentUnit.id, formats).then(() => {
            vm.form_f.$setPristine();
          });
        } else if ($state.includes('sensors.customFormats')) {
          var customUnits = formatCustomUnits(form);

          sensorsFactory.updateCustomUnidad45(vm.currentUnit.id, customUnits);
        }
      } else {
        vm.form_f.$setPristine();
        var formats = [];
        formats = vm.currentUnit.formats;
        sensorsFactory.updateFormats(vm.currentUnit.id, formats);
      }
    }

    function formatChanged(f) {
      f.prepared = true;
      $timeout(() => {
        f.prepared = false;
      }, 100);
    }

    function backup() {
      vm.backup = [];
      angular.copy(vm.currentUnit.formats, vm.backup);
    }

    function cancel_edition() {
      angular.copy(vm.backup, vm.currentUnit.formats);
    }

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form_f,
      () => {
        $scope.$emit('formUpdated', vm.form_f);
      },
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e, args) => {
      save(e, vm.forms);
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });
  }
})();
