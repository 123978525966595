import { getContainer } from '@vegga/front-store';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { UNITS } from '../utils/units.enum';

(function () {
  'use strict';

  angular.module('agronicwebApp').controller('fertilizersOverlayController', fertilizersOverlayController);

  fertilizersOverlayController.$inject = [
    '$state',
    '$rootScope',
    '$scope',
    '$filter',
    'unitFactory',
    'veggaModalConfirmService',
    '$transition$',
  ];
  function fertilizersOverlayController(
    $state,
    $rootScope,
    $scope,
    $filter,
    unitFactory,
    veggaModalConfirmService,
    $transition$
  ) {
    var vm = this;
    vm.UNITS = UNITS;
    vm.tabState = 'general';
    vm.overlayLoader$ = new Subject();
    vm.dismissOverlay = dismissOverlay;
    vm.saveFormChanges = saveFormChanges;
    vm.devicesFacade = getContainer().resolve('devicesFacade');

    activate();

    function activate() {
      vm.formFertilizer = {};
      vm.destroy$ = new Subject();

      // cached response, used when user is navigating from /fertilizers
      vm.devicesFacade = getContainer().resolve('devicesFacade');
      const unitReq$ = vm.devicesFacade.legacyUnitResponse.value$;
      // const stateParams = $transition$.router.globals.params;

      unitReq$
        .pipe(
          tap((unit) => (vm.currentUnit = unit)),
          takeUntil(vm.destroy$)
        )
        .subscribe(() => {
          showOverlay();
        });

      const [_, state] = $state.current.name.split('.');
      vm.activeTab = state;
    }

    function saveFormChanges() {
      $rootScope.$broadcast('formSaved');
    }

    function dismissOverlay(ev) {
      if (!vm.formFertilizer.$dirty) {
        $state.go('fertilizers');
        return;
      }

      ev && ev.preventDefault();
      openVeggaConfirmModal().then((isConfirmed) => {
        if (isConfirmed) {
          $state.go('fertilizers');
        }
      });
    }

    function showOverlay() {
      const overlay = document.querySelector('#fertilizersOverlay');

      // prevent from showing overlay again while refreshing
      // with overlay shown
      if (overlay.toggle !== 'show') {
        overlay.show();
      }
    }

    function openVeggaConfirmModal() {
      return veggaModalConfirmService.showVeggaModalConfirm({
        header: $filter('translate')('fert.fertconf'),
        content: $filter('translate')('programs.edit.cancelq'),
        cancelText: $filter('translate')('general.cancel'),
        confirmText: $filter('translate')('general.accept'),
      });
    }

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });

    $rootScope.$on('formUpdated', (_, form) => {
      vm.formFertilizer = form;
    });

    $rootScope.$on('showSkeleton', (ev, data) => {
      vm.showSkeleton = data;
      vm.overlayLoader$.next(!data);
    });
  }
})();
