import { getContainer } from '@vegga/front-store';
import { UNITS } from '../../../utils/units.enum';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('m120Controller', m120Controller);

  m120Controller.$inject = ['$scope', '$state', 'commFactory', 'Module', 'unitFactory'];

  function m120Controller($scope, $state, commFactory, Module, unitFactory) {
    var vm = this;
    var backup;
    vm.attemptsAM120;
    vm.destroy$ = new Subject();
    vm.devicesFacade = getContainer().resolve('devicesFacade');

    function activate() {
      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((currentUnit) => {
        if (!currentUnit) {
          return;
        }
        loadData(currentUnit);
      });
    }

    activate();

    function loadData(currentUnit) {
      vm.currentState = $state.includes;
      vm.UNITS = UNITS;

      vm.currentUnit = currentUnit;
      if (vm.currentUnit.type !== vm.UNITS.A_4500) {
        vm.module = Module;
        vm.attemptsAM120 = angular.copy(vm.module.attemptsAM120);
      }

      loadLinkBox();

      vm.groups = _.range(1, 13);
      vm.modBusList = {
        0: '0',
        1: '001',
        2: '002',
        3: '003',
        4: '004',
        5: '005',
        6: '006',
        7: '007',
        100: '100',
        101: '101',
        102: '102',
        103: '103',
        104: '104',
        105: '105',
        106: '106',
        107: '107',
      };
      vm.actionList = { 0: 'Ninguna', 1: 'Cerrar válvulas' };

      vm.save = save;
      vm.cancel = cancel_edition;
    }

    function loadLinkBox() {
      if (vm.currentUnit.type !== vm.UNITS.A_4500) {
        commFactory.linkBox(vm.currentUnit.id, 1).then((result) => {
          vm.linkBox = result[0];
          backup = angular.copy(vm.linkBox);
        });
      } else {
        commFactory.linkBox(vm.currentUnit.id, 1).then((result) => {
          vm.linkBoxes = result.plain();
          backup = angular.copy(vm.linkBox);
        });
      }
    }

    function saveA4500() {
      for (let linkBox of vm.linkBoxes) {
        linkBox.unittype = vm.currentUnit.type;
        commFactory.updateLinkBox(vm.currentUnit.id, linkBox).then((result) => {
          vm.form.$dirty = false;
        });
      }
    }

    function save() {
      if (vm.currentUnit.type === vm.UNITS.A_4500) {
        saveA4500();
      } else {
        vm.linkBox.unittype = vm.currentUnit.type;
        vm.loading = true;
        vm.module.linkBoxes = [vm.linkBox];
        vm.module.attemptsAM120 = vm.attemptsAM120;
        commFactory.updateModule(vm.currentUnit.id, vm.module).then((result) => {
          vm.module = result;
          vm.attemptsAM120 = angular.copy(vm.module.attemptsAM120);
          vm.linkBox = vm.module.linkBoxes[0];
          backup = angular.copy(vm.linkBox);
          vm.loading = false;
          vm.form.$dirty = false;
        });
      }
    }

    function cancel_edition() {
      vm.linkBox = angular.copy(backup);
    }

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e, args) => {
      save();
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
