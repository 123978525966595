import { UNITS } from '../utils/units.enum';
import { getContainer } from '@vegga/front-store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('moduleMapController', moduleMapController);

  moduleMapController.$inject = [
    '$log',
    '$rootScope',
    '$scope',
    'Restangular',
    '$state',
    'mapsFactory',
    '$filter',
    'commFactory',
    '$q',
    'manualFactory',
    '$confirm',
    '$timeout',
    '$anchorScroll',
    'unitFactory',
  ];

  function moduleMapController(
    $log,
    $rootScope,
    $scope,
    Restangular,
    $state,
    mapsFactory,
    $filter,
    commFactory,
    $q,
    manualFactory,
    $confirm,
    $timeout,
    $anchorScroll,
    unitFactory,
  ) {
    var vm = $scope.$parent.vm;
    var layerLoaded;
    var currentMarker;
    var conectionLines;
    vm.module;
    vm.destroy$ = new Subject();

    const types = {
      1: { type: 'Monocable', name: 'AM120', cod: 'EAM', mod: 'MAM' },
      2: { type: 'Radio', name: 'AR868-16', cod: 'EAR', mod: 'MAR' },
      3: { type: 'Radio', name: 'AR24', cod: 'EAR', mod: 'MAR' },
      4: { type: 'Radio', name: 'AR433', cod: 'EAR', mod: 'MAR' },
      5: { type: 'Radio', name: 'MI', cod: 'MI' },
      6: { type: 'Radio', name: 'ME', cod: 'ME' },
      7: { type: 'Radio', name: 'R868', cod: 'EAR', mod: 'MAR' },
      8: { type: 'Radio', name: 'AgroBee', cod: 'Coordinador', mod: 'Módulo' },
      9: { type: 'Radio', name: 'AgroBeeL', cod: 'Coordinador', mod: 'Módulo' },
      10: { type: 'Radio', name: 'SDI12', cod: '', mod: 'Dispositivo' },
    };

    vm.agroBeeTypes = {};

    activate();

    /*******************/

    function activate() {
      
      vm.mapsFacade = getContainer().resolve('mapsFacade');
      vm.mapsFacade.markers$.subscribe((markers) => {
        vm.modulesInMap = markers.filter((item) => item.type === 1);

        if (vm.filter.comp.active && vm.filter.comp.modules) loadModulesLayer(vm.modulesInMap);
      });

      vm.actionView = 0; //Default view INFO, 1 = Manual, 2 = Edition area
      vm.selectEquipo = false;
      vm.equipoInfo = false;
      vm.UNITS = UNITS;
      vm.addMarkerM = addMarker;
      vm.returnType = returnType;
      vm.loadLinkBoxes = loadLinkBoxes;
      vm.loadModules = loadModules;
      layerLoaded = false;
      vm.areModulesLoading = false;
    }

    function loadModulesLayer(markers) {
      if (!markers) return;
      if (!vm.map || (!layerLoaded && vm.map?.id !== undefined)) {
        layerLoaded = true;
        loadMarkersToLayer(markers);
        vm.map.addLayer(vm.modulesLayer);
      } else {
        vm.map.addLayer(vm.modulesLayer);
      }
    }

    function loadMarkersToLayer(markers) {
      if (!markers) return;
      vm.modulesInMap = markers;
      // vm.modulesLayer.clearLayers();
      let device, type, unit;
      const requests = [];
      const linkBoxEmList = [];

      _.forEach(markers, (markerInfo) => {
        var load = false;
        if (markerInfo.deviceId !== device) {
          device = markerInfo.deviceId;
          load = true;
        }
        if (markerInfo.prop2 !== type) {
          type = markerInfo.prop2;
          load = true;
        }

        if (linkBoxEmList.find((link) => link.deviceId === markerInfo.deviceId && link.prop2 === markerInfo.prop2)) {
          load = false;
        }

        linkBoxEmList.push(markerInfo);

        var func = commFactory.linkBoxEM(device, type);
        requests.push(func);
        // if (load) {

        // } else {
        //   requests.push({});
        // }
      });

      $q.all(requests).then((responses) => {
        vm.modulesLayer.clearLayers();
        _.forEach(responses, (resp, key) => {
          const response = _.first(resp);

          if (response) {
            vm.linkbox = response;
          }

          const linkboxUnit = vm.units.find((unit) => unit.id === markers[key].deviceId);
          if (markers[key].prop1 === '0') {
            unit = vm.linkbox;
          } else {
            if (linkboxUnit.type === UNITS.A_4500) {
              const { externalModulesLora, externalModulesMAM120, externalModulesMAR433, externalModules } = vm.linkbox;
              const modules = externalModulesLora || externalModulesMAM120 || externalModulesMAR433 || externalModules;

              unit = modules.find((mod) => mod.pk.id === +markers[key].prop3);
            } else {
              unit = _.filter(vm.linkbox.externalModules, (obj) => {
                return obj.pk.id === Number(markers[key].prop3);
              })[0];
            }
          }

          if (!_.isEmpty(unit)) {
            var marker = L.marker(new L.LatLng(markers[key].lat, markers[key].lng));
            // vm.moduleType = Number(markers[key].prop1);
            var className = getModuleStateClass(unit);
            marker.setIcon(createEquipoIcon(className));
            marker.properties = {};
            marker.properties.markerId = unit.id;
            marker.properties.id = markers[key].id;
            marker.properties.deviceid = markers[key].deviceId;
            marker.properties.type = markers[key].prop1;
            marker.properties.moduleType = markers[key].prop2;
            marker.properties.moduleId = markers[key].prop3;
            marker.properties.module = unit;
            if (markers[key].prop1 !== '0') {
              var obj = vm.linkbox;
              marker.properties.module.linkbox = obj;
            }
            const type = _.get(unit, 'pk.type');

            const module = types[type] || {};
            const i = vm.modulesInMap.findIndex((modInMap) => modInMap.id === marker.properties.id);
            _.assign(vm.modulesInMap[i], { name: `${module.cod} ${module.type} ${module.name}` });
            vm.modulesLayer.addLayer(marker);
          }
        });
      });

      vm.modulesLayer.on({ click: showModuleInfo });
    }

    function getModuleStateClass(module) {
      var type = '';
      switch (module.pk.type) {
        case 1: //Monocable
          type = module.linkBoxId === undefined ? 'eam' : 'mam';
          break;
        case 2: //Radio
        case 3:
        case 4:
        case 7:
          type = module.linkBoxId === undefined ? 'ear' : 'mar';
          break;
        case 8: //AgroBee
          type = module.linkBoxId === undefined ? 'agrocoor' : module.type === 1 ? 'agrorep' : 'agro';
          break;
        case 9: //AgroBeeL
          type = module.linkBoxId === undefined ? 'agrocoor' : module.type === 1 ? 'agrorep' : 'agro';
          break;
      }
      type = type + ' ';
      if (module.linkBoxId === undefined) {
        switch (module.pk.type) {
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
          case 6:
          case 7:
            if (module.xCommunicate) {
              if (module.pk.type === 7) {
                if (module.port !== null && module.port !== 0) {
                  module.state = 'comm';
                  type = type + 'con';
                } else {
                  module.state = 'noconf';
                  type = type + 'nocon';
                }
              } else {
                module.state = 'comm';
                type = type + 'con';
              }
            } else if (module.active && !module.xCommunicate) {
              module.state = 'error';
              type = type + 'averia';
            } else {
              module.state = 'nocomm';
              type = type + 'nocon';
            }
            break;
          case 8:
          case 9:
            switch (module.xState) {
              case 0:
                module.state = 'noconf';
                type = type + 'nocon';
                break;
              case 1:
                module.state = 'nocomm';
                type = type + 'nocon';
                break;
              case 2:
                module.state = 'comm';
                type = type + 'con';
                break;
              case 3:
                module.state = 'error';
                type = type + 'averia';
                break;
            }
            break;
        }
      } else {
        switch (module.pk.type) {
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
          case 6:
          case 7:
            if (module.xState === 1) {
              module.state = 'comm';
              type = type + 'con';
            } else {
              module.state = 'nocomm';
              type = type + 'nocon';
            }
            break;
          case 8:
          case 9:
            if (module.xState === 1 || module.xState === 0) {
              if (module.xState === 1) module.state = 'nocomm';
              if (module.xState === 0) module.state = 'noconf';
              type = type + 'nocon';
            } else {
              module.state = 'comm';
              type = type + 'con';
            }
            break;
        }
      }
      return type;
    }

    function showModuleInfo(e) {
      var unit = e.layer.properties.module;

      if (!_.isEmpty(unit)) {
        vm.module = unit;
        let currentModuleUnit = vm.units.filter((u) => {
          return +u.id === +vm.module.pk.deviceId;
        });
        if (currentModuleUnit.length > 0) {
          vm.module.link = vm.module.linkBoxId === undefined;
          if (vm.module.link) {
            conectToModules(e.layer);
            if (vm.module.pk.type !== 8) {
              if (currentModuleUnit[0].type !== UNITS.A_4500) {
                vm.module.activeModules = vm.module.externalModules.filter((em) => em.serialNumber).length;
              } else {
                let externalModules =
                  vm.module.externalModulesLora || vm.module.externalModulesMAM120 || vm.module.externalModulesMAR433;

                if (externalModules) {
                  switch (vm.module.pk.type) {
                    case 1:
                    case 4:
                      vm.module.activeModules = externalModules.filter((em) => em.active).length;
                      break;
                    case 9:
                      vm.module.activeModules = externalModules.filter((em) => em.serialNumber).length;
                      break;

                    default:
                      break;
                  }
                }
              }
            } else {
              if (currentModuleUnit[0].type !== UNITS.A_4500) {
                vm.module.activeModules = vm.module.externalModules.filter((obj) => {
                  switch (obj.xState) {
                    case 0:
                      return false;
                    case 1:
                    case 2:
                    case 3:
                      return true;
                    default:
                      return false;
                  }
                }).length;
              } else {
                vm.module.activeModules = vm.module.externalModulesLora.filter((mod) => {
                  switch (mod.xState) {
                    case 0:
                      return false;
                    case 1:
                    case 2:
                    case 3:
                      return true;
                    default:
                      return false;
                  }
                }).length;
              }
            }
          } else {
            getModuleStateClass(vm.module.linkbox);
            conectToLinkBox(e.layer);
          }

          currentMarker = e.layer;
          $timeout(() => {
            vm.map.invalidateSize();
            vm.map.flyTo(e.target.getBounds().getCenter());
          }, 200);

          $scope.$emit('editSectionChange', {
            type: 2,
            elementsInMap: vm.modulesInMap,
            value: _.get(e, 'layer.properties.id'),
            types: types,
          });
          vm.overlay.show();
          $state.go('maps.module', { module: vm.module, unit: _.first(currentModuleUnit) }, { reload: 'maps.module' });
        }
      }
    }

    function conectToLinkBox(marker) {
      var parentType = marker.properties.module.pk.type;
      var parentId = marker.properties.module.linkBoxId;
      var parentDeviceId = marker.properties.module.pk.deviceId;
      var moduleCoords = [marker._latlng.lat, marker._latlng.lng];
      var draw = false;
      var stateColors = {
        comm: '#00A99D',
        nocomm: '#ffffff',
        noconf: '#ffffff',
        error: 'var(--vegga-color-error)',
      };
      if (conectionLines !== undefined) vm.map.removeLayer(conectionLines);
      conectionLines = L.featureGroup();
      _.forEach(vm.modulesLayer.getLayers(), (m) => {
        if (
          m.properties.module.pk.deviceId === parentDeviceId &&
          m.properties.module.pk.type === parentType &&
          m.properties.module.pk.id === parentId &&
          m.properties.module.externalModules
        ) {
          draw = true;
          var line = L.polyline([moduleCoords, [m._latlng.lat, m._latlng.lng]], {
            color: stateColors[marker.properties.module.state],
            dashArray: '5 8',
            smoothFactor: 0.5,
          });
          conectionLines.addLayer(line);
        }
      });
      if (draw) {
        conectionLines.addTo(vm.map);
      }
    }

    function conectToModules(marker) {
      var parentId = marker.properties.module.pk.id;
      var parentType = marker.properties.module.pk.type;
      var parentDeviceId = marker.properties.module.pk.deviceId;
      var parentCoords = [marker._latlng.lat, marker._latlng.lng];
      var coords = [];
      var draw = false;
      var stateColors = {
        comm: '#00A99D',
        nocomm: '#ffffff',
        noconf: '#ffffff',
        error: 'var(--vegga-color-error)',
      };
      if (conectionLines !== undefined) vm.map.removeLayer(conectionLines);
      conectionLines = L.featureGroup();
      _.forEach(vm.modulesLayer.getLayers(), (m) => {
        if (
          m.properties.module.pk.deviceId === parentDeviceId &&
          m.properties.module.pk.type === parentType &&
          m.properties.module.linkBoxId === parentId
        ) {
          draw = true;
          var line = L.polyline([parentCoords, [m._latlng.lat, m._latlng.lng]], {
            color: stateColors[m.properties.module.state],
            dashArray: '5 8',
            smoothFactor: 0.5,
          });
          conectionLines.addLayer(line);
        }
      });
      if (draw) {
        conectionLines.addTo(vm.map);
      }
    }

    function saveMarker(latLng) {
      var params = {};
      params.lat = latLng.lat;
      params.lng = latLng.lng;
      params.mapId = vm.map.id;
      params.deviceId = vm.unit.id;
      params.userId = $rootScope.user.id;
      params.type = 1;
      params.prop1 = vm.moduleType;
      params.prop2 = vm.moduleType === 0 ? vm.linkboxToAdd.pk.type : vm.moduleAdding.pk.type;
      params.prop3 = vm.moduleType === 0 ? vm.linkboxToAdd.pk.id : vm.moduleAdding.pk.id;

      return mapsFactory.saveMarker(vm.map.id, params);
    }

    function addMarker() {
      const { layer, latlng } = vm.props;
      var className = getModuleStateClass(vm.moduleType === 0 ? vm.linkboxToAdd : vm.moduleAdding);
      layer.setIcon(createEquipoIcon(className));

      saveMarker(latlng).then((resp) => {
        layer.properties = {};
        layer.properties.id = resp.id;
        layer.properties.deviceid = resp.deviceId;
        layer.properties.type = resp.prop1;
        layer.properties.moduleType = resp.prop2;
        layer.properties.moduleId = resp.prop3;
        layer.properties.type = resp.prop1;
        layer.properties.moduleType = resp.prop2;
        layer.properties.moduleId = resp.prop3;
        layer.properties.module = vm.moduleType === 0 ? vm.linkboxToAdd : vm.moduleAdding;
        if (vm.moduleType !== 0) {
          var obj = vm.linkboxToAdd;
          layer.properties.module.linkbox = obj;
        }
        vm.mapsFacade.addMarker(resp);
        $scope.$emit('completedSave');
        reloadModules();
        vm.modulesLayer.addLayer(layer);
        layer.toggleEdit();
        vm.map.off('editable:drawing:commit');
      });
    }

    function initAddMarker() {
      vm.filter.comp.module = true;
      vm.filter.comp.active = true;
    }

    var createEquipoIcon = function (labelClass) {
      var content = '<div class="map-module-marker ' + labelClass + '"></div>';
      return L.divIcon({
        className: 'custom-marker',
        html: content,
      });
    };

    function closeModuleInfo() {
      $anchorScroll();
      $timeout(() => {
        vm.map.invalidateSize();
      }, 200);
    }

    function checkIrrigation(device) {
      var deferred = $q.defer();
      switch (device.type) {
        case 2: //A4000
        case 3: //A2500
          if (device.status == 'ok') {
            if (device.sectors !== undefined) {
              var irrig = _.filter(device.sectors, (sector) => {
                return sector.xStatus & (1 === 1);
              });
              device.irrigation = irrig.length > 0;
              extractIrrigationSectors(device, irrig);
            } else {
              sectorFactory.allSectors(device.id).then(
                function (data) {
                  device.sectors = data.plain();
                  var irrig = _.filter(device.sectors, (sector) => {
                    return sector.xStatus & (1 === 1);
                  });
                  device.irrigation = irrig.length > 0;
                  extractIrrigationSectors(device, irrig);
                },
                function (error) {},
              );
            }
            progFactory.programs(device.id, device.type, true).then(function (data) {
              var activeList = progFactory.activePrograms();

              device.nprogr = activeList.length;
              return deferred.resolve('');
            });
          } else {
            return deferred.resolve('');
          }
          break;
      }
      return deferred.promise;
    }

    function returnType(type) {
      switch (type) {
        case 2:
          return 'Agrónic 4000';
        case 3:
          return 'Agrónic 2500';
        case 4:
          return 'Agrónic BIT';
        case 5:
          return 'Agrónic 7000';
        case 6:
          return 'Agrónic 5500';
      }
    }

    function deleteModuleMarker() {
      mapsFactory.deleteMarker(vm.map.id, currentMarker.properties.id).then((resp) => {
        closeModuleInfo();
        reloadModules();
        vm.mapsFacade.deleteMarker(currentMarker.properties.id);
        vm.modulesLayer.removeLayer(currentMarker);
      });
    }

    function loadLinkBoxes() {
      vm.areModulesLoading = true;
      vm.moduleAdding = undefined;
      vm.moduleType = undefined;
      vm.linkbox = undefined;
      vm.linkboxToAdd = undefined;
      vm.linkBoxes = undefined;
      commFactory.linkBox(vm.unit.id).then((list) => {
        vm.linkBoxes = list.plain();
        vm.areModulesLoading = false;
      });
    }

    function loadModules() {
      vm.moduleAdding = undefined;
      vm.areModulesLoading = true;
      if (vm.moduleType === 1 || vm.moduleType === 0) {
        commFactory.linkBoxEM(vm.unit.id, vm.linkboxToAdd.pk.type).then((response) => {
          if (vm.unit.type === UNITS.A_4500) {
            if (response.plain()) {
              const module = response.plain().find((mod) => mod.pk.id === vm.linkboxToAdd.pk.id);

              vm.modules =
                module.externalModulesLora ||
                module.externalModulesMAM120 ||
                module.externalModulesMAR433 ||
                module.externalModules;

              vm.areModulesLoading = false;
            }
          } else {
            if (response[0]) vm.modules = response[0].externalModules;
            vm.areModulesLoading = false;
          }
        });
      }
    }

    function reloadModules() {
      layerLoaded = false;
    }

    $scope.$on('loadModulesLayer', () => {
      if (vm.filter.comp.active && vm.filter.comp.modules) loadModulesLayer(vm.modulesInMap);
    });

    $scope.$on('deleteModule', () => {
      deleteModuleMarker();
      closeModuleInfo();
    });

    $scope.$on('closeModule', () => {
      closeModuleInfo();
    });

    $scope.$on('reloadMap', () => {
      layerLoaded = false;
    });

    $scope.$on('addModuleMarker', (e, args) => {
      vm.props = args;
      initAddMarker(args);
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
