import { getContainer } from '@vegga/front-store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import moment from 'moment/moment';
import { UNITS } from '../../utils/units.enum';
import { exportToDocument } from '@vegga/front-utils';

(function () {
  'use strict';

  angular.module('agronicwebApp').controller('historyFertDepController', historyFertDepController);

  historyFertDepController.$inject = [
    '$scope',
    '$state',
    '$filter',
    'UserData',
    'historyFactory',
    'unitFactory',
    'historyUtilsFactory',
    '$rootScope',
  ];

  function historyFertDepController(
    $scope,
    $state,
    $filter,
    UserData,
    historyFactory,
    unitFactory,
    historyUtilsFactory,
    $rootScope,
  ) {
    var vm = this;
    vm.activeList;
    vm.filter = {};
    vm.pagination;

    vm.UNITS = UNITS;
    vm.destroy$ = new Subject();

    vm.currentPage = 1;
    vm.itemsPerPage = 15;
    vm.totalData;
    vm.devicesFacade = getContainer().resolve('devicesFacade');

    activate();

    function activate() {
      vm.currentState = $state.includes;

      vm.from = historyUtilsFactory.from;
      vm.to = historyUtilsFactory.to;
      vm.minDateValue = historyUtilsFactory.getMinDate(UserData.profesional);
      vm.maxDateValue = historyUtilsFactory.maxDateValue;
      vm.updateRangeDates = updateRangeDates;
      vm.loadHistory = loadHistory;

      vm.pageChangeHandler = pageChangeHandler;
      vm.pageSizeChangeHandler = pageSizeChangeHandler;

      vm.exportXLS = exportXLS;

      vm.groupBy = historyUtilsFactory.getGroupBy();

      vm.sectorListDTO = [
        { text: $filter('translate')('sensors.all'), value: '0' },
        { text: '1', value: '1' },
        { text: '2', value: '2' },
        { text: '3', value: '3' },
        { text: '4', value: '4' },
      ];
      vm.filter.groupBy = '2';
      vm.filter.sector = '0';

      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((currentUnit) => {
        if (!currentUnit) {
          return;
        }
        loadData(currentUnit);
      });
    }

    function loadData(currentUnit) {
      vm.currentUnit = currentUnit;
      getColumnDefinition(vm.currentUnit.type);
      loadHistory();
    }

    function getColumnDefinition(type) {
      switch (type) {
        case UNITS.A_4500:
          return (vm.gridColumnsDef = [
            {
              headerName: $filter('translate')('history.fromto'),
              field: 'from',
              width: 400,
            },
            { headerName: $filter('translate')('sectors.header'), field: 'header' },
            { headerName: insertTitle(1), field: 'lvlTank1' },
            { headerName: insertTitle(2), field: 'lvlTank2' },
            { headerName: insertTitle(3), field: 'lvlTank3' },
            { headerName: insertTitle(4), field: 'lvlTank4' },
            { headerName: insertTitle(5), field: 'lvlTank5' },
            { headerName: insertTitle(6), field: 'lvlTank6' },
            { headerName: insertTitle(7), field: 'lvlTank7' },
            { headerName: insertTitle(8), field: 'lvlTank8' },
          ]);
      }
    }

    function checkDates(i) {
      return i[2] + ' - ' + i[3];
    }
    function insertTitle(row) {
      return $filter('translate')('history.lvltank') + ' ' + row + ' (L)';
    }

    function updateRangeDates(e) {
      const date = e.detail;

      const [start, end] = date;

      vm.from = start;
      vm.to = end;
      loadHistory();
    }

    function loadHistory(exporting) {
      var params = {
        //TODO: We have to add 1 day maybe becaue in the backend the check is "lower than".
        from: vm.from,
        to: moment(vm.to, 'DD-MM-YYYY').add(1, 'd').format('DD-MM-YYYY'),
        filter: vm.filter,
        page: vm.currentPage,
        limit: vm.itemsPerPage,
      };

      if (exporting) {
        $rootScope.$emit('preventSkeleton', true);
        params = { ...params, limit: vm.totalData ? vm.totalData : 1 };
      }

      return historyFactory.fertdep(vm.currentUnit.id, params).then((data) => {
        var fertdep = data;
        vm.totalData = data.total;

        if (fertdep.content !== undefined && fertdep.content.length > 0) {
          vm.hstyFertDepsData = fertdep.content.map((i) => {
            return {
              from: checkDates(i),
              header: i[4],
              lvlTank1: i[5],
              lvlTank2: i[6],
              lvlTank3: i[7],
              lvlTank4: i[8],
              lvlTank5: i[9],
              lvlTank6: i[10],
              lvlTank7: i[11],
              lvlTank8: i[12],
            };
          });
          if (exporting) {
            exportToDocument('xls', vm.gridColumnsDef, vm.hstyFertDepsData, {
              fileName: $filter('translate')('history.fertilizerDepositsHistory'),
            });
          }
        } else {
          vm.hstyFertDepsData = [];
          if (exporting) {
            exportToDocument('xls', vm.gridColumnsDef, vm.hstyFertDepsData, {
              fileName: $filter('translate')('history.fertilizerDepositsHistory'),
            });
          }
        }
      });
    }

    function exportXLS() {
      loadHistory(true);
    }

    function pageChangeHandler(event) {
      vm.currentPage = event.detail;
      loadHistory();
    }

    function pageSizeChangeHandler(event) {
      vm.itemsPerPage = event.detail;
      vm.currentPage = 1;
      loadHistory();
    }
    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
