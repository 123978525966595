import { Subject, of, takeUntil, take, switchMap } from 'rxjs';
import { getContainer } from '@vegga/front-store';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('pivotsController', pivotsController);

  pivotsController.$inject = ['$scope', '$state', 'pivotsFactory', '$filter', '$confirm'];

  function pivotsController($scope, $state, pivotsFactory, $filter, $confirm) {
    var vm = this;
    vm.cancel;
    vm.save;
    vm.dataGridRowClick = dataGridRowClick;
    vm.changePivot = changePivot;
    vm.changingPivot = changingPivot;
    vm.nextOrPreviousItem = nextOrPreviousItem;
    vm.changePivotState = changePivotState;
    vm.cancel = cancel;
    vm.save = save;
    vm.changeState = changeState;
    vm.programsFacade = getContainer().resolve('programsFacade');
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    vm.destroy$ = new Subject();
    vm.gridColumnsDef = [
      { headerName: $filter('translate')('pivot.pivot'), field: '' },
      { headerName: $filter('translate')('pivot.name'), field: '' },
      { headerName: $filter('translate')('pivot.pi2'), field: '' },
      { headerName: $filter('translate')('pivot.pi3'), field: '' },
      { headerName: $filter('translate')('pivot.pi10'), field: '' },
    ];

    activate();
    
    function activate() {
      initSubscriptions();
      if ($state.$current.parent.name === 'pivots') {
        const veggaOverlay = document.querySelector('vegga-overlay');
        veggaOverlay.show();
      }
    }

    function initSubscriptions() {
      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$), switchMap((currentUnit) => {
        if (!currentUnit) {
          $state.go('units');
          return;
        }

        vm.currentState = $state;
        vm.currentUnit = currentUnit;
        loadPivots(vm.currentUnit.id);
        return pivotsFactory.getPivot()
      })).subscribe(pivot => {
        vm.pivot = pivot;
      });
    }

    function dataGridRowClick(_e, pivot) {
      const veggaOverlay = document.querySelector('vegga-overlay');
      veggaOverlay.show();
      vm.pivot = pivot;
      pivotsFactory.setPivot(pivot);
      changeState({ pivot: pivot, id: pivot.pk.id }, $state.params.config);
    }

    function changeState(params) {
      vm.active = null;
      vm.pivot = params.pivot;
      $state.go('pivots.detail', params);
    }

    function loadPivots(id) {
      return pivotsFactory.pivots(id).then(function (data) {
        vm.pivots = data.plain();
        if ($state.params.id) {
          pivotsFactory.setPivot(vm.pivots.find(pivot => pivot.pk.id === $state.params.id));
        }
      });
    }

    $scope.$on('formUpdated', function (event, args) {
      vm.formPivots = args;
    });

    function dataGridRowClick(_e, pivot) {
      const veggaOverlay = document.querySelector('vegga-overlay');
      veggaOverlay.show();
      vm.pivotSelected = pivot;
      pivotsFactory.setPivot(pivot);
      changeState({ pivot: pivot, id: pivot.pk.id }, $state.params.config);
    }

    function changePivot(e) {
      if (e.detail.value) {
        vm.pivotSelected = vm.pivots.find(pivot => pivot.pk.id === e.detail.value);
        pivotsFactory.setPivot(vm.pivotSelected);
        changeState({ id: e.detail.value }, $state.params.config);
      }
    }

    function changingPivot($event) {
      if (vm.formPivots && vm.formPivots.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({
          text: $filter('translate')('programs.edit.cancelq'),
          title: $filter('translate')('pivot.pivot'),
        }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.formPivots.$setPristine(); //Actualitzem estat del formulari a inicial
          this.nextOrPreviousItem($event.detail.action, $event);
        });
      }
    }

    function nextOrPreviousItem(action, $event) {
      const paginator = document.querySelector('vegga-item-paginator');
      switch (action) {
        case 'next-item':
          paginator.nextItem();
          break;
        case 'previous-item':
          paginator.previousItem();
          break;
        default:
          changePivot($event);
          break;
      }
    }

    /**
     * Funció per gestionar els canvis d'estat a a Info, Edició i Config.
     * Evaluem estat del formulari per avisar de modificació i validar-ne el canvi.
     */
    function changePivotState(state, _params) {
      if (vm.formPivots && vm.formPivots.$dirty) {
        $confirm({
          text: $filter('translate')('programs.edit.cancelq'),
          title: $filter('translate')('pivot.pivot'),
        }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.formPivots.$setPristine(); //Actualitzem estat del formulari a inicial
          $state.go(state, _params); //Canviem d'estat
        });
      } else {
        $state.go(state, _params);
      }
    }

    /**
     * Funcions que executen el broadcast als child controllers d'Edició i Configuració
     */
    function cancel($event) {
      if (vm.formPivots && vm.formPivots.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({
          text: $filter('translate')('programs.edit.cancelq'),
          title: $filter('translate')('pivot.pivot'),
        }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.formPivots.$setPristine(); //Actualitzem estat del formulari a inicial
          if (typeof $event !== 'undefined') {
            document.querySelector('vegga-overlay').dismiss();
            $state.go('pivots', { pivot: vm.pivot, id: vm.pivot.id });
          } else {
            $state.go('pivots.detail', { pivot: vm.pivot, id: vm.pivot.pk.id });
          }
        });
      } else {
        typeof $event !== 'undefined'
          ? $state.go('pivots', { pivot: vm.pivot, id: vm.pivot.pk.id })
          : $state.go('pivots.detail', { pivot: vm.pivot, id: vm.pivot.pk.id });
      }
    }
    function save(_ev) {
      if (vm.formPivots && vm.formPivots.$dirty) {
        $confirm({ text: $filter('translate')('programs.edit.saveq') }).then(() => {
          $scope.$broadcast('formSubmit');
        });
      }
    }

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
