import { A4000_DEFAULT_CONFIG, A7000_DEFAULT_CONFIG } from '../utils/device-config';
import { UNITS } from '../utils/units.enum';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { getContainer } from '@vegga/front-store';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('flowController', flowController);

  flowController.$inject = ['$rootScope', '$scope', '$state', 'sensorsFactory', 'configFactory'];

  function flowController($rootScope, $scope, $state, sensorsFactory, configFactory) {
    var vm = this;
    var backup;
    vm.activeList;
    vm.UNITS = UNITS;
    vm.save = save;
    vm.cancel = cancel_edition;
    vm.destroy$ = new Subject();
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    activate();

    function activate() {
      vm.currentState = $state.includes;
      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((currentUnit) => {
        if (!currentUnit) {
          return;
        }
        vm.currentUnit = currentUnit;
        loadData();
      });
    }
    function loadData() {
      vm.flow = angular.copy(vm.currentUnit.flow);
      initUnits();
      loadMeters();
    }
    function initUnits() {
      if (vm.currentUnit.type == UNITS.A_7000) {
        vm.irrigation = A7000_DEFAULT_CONFIG.measurementUnits.irrigation;
        vm.fertilizer = A7000_DEFAULT_CONFIG.measurementUnits.fertilization;
      } else {
        vm.irrigation = A4000_DEFAULT_CONFIG.measurementUnits.irrigation;
        vm.fertilizer = A4000_DEFAULT_CONFIG.measurementUnits.fertilization;
      }

      vm.stopTypes = {
        0: 'No para',
        1: 'Temporal',
        2: 'Definitivo',
      };
    }

    function loadMeters() {
      sensorsFactory.meters(vm.currentUnit.id).then((data) => {
        vm.meters = data;
        backup = angular.copy(vm.meters);
      });

      if (vm.currentUnit.type == vm.UNITS.A_7000) {
        sensorsFactory.fertmeters(vm.currentUnit.id).then((data) => {
          vm.fertMeters = data;
        });
      }
    }
    function save() {
      vm.flow.unittype = vm.currentUnit.type;
      _.forEach(vm.meters, (meter) => {
        meter.unittype = vm.currentUnit.type;
      });
      _.forEach(vm.fertMeters, (fertMeters) => {
        fertMeters.unittype = vm.currentUnit.type;
      });
      vm.loading = true;
      var params = {
        flow: vm.flow,
        meters: vm.meters,
        meterfert: vm.fertMeters,
      };
      // vm.flow = null;
      // vm.meters = null;
      // vm.fertMeters = null;
      configFactory.updateFlow(vm.currentUnit.id, params).then(
        (result) => {
          vm.meters = _.orderBy(result.meters, 'pk.id');
          vm.currentUnit.meters = vm.meters;
          vm.flow = result.flow;
          vm.currentUnit.flow = vm.flow;
          vm.fertMeters = _.orderBy(result.meterfert, 'pk.id');
          vm.currentUnit.fertMeters = vm.fertMeters;
          vm.loading = false;

          vm.form.$setPristine();
          $rootScope.$broadcast('reload', { message: 'refreshing' });
        },
        (error) => {},
      );
    }
    function cancel_edition() {
      vm.form.$setPristine();
      vm.config = angular.copy(backup);
    }

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });

    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formToUnitUpdated', vm.form);
      },
    );
  }
})();
