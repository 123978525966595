import { getContainer } from '@vegga/front-store';
import { from, of, Subject, take } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  IOSelectorItemResponseElementTypeEnum,
  IOSelectorItemResponseOutputTypeEnum,
} from '../../utils/enums/io-selector.enum';
import { UNITS, UNITS_V2 } from '../../utils/units.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('outFiltersController', outFiltersController);

  outFiltersController.$inject = ['$scope', '$state', 'configFactory', 'filterFactory', 'unitFactory', '$rootScope'];

  function outFiltersController($scope, $state, configFactory, filterFactory, unitFactory, $rootScope) {
    var vm = this;
    var backup;
    vm.activeList;
    vm.cancel;
    vm.open;
    vm.UNITS = UNITS;
    vm.IOSelectorItemResponseOutputTypeEnum = IOSelectorItemResponseOutputTypeEnum;
    vm.IOSelectorItemResponseElementTypeEnum = IOSelectorItemResponseElementTypeEnum;
    vm.destroy$ = new Subject();
    vm.ioFacade = getContainer().resolve('ioFacade');
    vm.save = save;
    vm.cancel = cancel_edition;
    vm.open = open;
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    activate();

    function activate() {
      vm.devicesFacade.legacyUnitResponse.value$.subscribe((currentUnit) => {
        if (!currentUnit) {
          $state.go('units');
        }
        loadData(currentUnit);
      });
    }

    function loadData(unit) {
      vm.currentUnit = unit;
      vm.currentState = $state.includes;
      vm.currentHeader = $state.params.headerId || 1;
      loadFilters();
      vm.selected = 0;
      if (vm.currentUnit.type === UNITS.A_2500) {
        vm.inputsCombo = _.range(0, 28);
      }
    }

    function open(index) {
      if (vm.selected === index) {
        vm.selected = null;
      } else {
        vm.selected = index;
      }
    }

    function loadFilters() {
      if (vm.currentUnit.type === UNITS.A_4500) {
        filterFactory.getHeaderFilters(vm.currentUnit.id, vm.currentHeader).then(function (data) {
          vm.filters = data
            .plain()
            .filter((filter) => (vm.currentUnit.version < +$rootScope.env.A4500_VERSION ? +filter.pk.id === 1 : true));
          vm.ioFacade.loadConfiguredOutputs({
            deviceId: vm.currentUnit.id,
            deviceType: UNITS_V2[vm.currentUnit.type],
          });
          backup = angular.copy(vm.filters);
          // Init StagingList in order to check the current io selector.
          vm.ioFacade.initializationStagingOutputs(vm.currentUnit.id, UNITS_V2[vm.currentUnit.type]);
        });
      } else {
        filterFactory.all(vm.currentUnit.id).then(function (data) {
          vm.filters = data.plain();
          backup = angular.copy(vm.filters);
        });
      }
    }

    function save() {
      vm.form.$setPristine();
      _.forEach(vm.filters, (filter) => {
        filter.unittype = vm.currentUnit.type;
      });

      switch (vm.currentUnit.type) {
        case UNITS.A_4000:
          configFactory.updateFilters(vm.currentUnit.id, vm.filters).then((result) => {
            vm.filters = result;
            backup = angular.copy(vm.filters);
          });
          break;
        case UNITS.A_2500:
        case UNITS.A_7000:
        case UNITS.A_5500:
          configFactory.updateFilters25(vm.currentUnit.id, vm.filters).then((result) => {
            vm.filters = result;
            backup = angular.copy(vm.filters);
          });
          break;
        case UNITS.A_4500:
          /*
          A_4500 sempre només té un filtre per capçal, per facilitar implementació continuem treballant amb array de filtres
          **/

          vm.filters.forEach((filter) => {
            filter.numFilters =
              filter.outputFirst > 0 && filter.outputLast > 0
                ? Math.max(filter.outputFirst, filter.outputLast) - Math.min(filter.outputFirst, filter.outputLast) + 1
                : 0;
            filter.modifiedForGeneral = true;
          });

          vm.ioFacade
            .validateOutputsToTheCurrentView()
            .pipe(
              switchMap((result) => {
                if (result) {
                  return from(filterFactory.saveHeaderFilter(vm.currentUnit.id, vm.currentHeader, vm.filters));
                } else {
                  return of(null);
                }
              }),
            )
            .subscribe(() => {});

          break;
      }
    }

    function cancel_edition() {
      vm.form.$setPristine();
      vm.filters = angular.copy(backup);
    }

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      },
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e, args) => {
      save();
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
      filterFactory.clearFiltersResponse();
    });
  }
})();
