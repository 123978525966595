import modalPivotsActionManual from './manualContent.html';
import './pivots.manual-controller';
import { getContainer } from '@vegga/front-store';
import { of, Subject, switchMap, takeUntil } from 'rxjs';
const colors = require('../../resources/vegga.tokens.colors.json');

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('pivotsDetailController', pivotsDetailController);

  pivotsDetailController.$inject = ['$log', '$state', 'progFactory', '$filter', '$uibModal', 'pivotsFactory'];

  function pivotsDetailController($log, $state, progFactory, $filter, $uibModal, pivotsFactory) {
    var vm = this;
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    vm.destroy$ = new Subject();
    vm.getTime2 = getTimeFromMins2;
    vm.drawPivot = drawPivot;
    vm.manualAction = manualAction;
    vm.getTime = progFactory.getTimeFromMins;

    activate();

    function activate() {
      initSubscriptions();
    }

    function initSubscriptions() {
      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).
      pipe(switchMap((currentUnit) => {
        if (!currentUnit) {
          $state.go('units');
          return of(null);
        }
        vm.currentState = $state.includes;
        vm.currentUnit = currentUnit;
        return pivotsFactory.getPivot()
      })).subscribe((pivot) => {

        vm.pivot = pivot;
        checkPivotStatus();
        setTimeout(() => {
          drawPivot(0);
          
        }, 300);
      });
    }

    function manualAction() {
      var modalInstance = $uibModal.open({
        animation: true,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        template: modalPivotsActionManual,
        controller: 'pivotsManualModal',
        controllerAs: 'vm',
        resolve: {
          Pivot: function () {
            return vm.pivot;
          },
        },
      });

      modalInstance.result.then(
        function (selectedItem) {
          var modalInstance = manualFactory.showLoadingBar();
          modalInstance.result.then((result) => {
            refresh();
          });
        },
        function () {
          $log.info('Modal dismissed at: ' + new Date());
        },
      );
    }

    function getTimeFromMins2(mins) {
      if (_.isNumber(mins)) {
        return $filter('parsetime')(mins * 60);
      }
      return mins;
    }

    function externalAreas(index) {
      var external = vm.pivot.externalAreas;
      var startPosition;
      if (index === 0) {
        startPosition = 0;
      } else {
        startPosition = vm.pivot.areas[index - 1].finalPosition;
      }
      var finalPosition = vm.pivot.areas[index].finalPosition;

      vm.externalAreas = [];

      _.forEach(external, (area, i) => {
        if (
          i < vm.pivot.exAreaNumber &&
          ((area.initialPosition >= startPosition && area.initialPosition < finalPosition) ||
            (area.finalPosition > startPosition && area.finalPosition <= finalPosition))
        ) {
          vm.externalAreas.push(area);
        }
      });
    }

    function checkPivotStatus() {
      if (vm.pivot) {
        switch (vm.pivot.xState) {
          case 0: //Pivot parat
            vm.pivot.status1 = 'Paro ';
            vm.pivot.status2 = getGoalStatus();
            break;
          case 1: //Automatic marxa en moviment
            vm.pivot.irrigation = vm.pivot.xIrrigation;
            vm.pivot.status1 = 'AUTOMATICO ';
            switch (vm.pivot.xDirection) {
              case 0:
                break;
              case 1:
                vm.pivot.status2 = '| Movimiento izquierda';
                break;
              default:
                vm.pivot.status2 = '| Movimiento canvio';
                break;
            }
            break;
          case 2: //Manual marxa amb temps
            vm.pivot.status1 = 'Manual ';
            vm.pivot.status2 = '| Movimiento (' + $filter('parsetime')(vm.pivot.xManualTime * 60) + ')';
            break;
          case 3: //Manual paro amb temps
            vm.pivot.status1 = 'Manual ';
            vm.pivot.status2 = '| Paro (' + $filter('parsetime')(vm.pivot.xManualTime * 60) + ')';
            break;
          case 4: //Manual marxa sense temps
            vm.pivot.status1 = 'Manual ';
            switch (vm.pivot.xDirection) {
              case 0:
                vm.pivot.status2 = '| Movimiento derecha';
                break;
              case 1:
                vm.pivot.status2 = '| Movimiento izquierda';
                break;
              default:
                vm.pivot.status2 = '| Movimiento canvio';
                break;
            }
            if (vm.pivot.xAreaToMove === 0) {
              if (vm.pivot.xStateVirtualGoal !== 7) {
                if (vm.pivot.xStateFisicalGoal !== 7) {
                  vm.pivot.status2 = vm.pivot.status2 + ' hasta porteria física';
                } else {
                  vm.pivot.status2 = vm.pivot.status2 + ' hasta porteria virtual';
                }
              } else if (vm.pivot.xStateFisicalGoal !== 7) {
                vm.pivot.status2 = vm.pivot.status2 + ' hasta porteria física';
              }
            } else {
              vm.pivot.status2 = vm.pivot.status2 + ' hasta area ' + vm.pivot.xAreaToMove;
            }
            break;
          case 5:
            vm.pivot.status1 = 'Manual';
            vm.pivot.status2 = '| Paro';
            break;
          case 6:
            vm.pivot.status1 = 'En espera para entrar en curso';
            break;
          case 7:
            vm.pivot.status1 = 'Riego aplazado';
            break;
          case 8:
            vm.pivot.status1 = 'Paro definitivo ';
            if (vm.pivot.xSecurityAlarm) vm.pivot.status2 = '| Alarma seguridad';
            if (vm.pivot.xSideslipAlarm) vm.pivot.status2 = '| Alarma patinaje';
            if (vm.pivot.xPressureAlarm) vm.pivot.status2 = '| Alarma presión';
            break;
          case 9:
            vm.pivot.status1 = 'Fuera de servicio';
            break;
          case 10:
            vm.pivot.status1 = 'Aparcando pivot';
            break;
          case 11:
            vm.pivot.status1 = 'Saliendo de aparcado';
            break;
          case 12:
            vm.pivot.status1 = 'En espera de presión';
            break;
          case 13:
            vm.pivot.status1 = 'En espera de adelanto del motor';
            break;
          case 14:
            vm.pivot.status1 = 'Riego en porteria ';
            vm.pivot.irrigation = vm.pivot.xIrrigation;
            var minutos = vm.pivot.status.xGoalIrrig / 60;
            var segundos = vm.pivot.status.xGoalIrrig % 60;
            vm.pivot.status = vm.pivot.status + Math.trunc(minutos) + "'" + segundos + '"';
            break;
        }
      }
    }

    function getGoalStatus() {
      var state;
      if (vm.pivot.xStateVirtualGoal !== 7) {
        state = vm.pivot.xStateVirtualGoal;
      }
      if (vm.pivot.xStateFisicalGoal !== 7) {
        state = vm.pivot.xStateFisicalGoal;
      }
      switch (state) {
        case 0: //Fora
          return 'Fuera';
          break;
        case 1: //Dreta
          return 'Derecha';
          break;
        case 2: //Esquerra
          return 'Izquierda';
          break;
        case 3: //Aparcat dreta
          return 'Aparcado derecha';
          break;
        case 4: //Aparcat esquerra
          return 'Aparcado izquierda';
          break;
        case 5: //Inici
          return 'Inicio';
          break;
        case 6: //Final
          return 'Final';
          break;
        default:
          return 'Sin porteria configurada';
          break;
      }
    }

    function drawPivot(index) {
      var areas = [];
      if (index !== undefined) {
        externalAreas(index);
        _.forEach(vm.pivot.areas, (area, i) => {
          if (area.sector !== 0 && areas.length < vm.pivot.areasNumber) {
            areas.push({
              end: area.finalPosition / 10,
              irrigation: vm.pivot.irrigation === 1 && vm.pivot.xIrrigationArea === area.id,
              active: area.activateIrrig === 1,
              selected: i === index,
            });
          }
        });
        _DrawPivot(areas, vm.pivot);
      }
    }
  }

  function _DrawPivot(areas, pivot) {
    var canvas = document.getElementById('pivotdraw');
    var ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    var x = canvas.width / 2;
    var y = canvas.height / 2;
    var lastend = 0;

    _.forEach(areas, (area) => {
      ctx.fillStyle = _getAreaColor(area);
      ctx.beginPath();
      ctx.moveTo(x, y);
      ctx.arc(x, y, 120, lastend, _getRads(area.end), false);
      ctx.lineTo(x, y);
      ctx.fill();
      ctx.lineWidth = 2;
      ctx.strokeStyle = '#364A5B';
      ctx.stroke();
      lastend = _getRads(area.end);
    });

    _drawSelectedArea(ctx, x, y, areas);

    _drawExternalAreas(ctx, x, y, pivot);

    if (pivot.positionSensor !== 0) {
      _drawLine(ctx, pivot.xPosition / 10, x, y);
    }

    _drawDotCenter(ctx, x, y);

    switch (pivot.xState) {
      case 1:
      case 2:
      case 4:
        _drawDirection(ctx, pivot.xDirection, canvas);
        break;
    }
  }

  function _drawSelectedArea(ctx, x, y, areas) {
    var lastend = 0;
    _.forEach(areas, (area) => {
      if (area.selected) {
        ctx.beginPath();
        ctx.arc(x, y, 125, lastend, _getRads(area.end), false);
        ctx.lineWidth = 10;
        ctx.strokeStyle = '#FFFFFF';
        ctx.stroke();
      }
      lastend = _getRads(area.end);
    });
  }

  function _drawExternalAreas(ctx, x, y, pivot) {
    _.forEach(pivot.externalAreas, (area, i) => {
      if (i < pivot.exAreaNumber) {
        ctx.fillStyle = '#6E7783';
        if (area.usePistol === 0) {
          //Draw pistol area
          ctx.beginPath();
          ctx.arc(x, y, 114, _getRads(area.initialPosition / 10), _getRads(area.finalPosition / 10), false);
          ctx.lineWidth = 10;
          ctx.strokeStyle = '#6E7783';
          ctx.stroke();
        }
        if (area.useAlero === 0) {
          //Draw alero area
          ctx.beginPath();
          ctx.arc(x, y, 104, _getRads(area.initialPosition / 10), _getRads(area.finalPosition / 10), false);
          ctx.lineWidth = 10;
          ctx.strokeStyle = '#6E7783';
          ctx.stroke();
        }
        if (area.usePistol === 0 && area.useAlero === 0) {
          //Draw pistol arelo divisor
          ctx.beginPath();
          ctx.arc(x, y, 109, _getRads(area.initialPosition / 10), _getRads(area.finalPosition / 10), false);
          ctx.lineWidth = 2;
          ctx.strokeStyle = '#364A5B';
          ctx.stroke();
        }
      }
    });
  }

  function _drawDotCenter(ctx, x, y) {
    ctx.fillStyle = '#6E7783';
    ctx.beginPath();
    ctx.arc(x, y, 5, 0, _getRads(360), false);
    ctx.fill();
    ctx.lineWidth = 2;
    ctx.strokeStyle = '#364A5B';
    ctx.stroke();
  }

  function _drawLine(ctx, degrees, x, y) {
    // Save the current drawing state
    ctx.save();
    // Now move across and down half the
    ctx.translate(x, y);
    // Rotate around this point
    ctx.rotate(_getRads(degrees));

    ctx.beginPath();
    ctx.strokeStyle = '#364A5B';
    ctx.lineWidth = 5;
    ctx.moveTo(0, 0);
    ctx.lineTo(125, 0);
    ctx.stroke();

    // Restore the previous drawing state
    ctx.restore();
  }

  function _drawDirection(ctx, dir, canvas) {
    if (dir < 2) {
      //Esquerra o dreta
      var startPointX = 100;
      var startPointY = 5;
      var endPointX = 160;
      var endPointY = 5;
      var headlen = 10; // length of head in pixels
      var angle = Math.atan2(endPointY - startPointY, endPointX - startPointX);

      ctx.beginPath();
      ctx.strokeStyle = '#364A5B';
      ctx.lineWidth = 2;
      if (dir === 1) {
        ctx.moveTo(endPointX, startPointY);
        ctx.lineTo(startPointX, endPointY);
        ctx.lineTo(
          startPointX + headlen * Math.cos(angle - Math.PI / 6),
          endPointY - headlen * Math.sin(angle - Math.PI / 6),
        );
        ctx.moveTo(startPointX, endPointY);
        ctx.lineTo(
          startPointX + headlen * Math.cos(angle + Math.PI / 6),
          endPointY - headlen * Math.sin(angle + Math.PI / 6),
        );
      } else {
        ctx.moveTo(startPointX, startPointY);
        ctx.lineTo(endPointX, endPointY);
        ctx.lineTo(
          endPointX - headlen * Math.cos(angle - Math.PI / 6),
          endPointY - headlen * Math.sin(angle - Math.PI / 6),
        );
        ctx.moveTo(endPointX, endPointY);
        ctx.lineTo(
          endPointX - headlen * Math.cos(angle + Math.PI / 6),
          endPointY - headlen * Math.sin(angle + Math.PI / 6),
        );
      }

      ctx.stroke();
    }
  }

  //duplicated line 37
  /*function manualAction() {
    var modalInstance = $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title',
      ariaDescribedBy: 'modal-body',
      //templateUrl: 'components/pivots/manualContent.html',
      template: modalPivotsActionManual,
      controller: 'pivotsManualModal',
      controllerAs: 'vm',
      resolve: {
        Pivot: function () {
          return vm.pivot;
        },
      },
    });

    modalInstance.result.then(
      function (selectedItem) {
        var modalInstance = manualFactory.showLoadingBar();
        modalInstance.result.then((result) => {
          refresh();
        });
      },
      function () {
        $log.info('Modal dismissed at: ' + new Date());
      }
    );
  }*/

  function _getRads(degrees) {
    return degrees * (Math.PI / 180);
  }

  function _getAreaColor(area) {
    if (area.irrigation) {
      return colors['vegga-color-secondary'];
    } else if (!area.active) {
      return colors['vegga-color-grey-200'];
    } else {
      return colors['vegga-color-primary-default'];
    }
  }
})();
