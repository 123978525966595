// (function() {
//     'use strict';
// @ts-ignore
import { showLoader, hideLoader } from '@vegga/front-utils';

import { getToken, getAppLanguage } from '@vegga/front-utils';
import angular from 'angular';
import 'angular-animate/angular-animate';
import 'angular-aria/angular-aria';
import 'angular-bootstrap-calendar/dist/css/angular-bootstrap-calendar.min.css';
import 'angular-bootstrap-calendar/dist/js/angular-bootstrap-calendar-tpls';
import 'angular-chart.js/dist/angular-chart';
import 'angular-confirm/angular-confirm';
import 'angular-material/angular-material';
import 'angular-pageslide-directive/dist/angular-pageslide-directive';
import 'angular-svg-round-progressbar/build';
import 'angular-translate';
import 'angular-ui-bootstrap/dist/ui-bootstrap';
import 'angular-ui-bootstrap/dist/ui-bootstrap-tpls';
import 'angular-ui-grid';
import 'angular-ui-grid/ui-grid.css';
import 'angular-ui-router';
import 'angularjs-slider/dist/rzslider.min';
import 'angularjs-slider/dist/rzslider.min.css';
// TODO - check $('#myModal').modal() in order to remove next import
import 'bootstrap-sass/assets/javascripts/bootstrap';
// import 'highcharts/highcharts';
// import 'highcharts/modules/exporting';
// import 'highcharts/modules/export-data';
// import '../resources/js/highcharts-offline-exporting'
import 'chart.js/dist/Chart.bundle';
import 'chartjs-plugin-annotation/chartjs-plugin-annotation';
import 'chartjs-plugin-zoom/chartjs-plugin-zoom';
import 'file-saver/dist/FileSaver.min';
import 'jquery/dist/jquery';
import 'masonry-layout/dist/masonry.pkgd.min';
import moment from 'moment/moment';
import 'ng-table/bundles/ng-table';
import 'ngprogress/build/ngprogress.min';
import 'ngprogress/ngProgress.css';
import 'restangular/dist/restangular';
import swal from 'sweetalert2/dist/sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
import en from '../resources/i18n/locale-en.json';
import es from '../resources/i18n/locale-es.json';
import pt from '../resources/i18n/locale-pt.json';
import fr from '../resources/i18n/locale-fr.json';
import it from '../resources/i18n/locale-it.json';
import '../resources/js/ui.bootstrap.materialPicker';

// import '../components/utils/auth-interceptor';

//import '../resources/js/parse-dms';

// angular.module('App.Controllers', ['restangular']);
// angular.module('agronicwebApp.a25', ['restangular']);

angular
  .module('agronicwebApp', [
    'ngAnimate',
    'ngAria',
    'ui.bootstrap',
    'ui.router',
    'ngMaterial',
    'ui.grid',
    'ui.grid.edit',
    'ui.grid.selection',
    'ui.grid.pagination',
    'ui.grid.resizeColumns',
    'ui.grid.moveColumns',
    'ui.grid.exporter', //Grid dependency
    'ngTable',
    'chart.js',
    'mwl.calendar',
    'angular-svg-round-progressbar',
    'pascalprecht.translate',
    'restangular',
    'pageslide-directive',
    'angular-confirm',
    //     'ng-ip-address',
    'ui.bootstrap.materialPicker',
    //     'rzModule',
    'rzSlider',
    'ngProgress',
  ])
  .config([
    '$httpProvider',
    function ($httpProvider) {
      var requestErrorInterceptor = [
        '$injector',
        '$q',
        '$translate',
        function ($injector, $q, $translate) {
          var interceptorInstance = {
            response: function (response) {
              const toast = document.querySelector('vegga-toast-manager');
              let message;
              hideLoader();
              if (response.config.method === 'POST' || response.config.method === 'PUT') {
                message = $translate.instant('interceptorMessages.response.saved');
              }

              if (response.config.method === 'DELETE') {
                message = $translate.instant('interceptorMessages.response.deleted');
              }

              if (message && toast) {
                toast.create({
                  message: message,
                  statusClass: 'vegga-alert--success',
                });
              }

              return response;
            },

            responseError: function (rejection) {
              hideLoader();
              const toast = document.querySelector('vegga-toast-manager');
              if (toast) {
                toast.create({
                  message: $translate.instant('interceptorMessages.errorResponse'),
                  statusClass: 'vegga-alert--error',
                });
              }

              if (rejection.status === 401) {
                var httpErrorHandler = $injector.get('httpErrorHandler');
                httpErrorHandler.error();
              }
              return $q.reject(rejection);
            },
          };
          return interceptorInstance;
        },
      ];
      $httpProvider.interceptors.push(requestErrorInterceptor);
    },
  ])
  .config([
    '$httpProvider',
    function ($httpProvider) {
      $httpProvider.interceptors.push(function () {
        var request = async function request(config) {
          if (config.method !== 'GET') {
            console.log('showloader');
            showLoader();
          }
          let token = await getToken();
          config.headers['X-Requested-With'] = 'XMLHttpRequest';
          if (!config.url.includes('.html')) {
            config.headers['Authorization'] = `Bearer ${token}`;
          }
          return config;
        };
        return {
          request: request,
        };
      });
    },
  ])
  //TODO - check moment
  .constant('moment', moment)
  .config([
    'RestangularProvider',
    '$httpProvider',
    function (RestangularProvider, $httpProvider) {
      /**
       *API base url configuration
       */
      var url = `${process.env.API_IRRIGATION_CONTROL_ENDPOINT}v1`;
      RestangularProvider.setBaseUrl(url);
    },
  ])

  .config([
    'calendarConfig',
    function (calendarConfig) {
      calendarConfig.dateFormatter = 'moment'; //use either moment or angular to format dates on the calendar. Default angular. Setting this will override any date formats you have already set.
      calendarConfig.allDateFormats.moment.date.hour = 'HH:mm'; //this will configure times on the day view to display in 24 hour format rather than the default of 12 hour
      calendarConfig.allDateFormats.moment.title.day = 'ddd D MMM'; //this will configure the day view title to be shorter
      calendarConfig.i18nStrings.weekNumber = 'Semana {week}'; //This will set the week number hover label on the month view
      calendarConfig.displayAllMonthEvents = true; //This will display all events on a month view even if they're not in the current month. Default false.
    },
  ])
  .config([
    '$translateProvider',
    function ($translateProvider) {
      /*Transalation configuration*/
      // $translateProvider.useStaticFilesLoader({
      //     prefix: 'resources/i18n/locale-',
      //     suffix: '.json'
      // }) .registerAvailableLanguageKeys(['en', 'es', 'fr', 'it', 'pt'], {
      //      'en*': 'en',
      //      'pt*': 'pt',
      //      'es*': 'es',
      //      'ca*': 'es',
      //      'fr*': 'fr',
      //      'it*': 'it',
      //       '*': 'en'
      //   })
      // .fallbackLanguage('en')
      // .determinePreferredLanguage();
      $translateProvider.translations('en', en);
      $translateProvider.translations('es', es);
      $translateProvider.translations('pt', pt);
      $translateProvider.translations('fr', fr);
      $translateProvider.translations('it', it);

      $translateProvider.preferredLanguage(getAppLanguage());
    },
  ])
  .config([
    '$sceDelegateProvider',
    function ($sceDelegateProvider) {
      $sceDelegateProvider.resourceUrlWhitelist([
        // Allow same origin resource loads.
        'self',
        // Allow loading from our assets domain. **.
        'https://frontassets.blob.core.windows.net/**',
      ]);
    },
  ])
  .config([
    '$mdDateLocaleProvider',
    function ($mdDateLocaleProvider) {
      var localeDate = moment.localeData();
      // Can change week display to start on Monday.
      $mdDateLocaleProvider.firstDayOfWeek = 1;
      $mdDateLocaleProvider.months = localeDate._months;
      $mdDateLocaleProvider.shortMonths = localeDate._monthsShort;
      $mdDateLocaleProvider.days = localeDate._weekdays;
      $mdDateLocaleProvider.shortDays = localeDate._weekdaysMin;

      // Example uses moment.js to parse and format dates.
      $mdDateLocaleProvider.parseDate = function (dateString) {
        var m = moment(dateString, 'L', true);
        return m.isValid() ? m.toDate() : new Date(NaN);
      };

      $mdDateLocaleProvider.formatDate = function (date) {
        var m = moment(date);
        return m.isValid() ? m.format('L') : '';
      };
    },
  ])
  /**
   *Auth run function state verification
   **/
  .run([
    '$rootScope',
    '$state',
    'AuthenticationService',
    '$window',
    '$location',
    '$transitions',
    function ($rootScope, $state, AuthenticationService, $window, $location, $transitions) {
      $transitions.onStart({}, function (transition) {
        // check parent name if equals to unit and dismiss overlay
        if (transition.$to().parent.name === 'unit') {
          const overlay = document.querySelector('vegga-overlay');
          overlay && overlay.dismiss();
        }
      });

      $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
        //stateChangeStart
        if (toState.authenticate && $rootScope.user === undefined) {
          //AuthenticationService.authenticate(null,function(){
          $state.transitionTo('login');
          //event.preventDefault();
          //});
        }
        $rootScope.statename = $state.current.name;
        $window.ga('send', 'pageview', $location.path());
        return;
      });
      $rootScope.toast = swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        //background:'#FFC72C',
        timer: 5000,
      });
      $rootScope.env = {
        BASE_HREF: process.env.BASE_HREF,
        HOST: process.env.HOST,
        FLAGS_ENABLED_REDIRECT_TO_AGRONIC: /^true$/i.test(process.env.FLAGS_ENABLED_REDIRECT_TO_AGRONIC),
        REFRESH_COUNTDOWN_VALUE: process.env.REFRESH_COUNTDOWN_VALUE,
        A4500_VERSION: process.env.A4500_VERSION,
      };
    },
  ])
  .run(function () {
    moment.locale('es');
  })
  .filter('capitalize', function () {
    return function (input) {
      return typeof input === 'string' && input.length > 0 ? input.charAt(0).toUpperCase() + input.slice(1) : input;
    };
  });
// .filter('unique', function() {
//    return function(collection, keyname) {
//       var output = [],
//           keys = [];

//       angular.forEach(collection, function(item) {
//           var key = item[keyname];
//           if(keys.indexOf(key) === -1) {
//               keys.push(key);
//               output.push(item);
//           }
//       });

//       return output;
//    };
// })
// ;

//Animation for views transition.
angular.module('agronicwebApp').animation('.fade-in', function () {
  return {
    enter: function (element, done) {
      element
        .css({
          opacity: 0,
        })
        .animate(
          {
            opacity: 1,
          },
          1000,
          done,
        );
    },
    leave: function (element, done) {
      element.css({
        opacity: 0,
      });
      done();
    },
  };
});

angular.module('agronicwebApp').factory('veggaModalConfirmService', [
  '$q',
  '$filter',
  function ($q, $filter) {
    let veggaModalEl;
    let veggaModalWrapper;
    let deferred;
    let buttons;
    const service = {
      showVeggaModalConfirm: (config, size = 'md') => {
        if (veggaModalWrapper) {
          veggaModalWrapper.remove();
        }

        const { header, content, cancelText, confirmText } = config || {};

        veggaModalWrapper = document.createElement('div');
        veggaModalWrapper.innerHTML = `<vegga-modal id="confirmModal" class="${size}">
                                <div slot="header">
                                  <vegga-heading class="md">${header || ''}</vegga-heading>
                                </div>
                                <div slot="content">
                                  <vegga-text class="lg">${content || ''}</vegga-text>
                                </div>
                                <div slot="footer">
                                  <vegga-button class="secondary">
                                    ${cancelText || $filter('translate')('AUTOGENERATED.ACTIONS.ACT0003_CANCEL')}
                                  </vegga-button>
                                  <vegga-button class="primary">
                                   ${confirmText || $filter('translate')('general.accept')}
                                  </vegga-button>
                                </div>
                            </vegga-modal>`;

        document.body.appendChild(veggaModalWrapper);

        veggaModalEl = document.querySelector('#confirmModal');
        veggaModalEl.show();

        deferred = $q.defer();

        handleVeggaModalConfirm();
        handleResize(buttons);

        $(window).on('resize', () => handleResize(buttons));

        return deferred.promise;
      },
    };

    function handleVeggaModalConfirm() {
      buttons = veggaModalEl.querySelectorAll('vegga-button');
      if (!buttons) {
        return;
      }

      buttons.forEach((btn, i) => {
        btn.addEventListener('veggaButtonClick', () => {
          deferred.resolve(!!i);
          hideModal();
        });
      });

      veggaModalEl.addEventListener('veggaOnModalDismiss', () => {
        deferred.resolve(false);
      });
    }

    function hideModal() {
      veggaModalEl.hide();
      veggaModalWrapper.remove();
    }

    function handleResize(buttons) {
      buttons.forEach((btn) => {
        if ($(window).innerWidth() > 768) {
          btn.classList.remove('full');
        } else {
          btn.classList.add('full');
        }
      });
    }

    return service;
  },
]);
