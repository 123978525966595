import Highcharts from 'highcharts/highcharts';
import moment from 'moment/moment';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('extractionsDetailController', extractionsDetailController);

  extractionsDetailController.$inject = [
    '$log',
    '$rootScope',
    '$scope',
    '$state',
    'Restangular',
    '$mdDialog',
    'UserData',
    'extractionsFactory',
  ];

  function extractionsDetailController(
    $log,
    $rootScope,
    $scope,
    $state,
    Restangular,
    $mdDialog,
    UserData,
    extractionsFactory,
  ) {
    var vm = this;
    var chart;
    vm.pagination;
    activate();

    function activate() {
      if ($state.params.extractions !== null) {
        vm.extractions = $state.params.extractions;
        vm.changeState = changeState;
        vm.getExtractionStatus = getExtractionStatus;
        vm.from = moment().subtract(6, 'day')._d;
        vm.to = moment()._d;
        vm.loadHistory = loadHistory;
        vm.pagination = {
          page: 1,
        };
        initGrid();
        loadGraficas();
        loadHistory();
      } else {
        $state.go('units');
      }
    }

    function initGrid() {
      vm.gridOptions = {
        enableSorting: true,
        enableColumnMenus: false,
        useExternalPagination: false,
        paginationPageSize: 24,
        columnDefs: [
          { field: 'getDate()', displayName: 'Fecha medición', width: '**' },
          { field: 'getHour()', displayName: 'Hora medición', width: '**' },
          { field: 'flow', displayName: 'Caudal (L/s)', width: '**' },
          { field: 'total', displayName: 'Totalizador (m3)', width: '**' },
          { field: 'increment', displayName: 'Consumo (m3)', width: '**' },
          { field: 'level', displayName: 'Nivel freático (m)', width: '**' },
          { field: 'getStatus()', displayName: 'Estado envio', width: '**' },
          { field: 'sentDate', displayName: 'Fecha envio', width: '**' },
          { field: 'checkCode', displayName: 'Comprobante', width: '**' },
        ],
        onRegisterApi: function (gridApi) {
          vm.gridApi = gridApi;
        },
      };
    }
    function loadHistory(clean, update) {
      if (clean) {
        vm.pagination.page = 1;
      }
      var params = {
        from: moment(vm.from).format('DD-MM-YYYY'),
        to: moment(vm.to).add(1, 'd').format('DD-MM-YYYY'),
        page: vm.pagination.page,
      };
      extractionsFactory.history(vm.extractions.id, params).then((data) => {
        vm.gridOptions.totalItems = data.totalElements;
        vm.gridOptions.data = data.content;
        processDataToChartType(update);
        _.forEach(vm.gridOptions.data, (row) => {
          row.getStatus = getStatus;
          row.getDate = getDate;
          row.getHour = getHour;
        });
      });
    }
    function getDate() {
      if (this.extractionDate !== null) {
        return moment(this.extractionDate, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY');
      }
    }
    function getHour() {
      if (this.extractionHour !== null) {
        return moment(this.extractionHour, 'H').format('HH:mm');
      }
    }

    function getStatus() {
      if (this.status !== null) {
        switch (this.status) {
          case 0:
            return 'Enviado ';
          case 10:
            return 'Error estructura Request';
          case 20:
            return 'Error tipo de datos';
          case 30:
            return 'Error cola MQ llena';
          case 40:
            return 'Código de la obra inválido';
          case 50:
            return 'Error Datos incompletos';
          case 60:
            return 'Datos de medición no registrados';
          case 61:
            return 'Datos de medicion no encontrados';
          case 70:
            return 'Fecha/hora enviadas estan fuera del rango';
          case 80:
            return 'Rut Invalido';
          case 81:
            return 'Usuario Invalido';
          case 1000:
            return 'No se corresponde la naturaleza de la obra con los datos enviados';
          default:
            return this.status;
        }
      } else {
        return 'Pendiente';
      }
    }

    function getExtractionStatus() {
      if (vm.extractions.cancelDate !== null) {
        return 'Pozo inactivo - baja';
      } else if (vm.extractions.canceled) {
        return 'Sincronización parada';
      } else {
        return 'Pozo activo - Sincronizando';
      }
    }

    function processDataToChartType(update) {
      if (vm.gridOptions.data && vm.gridOptions.data.length > 0) {
        let flowData = [];
        let levelData = [];
        let totalData = [];
        _.forEach(vm.gridOptions.data, (row, key) => {
          let date = moment(row.extractionDate, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY');
          let hour = moment(row.extractionHour, 'H').format('HH:mm');
          let date_timestamp = moment(date + ' ' + hour, 'DD-MM-YYYY HH:mm').format('x');
          date_timestamp = parseInt(date_timestamp);
          flowData.push([date_timestamp, row.flow]);
          levelData.push([date_timestamp, row.level]);
          totalData.push([date_timestamp, row.increment]);
        });

        if (update) {
          chart.series[0].setData(flowData);
          chart.series[1].setData(totalData);
          chart.series[2].setData(levelData);
          chart.redraw();
        } else {
          chart.addAxis({
            id: 1,
            min: 0,
            title: {
              text: 'Caudal (L/s)',
            },
            showEmpty: false,
            opposite: false,
          });
          chart.addSeries({
            name: 'Caudal',
            index: 1,
            legendIndex: 1,
            type: 'line',
            yAxis: 1,
            data: flowData,
            tooltip: {
              valueSuffix: ' L/s',
            },
          });
          chart.addAxis({
            id: 2,
            min: 0,
            title: {
              text: 'Consumo (m3)',
            },
            showEmpty: false,
            opposite: false,
          });
          chart.addSeries({
            name: 'Totalizador',
            index: 2,
            legendIndex: 2,
            type: 'column',
            yAxis: 2,
            data: totalData,
            tooltip: {
              valueSuffix: ' m3',
            },
          });
          chart.addAxis({
            id: 3,
            min: 0,
            title: {
              text: 'Nivel (m)',
            },
            showEmpty: false,
            opposite: true,
          });
          chart.addSeries({
            name: 'Nivel',
            index: 3,
            legendIndex: 3,
            type: 'line',
            yAxis: 3,
            data: levelData,
            tooltip: {
              valueSuffix: ' m',
            },
          });
        }
      }
    }

    function loadGraficas() {
      let id = 'graficas';

      chart = new Highcharts.Chart({
        time: {
          useUTC: false,
        },
        title: {
          text: '',
        },
        tooltip: {
          shared: true,
        },
        chart: {
          alignTicks: false,
          zoomType: 'x',
          renderTo: id,
          type: 'line',
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          type: 'datetime',
          labels: {
            //format: '{value:%d-%m-%Y}',
            rotation: -65,
          },
        },
        yAxis: {
          min: 0,
          visible: false,
          title: {
            text: '',
          },
        },
        plotOptions: {
          line: {
            lineWidth: 1,
            softThreshold: false,
          },
        },
        exporting: {
          enabled: true,
          buttons: {
            contextButton: {
              menuItems: ['viewFullscreen', 'separator', 'downloadCSV', 'downloadXLS'],
            },
          },
        },
      });
    }
    function changeState(state, e) {
      $state.go('eextra.' + state, { extraction: e });
    }
  }
})();
